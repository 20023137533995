import { useState, useEffect } from 'react';
import DigitayoLogo from '../assets/logo/digitayo-logo-only.png';
import PrimaryButton from './button/PrimaryButton';
import SecondaryButton from './button/SecondaryButton';

export type Prompt = {
  prompt: () => void;
};
// Taken from: https://gist.github.com/rikukissa/cb291a4a82caa670d2e0547c520eae53
export default function useAddToHomescreenPrompt() {
  const [prompt, setPrompt] = useState<any>();
  const [isAppInstalled, setIsAppInstalled] = useState(false);
  const [hidden, setHidden] = useState(!!window.sessionStorage.getItem('add_to_homescreen_closed'));

  const isPWAInstalled = async () => {
    if (window.navigator && 'getInstalledRelatedApps' in window.navigator) {
      const relatedApps = await (window.navigator as any).getInstalledRelatedApps();
      let installed = false;
      relatedApps.forEach((app: any) => {
        //if your PWA exists in the array it is installed
        console.log(app.platform, app.url);
        if (app.url === 'https://yourwebsite.com/manifest.json') {
          installed = true;
        }
      });
      setIsAppInstalled(installed);
    }
  };

  const promptToInstall = () => {
    console.log('prompt to install', prompt);

    if (prompt) {
      console.log('has prompt');
      return prompt!.prompt();
    }
    alert('has no prompt');
    return Promise.reject(
      new Error('Tried installing before browser sent "beforeinstallprompt" event')
    );
  };

  useEffect(() => {
    isPWAInstalled();
    const ready = (e: any) => {
      console.log('beforeinstallprompt added');
      e.preventDefault();
      setPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', ready);

    return () => {
      window.removeEventListener('beforeinstallprompt', ready);
    };
  }, []);

  const hideWidget = () => {
    window.sessionStorage.setItem('add_to_homescreen_closed', '1');
    setHidden(true);
  };

  return (
    <>
      {!isAppInstalled && prompt && !hidden && (
        <div className="fixed mx-auto inset-x-0 bottom-10 border border-digitayoBlack4 bg-white w-[377px] h-[135px] z-50 cursor-pointer rounded-xl max-w-md">
          <div className="flex flex-col justify-center px-4 py-3">
            <h4 className="bg-gradient-to-r from-digitayoOrange1 to-digitayoPurple1 bg-clip-text font-archivo font-bold text-transparent text-[20px] mt-2">
              Add to Home Screen
            </h4>
            <div className="flex mt-3 w-[343.51px] h-[50px] gap-2">
              <img src={DigitayoLogo} alt="Digitayo logo" />
              <div className="flex flex-col">
                <p className="text-digitayoBlack4 text-[20px] font-archivo font-bold">DigiTayo</p>
                <p className="text-gray-400 text-[14px]">app.digitayo.ph</p>
              </div>
              <div className="flex w-full gap-2">
                <PrimaryButton isInvalid={false} onClick={promptToInstall}>
                  Add
                </PrimaryButton>
                <SecondaryButton isValid={false} isOrange={true} onClick={hideWidget}>
                  <span className="w-full">Cancel</span>
                </SecondaryButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
