import { forwardRef, useEffect } from 'react';

type SelectFieldProps = {
  label?: string;
  value?: string;
  options?: { code: string; name: string; icon?: string }[];
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  placeholder?: string;
  hasOptionsWithId?: boolean;
  isValid?: string;
};

const SelectField = forwardRef<HTMLSelectElement, SelectFieldProps>((props, ref) => {
  const { label, value, options, onChange, placeholder, hasOptionsWithId, ...otherProps } = props;

  // const [selectedValue, setSelectedValue] = useState(value || '');

  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    // setSelectedValue(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div className="border rounded-lg border-digitayoGray3 p-2 mt-2">
      <label
        className={`text-sm text-digitayoBlack1 p-1 pb-0 mb-0 ${value ? 'text-green-600' : ''}`}
      >
        {label}
      </label>

      {hasOptionsWithId !== true ? (
        <select
          ref={ref}
          className={`py-1 mt-1 rounded-md w-full bg-digitayoWhite text-xl ${
            value ? 'text-digitayoBlack4' : 'text-digitayoBlack3'
          } focus:text-digitayoBlack4`}
          {...otherProps}
          value={value}
          onChange={handleOnChange}
          defaultValue=""
        >
          <option value="" disabled>
            {placeholder}
          </option>
          {options?.map((item, i) => {
            return (
              <option
                key={i}
                value={item.code}
                className="text-digitayoBlack4 bg-digitayoWhite bg-white border border-digitayoOrange1 border-solid"
              >
                {item.name}
              </option>
            );
          })}
        </select>
      ) : (
        <select
          ref={ref}
          className={`py-1 mt-1 rounded-md w-full bg-digitayoWhite text-xl ${
            value ? 'text-digitayoBlack4' : 'text-digitayoGray3'
          } focus:text-digitayoBlack4`}
          onChange={handleOnChange}
          aria-label={placeholder}
          value={value}
        >
          <option value="" disabled>
            {placeholder}
          </option>
          {options?.map((item) => (
            <option
              key={item.code}
              value={item.code}
              className="text-digitayoBlack4 bg-digitayoWhite"
            >
              {item.name}
            </option>
          ))}
        </select>
      )}
    </div>
  );
});

export default SelectField;
