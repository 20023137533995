import score0 from '../../assets/das/0white.svg';
import score1 from '../../assets/das/1white.svg';
import score2 from '../../assets/das/2white.svg';
import score3 from '../../assets/das/3white.svg';
import score4 from '../../assets/das/4white.svg';
import score5 from '../../assets/das/5white.svg';
import store from '../../assets/das/digital-maturity.svg';
import ProgressBar from '../../components/ProgressBar';
import DigitalMaturity from '../../assets/das/digital-maturity.svg';
import DigitalReadiness from '../../assets/das/digital-readiness.svg';
import SecondaryButton from '../../components/button/SecondaryButton';
import PrimaryButton from '../../components/button/PrimaryButton';
import { SetStateAction, useEffect, useState } from 'react';
import {
  getRecommendedCourses,
  getSmeDashboard,
  getProfile,
  getSmeScores,
  getDrcScore,
} from '../../services/https/api';
import CardCourses from '../../components/CardCourses';
import Bookmark from '../../assets/dashboard/courses/bookmark.svg';
import Card1 from '../../assets/dashboard/courses/digital-marketing.jpg';
import Card2 from '../../assets/dashboard/courses/ecommerece.jpg';
import UnderlineLink from '../../components/UnderlineLink';
import { useHistory } from 'react-router';
import ToastPopup from '../../components/Toastr';
import BackIcon from '../../assets/left-arrow.svg';

type ScoreCardType = {
  label: string;
  icon: string;
  level: number;
  progress: number;
};

// type CourseType = {
//   id: string;
//   title: string;
//   description: string;
//   url: string;
//   thumbnail: string;
// tags: string;
// };

type ProfileType = {
  business_name: string;
  city: string;
  region: string;
};

type ScoreType = {
  overall_score: number;
  has_digital_profile: string;
  took_assessment: boolean;
  maturity_level: number;
  readiness_level: number;
  createdAt: Date;
};

type LevelType = {
  overall_score: number;
};

type Tag = {
  title: string;
  color: string;
};

type ScoreHistory = {
  id: string;
  level: number;
  createdAt: Date;
  maturity_level: number;
  readiness_level: number;
};

type Course = {
  id: string;
  title: string;
  category: string;
  url: string;
  tags: Tag[];
  created_at: string;
  updated_at: string;
  level: string;
  drc_score: number[];
  thumbnail: string;
  description: string;
};

type CourseDrcScore = {
  id: string;
  value: number;
  course_id: string;
};

const LevelCard = ({ overall_score }: LevelType) => {
  return (
    <div className="bg-gradient-to-br from-digitayoOrange1 via-digitayoPurple1 to-digitayoBlue1 grid grid-flow-col grid-cols-5 px-4 py-6 rounded-lg text-xs">
      <div className="border-r border-digitayoOrange4 col-span-2 pr-2">
        <div className="text-white text-center font-semibold">
          Current Digital Maturity and Readiness Level
        </div>
        {overall_score === 0 && (
          <img src={score0} alt="score-0" width="90" className="mx-auto my-2" />
        )}
        {overall_score === 1 && (
          <img src={score1} alt="score-1" width="90" className="mx-auto my-2" />
        )}
        {overall_score === 2 && (
          <img src={score2} alt="score-2" width="90" className="mx-auto my-2" />
        )}
        {overall_score === 3 && (
          <img src={score3} alt="score-3" width="90" className="mx-auto my-2" />
        )}
        {overall_score === 4 && (
          <img src={score4} alt="score-4" width="90" className="mx-auto my-2" />
        )}
        {overall_score === 5 && (
          <img src={score5} alt="score-5" width="90" className="mx-auto my-2" />
        )}
      </div>
      <div className="text-white col-span-3 mx-6">
        <div className="font-semibold">LEVEL {overall_score}:</div>
        {/* <div>
          SMEs who are using advanced digital technologies (artificial intelligence, internet of
          things, virtual/augmented reality) , analytics, and business process automations.
        </div> */}
        {overall_score === 0 && (
          <div>
            SMEs who are still generally a traditional business, mostly offline (not using any
            technology) and most business processes are manual.
          </div>
        )}
        {overall_score === 1 && (
          <div>
            SMEs who are still generally a traditional business, mostly offline (not using any
            technology) and most business processes are manual.
          </div>
        )}
        {overall_score === 2 && (
          <div>
            SMEs who are using devices like mobile phone or laptop for basic productivity tools to
            manage orders, but transactions are mostly offline.
          </div>
        )}
        {overall_score === 3 && (
          <div>
            SMEs who are using devices for basic business operations like tracking orders via
            messaging apps and spreadsheet; has static website for product or service information.
          </div>
        )}
        {overall_score === 4 && (
          <div>
            SMEs who are using devices to manage real-time orders coming from e-commerce platforms,
            with integration to payments and logistics/delivery service providers.
          </div>
        )}
        {overall_score === 5 && (
          <div>
            SMEs who are using advanced digital technologies (artificial intelligence, internet of
            things, virtual/augmented reality) , analytics, and business process automations.
          </div>
        )}
      </div>
    </div>
  );
};

const ScoreCard = ({ label, icon, level, progress }: ScoreCardType) => {
  function getDescriptions() {
    if (label === 'Digital Maturity') {
      if (level === 0 || level === 1) {
        return 'Wala ka pang ginagamit na digital tools sa iyong negosyo.';
      }
      if (level === 2) {
        return 'Kakasimula mo lang gumamit ng mga simpleng digital tools.';
      }
      if (level === 3) {
        return 'Aktibo ang iyong negosyo sa pag-embrace ng new technologies at pag-incorporate ng digital tools sa inyong operations.';
      }
      if (level === 4) {
        return 'Palagi kang updated sa mga pinakabagong tools at teknolohiya na makakatulong sa iyong negosyo.';
      }
      if (level === 5) {
        return 'Updated ka sa latest tools at platforms na makakatulong mag improve ng iyong business';
      }
    }
    if (label === 'Digital Readiness') {
      if (level === 0 || level === 1) {
        return 'Tradisyonal pa rin ang negosyo at kulang pa ng digital presence.';
      }
      if (level === 2) {
        return 'Gumagamit ang iyong negosyo ng simpleng digital tools ngunit maraming pa ang manual na proseso.';
      }
      if (level === 3) {
        return 'Aktibo ang iyong negosyo sa pag-embrace ng new technologies at pag-incorporate ng digital tools sa inyong operations.';
      }
      if (level === 4) {
        return 'Gumagamit ang iyong negosyo ng advanced na tools para mapabuti ang inyong operations.';
      }
      if (level === 5) {
        return 'Updated ang iyong negosyo sa best practices at tools para sa  digital transformation';
      }
    }
  }
  return (
    <div className="bg-gray-50 shadow-lg text-digitayoBlack4 px-6 pt-4 pb-6 rounded-lg my-4">
      <div className="flex justify-between items-end">
        <div className="flex justify-between items-end text-sm">
          <b>{label}</b>
        </div>
        <img src={icon} alt="score" width="40" />
      </div>
      <div className="grid grid-flow-col grid-cols-12 items-end my-6 font-bold">
        <div className="text-xl font-bold">{level}</div>
        <div className="col-span-10 w-full my-auto h-[6px]">
          <ProgressBar nProgress={progress} rounded={true} />
        </div>
      </div>
      {/* <div className="text-xs text-digitayoBlack2">
        Updated ka sa latest tools at technologies na makakatulong sa pagne-negosyo.
      </div> */}
      <div className="text-xs text-digitayoBlack2">{getDescriptions()}</div>
    </div>
  );
};

const Score = () => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [data, setData] = useState<ProfileType>();
  const [scoreDetails, setScoreDetails] = useState<ScoreType>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const history = useHistory();
  const [selectedScore, setSelectedScore] = useState<string>('');
  const [scoreHistory, setScoreHistory] = useState<ScoreHistory[]>([]);

  const getCourses = async () => {
    try {
      const courses = await getRecommendedCourses({});
      const dataCourses = courses.data.courses;

      const updatedCourse: Course[] = [];

      if (dataCourses.length > 0) {
        const responseScore = await getDrcScore();
        const scoreList = responseScore.data.scores;

        if (scoreList.length > 0) {
          for (let index = 0; index < dataCourses.length; index++) {
            const course = dataCourses[index];

            const filteredScore: CourseDrcScore[] = scoreList.filter(
              (score: CourseDrcScore) => score.course_id == course.id
            );
            if (filteredScore.length > 0) {
              const drcScores = filteredScore.map((score: CourseDrcScore) => score.value);

              updatedCourse.push({
                ...course,
                drc_score: drcScores,
              });
            } else {
              updatedCourse.push({
                ...course,
                drc_score: null,
              });
            }
          }
        } else {
          dataCourses.forEach((course: Course) => {
            updatedCourse.push(course);
          });
        }
      }

      setCourses(updatedCourse);
    } catch (error: any) {
      handleErrorCode(error.response.data.error_code);
    }
  };

  const getProfileDetails = async () => {
    try {
      const profile = await getProfile();
      const data = profile.data.sme;
      setData(data);
    } catch (error: any) {
      handleErrorCode(error.response.data.error_code);
    }
  };

  const handleErrorCode = (errorCode: string) => {
    if (errorCode === 'UNAUTHORIZED') {
      setErrorMessage('Access is denied due to invalid credentials.');
      window.sessionStorage.clear();
      history.push('/login');
    } else if (errorCode === 'BAD_REQUEST') {
      setErrorMessage('Validation error. Please check the required fields');
    } else if (errorCode) {
      setErrorMessage('There was an error. Please try again later.');
      console.log(errorCode);
    }
  };

  const toCourseDetails = (id: string) => {
    window.sessionStorage.setItem('course-id', id);
    history.push(`/courses/${id}/recommended/details`);
  };

  const getScore = async () => {
    try {
      const score = await getSmeDashboard();
      console.log(score.data);
      setScoreDetails(score.data);
    } catch (error: any) {
      console.log(error);
      handleErrorCode(error.response.data.error_code);
    }
  };

  const getScoreHistory = async () => {
    try {
      const score = await getSmeScores();
      setScoreHistory(score.data);
    } catch (error: any) {
      console.log(error);
      handleErrorCode(error.response.data.error_code);
    }
  };

  const handleClick = (id: SetStateAction<string>) => {
    if (selectedScore === id) {
      setSelectedScore('');
    } else {
      setSelectedScore(id);
    }
  };

  function formatDateWithMonthName(date: Date) {
    const sDay = new Date(date);
    sDay.setHours(0, 0, 0, 0);

    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const monthIndex = sDay.getMonth();
    const day = sDay.getDate();
    const year = sDay.getFullYear();

    return `${months[monthIndex]} ${day}, ${year}`;
  }

  useEffect(() => {
    getProfileDetails();
    getCourses();
    getScore();
    getScoreHistory();
  }, []);

  const recommendedCourses = () => {
    if (courses.length > 0) {
      const updatedCourse: Course[] = [];

      for (let index = 0; index < courses.length; index++) {
        const course = courses[index];

        const recommendedCourseList = course.drc_score.find(
          (score) => score == scoreDetails?.overall_score
        );

        if (recommendedCourseList) {
          updatedCourse.push(course);
        }
      }

      return updatedCourse;
    }

    return [];
  };

  return (
    <div className="min-h-screen h-100 bg-gradient-to-r from-digitayoCreated1 to-digitayoCreated2 p-6">
      <div className="flex flex-col justify-between relative">
        <div>
          <div
            className="w-8 cursor-pointer p-[4px] ml-[-9px]"
            onClick={() => history.push('/score')}
          >
            <img src={BackIcon} alt="back-icon" width="32" />
          </div>
          <div className="mt-5 mb-6 bg-gradient-to-r from-digitayoOrange1 to-digitayoPurple1 bg-clip-text text-transparent font-bold text-[34px]">
            Score History
          </div>
          <div className="flex flex-row gap-[14px] mt-2 mb-6">
            <div className="flex justify-center items-center rounded-full shadow-lg p-1 w-16 h-16 flex-none">
              <img src={store} alt="store-icon" width="43" />
            </div>
            <div className="flex-1">
              <div className="bg-gradient-to-r from-digitayoOrange1 to-digitayoPurple1 bg-clip-text text-transparent font-bold text-[22px]">
                {data?.business_name || 'Business Name'}
              </div>
              <div>
                {/* <img src={Location} alt="location-icon" width="20" /> */}
                <div className="text-digitayoBlack4 text-md">
                  {`${data?.city}, ${data?.region}`}
                  {/* Nueva Ecija, Region III */}
                </div>
              </div>
            </div>
          </div>

          {!!errorMessage && (
            <ToastPopup error={true} message={errorMessage} onClose={() => setErrorMessage('')} />
          )}

          {scoreHistory.length > 0 && (
            <>
              <div className="flex flex-col mb-8">
                {scoreHistory.map((score: ScoreHistory) => (
                  <>
                    <LevelCard overall_score={score.level} />
                    <div
                      className={`${
                        selectedScore === score.id
                          ? 'opacity-100 h-auto overflow-auto'
                          : 'opacity-0 h-0 overflow-hidden'
                      } transition-all ease-in-out duration-[0.5s]`}
                    >
                      <div className="text-center mx-auto text-digitayoBlack4 mt-8 font-bold text-xl">
                        Score Breakdown
                      </div>
                      <ScoreCard
                        label="Digital Maturity"
                        level={score.maturity_level}
                        icon={DigitalMaturity}
                        progress={(score.maturity_level / 5) * 100}
                      />
                      <ScoreCard
                        label="Digital Readiness"
                        level={score.readiness_level}
                        icon={DigitalReadiness}
                        progress={(score.readiness_level / 5) * 100}
                      />
                    </div>
                    <div className="flex flex-row justify-between mt-4 mb-8">
                      <div className="text-digitayoBlack2 text-base">
                        Taken {formatDateWithMonthName(score.createdAt)}
                      </div>
                      <UnderlineLink
                        fontSize="text-base"
                        fontWeight="font-semibold"
                        onClick={() => handleClick(score.id)}
                      >
                        {selectedScore === score.id ? 'Show less' : 'View more'}
                      </UnderlineLink>
                    </div>
                    <div
                      className={`${
                        selectedScore === score.id
                          ? 'opacity-100 h-auto overflow-auto'
                          : 'opacity-0 h-0 overflow-hidden'
                      } transition-all ease-in-out duration-[0.5s]`}
                    >
                      <div className="text-center mx-auto text-digitayoBlack4 mt-8 font-bold text-xl">
                        Courses You Might Like
                      </div>
                      {recommendedCourses().length > 0 ? (
                        <div className="pb-12">
                          {recommendedCourses()
                            .slice(0, 2)
                            .map((course: Course) => (
                              <CardCourses
                                key={course.id}
                                imageSrc={course.thumbnail}
                                bookmarkSrc={Bookmark}
                                label={course.title}
                                description={course.description}
                                tags={course.tags}
                                onClick={() => toCourseDetails(course.id)}
                              />
                            ))}
                          <UnderlineLink display="block" onClick={() => history.push('/courses')}>
                            <div className="text-md font-semibold text-center mt-8">
                              View more courses
                            </div>
                          </UnderlineLink>
                        </div>
                      ) : (
                        <div className="text-md font-semibold text-digitayoBlack4 h-60 flex flex-col justify-center align-middle items-center">
                          No results found.
                        </div>
                      )}
                    </div>
                  </>
                ))}
              </div>

              {/* <LevelCard overall_score={scoreDetails.overall_score} /> */}

              {/* <div className="text-center mx-auto text-digitayoBlack4 mt-8 font-bold text-xl">
                Score Breakdown
              </div>
              <ScoreCard
                label="Digital Maturity"
                level={3}
                icon={DigitalMaturity}
                progress={(3 / 5) * 100}
              />
              <ScoreCard
                label="Digital Readiness"
                level={3}
                icon={DigitalReadiness}
                progress={(3 / 5) * 100}
              /> */}
              {/* <ScoreCard
                label="Digital Maturity"
                level={scoreDetails.maturity_level}
                icon={DigitalMaturity}
                progress={(scoreDetails.maturity_level / 5) * 100}
              />
              <ScoreCard
                label="Digital Readiness"
                level={scoreDetails.readiness_level}
                icon={DigitalReadiness}
                progress={(scoreDetails.readiness_level / 5) * 100}
              /> */}
              {/* <div className="flex flex-row justify-between mt-5">
                <div className="text-digitayoBlack2 text-base">Taken May 20, 2024</div>
                <UnderlineLink fontSize="text-base" fontWeight="font-semibold" onClick={() => ({})}>
                  View more
                </UnderlineLink>
              </div> */}

              {/* <div className="text-center mx-auto text-digitayoBlack4 mt-8 font-bold text-xl">
                Courses You Might Like
              </div>
              <div className="pb-10">
                {[
                  {
                    id: '1',
                    thumbnail: Card1,
                    Bookmark: 'Mark Book',
                    title: 'Sample Course 1',
                    description: 'Sample Course description',
                    tags: 'Tag 1',
                  },
                  {
                    id: '2',
                    thumbnail: Card2,
                    Bookmark: 'Mark Book',
                    title: 'Sample Course 2',
                    description: 'Sample Course 2 description',
                    tags: 'Tag 2',
                  },
                ]
                  .slice(0, 2)
                  .map((course) => (
                    <CardCourses
                      key={course.id}
                      imageSrc={course.thumbnail}
                      bookmarkSrc={Bookmark}
                      label={course.title}
                      description={course.description}
                      tags={course.tags}
                      onClick={() => toCourseDetails(course.id)}
                    />
                  ))}
                <UnderlineLink onClick={() => history.push('/courses')}>
                  <div className="text-md font-semibold text-center -mt-8 mb-12">
                    View more courses
                  </div>
                </UnderlineLink>
              </div> */}
              {/* <div className="pb-10">
                {courses.length > 0 ? (
                  <>
                    {courses.slice(0, 2).map((course) => (
                      <CardCourses
                        key={course.id}
                        imageSrc={course.thumbnail}
                        bookmarkSrc={Bookmark}
                        label={course.title}
                        description={course.description}
                        tags={course.tags}
                        onClick={() => toCourseDetails(course.id)}
                      />
                    ))}
                    <UnderlineLink onClick={() => history.push('/courses')}>
                      <div className="text-md font-semibold text-center -mt-8 mb-12">
                        View more courses
                      </div>
                    </UnderlineLink>
                  </>
                ) : (
                  <div className="text-md font-semibold text-digitayoBlack4 h-60 flex flex-col justify-center align-middle items-center">
                    No results found.
                  </div>
                )}
              </div> */}
            </>
          )}
        </div>
        <div className="flex flex-col w-full pb-8 gap-3">
          <PrimaryButton isInvalid={false} onClick={() => history.push('/dashboard')}>
            Go to Dashboard
          </PrimaryButton>
          <SecondaryButton
            isValid={false}
            isOrange={true}
            onClick={() => history.push('/digital-assessment')}
          >
            <div></div>
            Retake the Assessment
            <div></div>
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
};

export default Score;
