import Home from '../assets/dashboard/navbar/home.svg';
import AHome from '../assets/dashboard/navbar/a-home.svg';
import SDMEST from '../assets/dashboard/navbar/sdmest.svg';
import ASDMEST from '../assets/dashboard/navbar/a-sdmest.svg';
import Courses from '../assets/dashboard/navbar/courses.svg';
import ACourses from '../assets/dashboard/navbar/a-courses.svg';
import Settings from '../assets/dashboard/navbar/settings.svg';
import ASettings from '../assets/dashboard/navbar/a-settings.svg';
import AResources from '../assets/dashboard/navbar/a-resources.svg';
import Resources from '../assets/dashboard/navbar/resources.svg';
// import AResources from '../assets/dashboard/navbar/a-resources.svg';
import { useHistory } from 'react-router';
import { useState } from 'react';
import SettingsSidebar from './sidebar/SettingsSidebar';

const FooterNavigation = () => {
  const history = useHistory();
  const pathname = window.location.pathname;
  const [openSettingsSidebar, setOpenSettingsSidebar] = useState<boolean>(false);

  return (
    <div className="w-100 pt-4 pb-6 bg-digitayoWhite border-t">
      <div className="flex justify-around w-100 text-xs text-digitayoGray4">
        <div
          className="flex flex-col justify-center items-center cursor-pointer"
          onClick={() => history.push('/dashboard')}
        >
          <img src={`${pathname.includes('dashboard') ? AHome : Home}`} alt="User" width="28" />
          <div
            className={`muted mt-1 ${
              pathname.includes('dashboard') ? 'text-digitayoOrange1' : 'text-digitayoGray4'
            }`}
          >
            Home
          </div>
        </div>
        <div
          className="flex flex-col justify-center items-center cursor-pointer"
          onClick={() => history.push('/digital-assessment')}
        >
          <img
            src={`${pathname.includes('digital-maturity') ? ASDMEST : SDMEST}`}
            alt="Test"
            width="28"
          />
          <div
            className={`muted mt-1 ${
              pathname.includes('digital-maturity') ? 'text-digitayoOrange1' : 'text-digitayoGray4'
            }`}
          >
            Assessment
          </div>
        </div>
        <div
          className="flex flex-col justify-center items-center cursor-pointer"
          onClick={() => history.push('/courses')}
        >
          <img
            src={`${pathname.includes('courses') ? ACourses : Courses}`}
            alt="Learning"
            width="28"
          />
          <div
            className={`muted mt-1 ${
              pathname.includes('courses') ? 'text-digitayoOrange1' : 'text-digitayoGray4'
            }`}
          >
            Courses
          </div>
        </div>
        <div
          className="flex flex-col justify-center items-center cursor-pointer"
          onClick={() => history.push('/resources')}
        >
          <img
            src={`${pathname.includes('resources') ? AResources : Resources}`}
            alt="Resources"
            width="28"
          />
          <div
            className={`muted mt-1 ${
              pathname.includes('resources') ? 'text-digitayoOrange1' : 'text-digitayoGray4'
            }`}
          >
            Resources
          </div>
        </div>
        <div
          className="flex flex-col justify-center items-center cursor-pointer"
          onClick={() => setOpenSettingsSidebar(true)}
        >
          <img
            src={`${pathname.includes('settings') ? ASettings : Settings}`}
            alt="Discount"
            width="28"
          />
          <div
            className={`muted mt-1 ${
              pathname.includes('settings') ? 'text-digitayoOrange1' : 'text-digitayoGray4'
            }`}
          >
            Settings
          </div>
        </div>
        {openSettingsSidebar && (
          <SettingsSidebar
            isOpen={openSettingsSidebar}
            onClose={() => setOpenSettingsSidebar(false)}
          />
        )}
      </div>
    </div>
  );
};

export default FooterNavigation;
