import React from 'react';

type ButtonProps = {
  children: React.ReactNode;
  onClick: () => void;
  isInvalid: boolean;
  type?: 'button' | 'submit';
};

const PrimaryButton = ({ children, onClick, isInvalid: isValid, type = 'button' }: ButtonProps) => {
  return (
    <button
      className={`text-lg text-white rounded-[10px] h-[50px] leading-[50px] px-3 w-full font-bold ${
        !isValid ? 'cursor-pointer bg-digitayoOrange1' : 'cursor-not-allowed bg-digitayoOrange4'
      }`}
      type={type}
      onClick={onClick}
      disabled={isValid}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
