import qs from 'qs';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import filterIcon from '../../../assets/filter-icon.svg';
import PrimaryButton from '../../../components/button/PrimaryButton';
import CardCarousel from '../../../components/CardCarousel';
import TextareaField from '../../../components/form/TextareaField';
import FilterSidebar, { Filters } from '../../../components/sidebar/FilterSidebar';
import UnderlineLink from '../../../components/UnderlineLink';
import { GetCoursesParams } from '../../../types/http';
import { Resource } from './DashboardResources';

type data = {
  id: string;
  thumbnail: string;
  title: string;
  description: string;
  highlight?: string;
  url?: string;
};

type partnersData = {
  id: string;
  logo: string;
  url?: string;
};

type allResourcesTabType = {
  inquiryType: string;
  others: string;
  blogs: Resource[];
  tools: Resource[];
  partners: Resource[];
  onClick: () => void;
};

const allResourcesTab = (
  { inquiryType, others, blogs, tools, partners, onClick }: allResourcesTabType,
  props: { location: { search: string } }
) => {
  const {
    register,
    watch,
    // getValues,
    formState: { isValid },
  } = useForm<{ message: string; inquiryType: string }>();
  let search = props.location?.search ?? '';

  if (search.startsWith('?')) {
    search = search.substring(1);
  }
  const query = qs.parse(search) as { allResources?: string };
  const [isOpenfilterSidebar, setOpenFiltersidebar] = useState<boolean>(false);
  const [tags, setTags] = useState<string[]>([]);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [showFilterCapsules, setShowFilterCapsules] = useState(false);
  const [showViewAll, setShowViewAll] = useState(true);
  const [filters, setFilters] = useState<GetCoursesParams>({
    category: !!query.allResources ? '' : 'recommended',
    orderBy: 'showViewAll',
    bookmarked: !!query.allResources,
  });
  const [searchQuery, setSearchQuery] = useState('');

  const [submitted, setSubmitted] = useState(false);
  const submitForm = async () => {
    const inquiryType = watch('inquiryType');
    const message = watch('message');

    try {
      //   await sendInquiry(inquiryType, message);
      console.log(inquiryType, message);
      setSubmitted(true);
      //   history.push('/inquiry-submitted');
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setFilters({ ...filters, search: searchQuery });
  }, [searchQuery]);

  useEffect(() => {
    if (tags.length) {
      setShowViewAll(false);
    } else {
      setShowViewAll(true);
    }
  }, [tags]);

  const onApplyFilter = (sideFilter: Filters) => {
    setShowFilterCapsules(true);

    setFilters({ ...filters, tags: sideFilter.tags, orderBy: sideFilter.order });
    setOpenFiltersidebar(false);
  };

  const onResetFilter = () => {
    setFilters({
      ...filters,
      tags: '',
      orderBy: 'asc',
    });
    setShowFilterCapsules(false);
    setOpenFiltersidebar(false);
    setShowViewAll(true);
  };

  const categories = [
    'Human Resources (HR)',
    'Communication and Collaboration',
    'Project Management',
    'Customer Relationship - Management',
    'Digital Marketing',
    'Payments',
    'Logistics',
    'Others',
  ];

  const renderCategories = () => {
    return categories.map((category) => {
      if (inquiryType.length && inquiryType !== category) return null;

      const items = tools.filter((tool) => tool.category === category);
      if (!items.length) return null;

      return (
        <div className="mb-5" key={`category-${category}`}>
          <div className="mx-6 mb-5">
            <h3 className="text-digitayoBlack4 font-bold text-lg">{category}</h3>
          </div>
          <CardCarousel data={items} />
        </div>
      );
    });
  };

  const renderHeader = () => {
    if (inquiryType === 'Others' && !!others) {
      return (
        <h3 className="text-digitayoBlack4 font-bold text-xl">
          Here are resources on <br />
          {others}:
        </h3>
      );
    }

    if (inquiryType && inquiryType !== 'Others') {
      return (
        <h3 className="text-digitayoBlack4 font-bold text-xl">
          Here are resources on <br />
          {inquiryType}:
        </h3>
      );
    }

    return <h3 className="text-digitayoBlack4 font-bold text-xl">Recommended for You</h3>;
  };

  const renderSections = () => {
    const sections = [];
    if ((tags.includes('Blogs') && blogs.length > 0) || (showViewAll && blogs.length > 0)) {
      sections.push(
        <div className="mb-5">
          <div className="py-4 mx-6 mb-1">
            <h3 className="bg-gradient-to-r from-digitayoOrange1 to-digitayoPurple1 bg-clip-text text-transparent font-bold text-xl">
              Blogs
            </h3>
          </div>
          <CardCarousel data={blogs} blogs />
          <div className="my-3 text-center text-md font-semibold">
            <UnderlineLink onClick={onClick}>See more</UnderlineLink>
          </div>
        </div>
      );
    }

    if (
      (tags.includes('Digital Tools and Platforms') && tools.length > 0) ||
      (showViewAll && tools.length > 0)
    ) {
      sections.push(
        <div className="mb-5">
          <div className="py-4 mx-6 mb-1">
            <h3 className="bg-gradient-to-r from-digitayoOrange1 to-digitayoPurple1 bg-clip-text text-transparent font-bold text-xl">
              Digital Tools and Platforms
            </h3>
          </div>
          {renderCategories()}
        </div>
      );
    }

    if (
      (tags.includes('Enablers and Partners') && partners.length > 0) ||
      (showViewAll && partners.length > 0)
    ) {
      sections.push(
        <div className="mb-9">
          <div className="py-4 mx-6 mb-1">
            <h3 className="bg-gradient-to-r from-digitayoOrange1 to-digitayoPurple1 bg-clip-text text-transparent font-bold text-xl">
              Enablers and Partners
            </h3>
          </div>
          <CardCarousel data={partners} partners />
        </div>
      );
    }

    if (!sections.length) {
      return (
        <div className="mb-5">
          <div className="py-4 mx-6 mb-1">
            <h4 className="text-digitayoBlack1 font-semibold text-lg">No Resources Found.</h4>
          </div>
        </div>
      );
    }

    return sections;
  };

  return (
    <>
      <div className="pt-4 pb-2 mx-6">
        <div className="flex justify-between items-start">
          {renderHeader()}
          <div
            className="p-1 bg-digitayoWhite rounded-lg cursor-pointer"
            onClick={() => setOpenFiltersidebar(!isOpenfilterSidebar)}
          >
            <img width="35" src={filterIcon} alt="filter-icon" />
          </div>

          <FilterSidebar
            onApplyFilter={onApplyFilter}
            onResetFilter={onResetFilter}
            isOpen={isOpenfilterSidebar}
            onClose={() => setOpenFiltersidebar(false)}
            tags={tags}
            setTags={setTags}
            order={order}
            setOrder={setOrder}
            tagOptions={['Blogs', 'Digital Tools and Platforms', 'Enablers and Partners']}
            viewAll
            showViewAll={showViewAll}
            setShowViewAll={() => {
              setShowViewAll((prev) => {
                if (!prev) {
                  setTags([]);
                }

                return !prev;
              });

              if (showViewAll) {
                setTags([]);
              }
            }}
            resources
          />
        </div>
      </div>

      {renderSections()}
      <div className="mb-12">
        <div className="mx-6 mb-6">
          <h3 className="text-digitayoBlack4 font-bold text-lg">
            Know a great resource we should include? Share your suggestion with us!
          </h3>
        </div>
        <div className="mx-6">
          <form className="">
            <div className="bg-digitayoWhite rounded-xl">
              <TextareaField
                label="Message"
                placeholder="Your message here"
                isValid={watch('message')}
                {...register('message', {
                  required: 'Message is required',
                })}
              />
            </div>
            <div className="pt-[38px]">
              <PrimaryButton isInvalid={!isValid} onClick={submitForm}>
                Submit
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default allResourcesTab;
