export const handleErrorCode = (
  errorCode: string,
  setErrorMessage?: (m: string) => void,
  history?: { push: (loc: string) => void }
) => {
  if (errorCode === 'UNAUTHORIZED') {
    setErrorMessage!('Session has ended. Please login again');
    window.sessionStorage.clear();
    history!.push('/login');
  } else {
    setErrorMessage!('There was an error. Please try again later.');
    console.log(errorCode);
  }
};
