type FilterType = {
  label: string;
  onClick: () => void;
  tags: string[];
};

const CapsuleButton = ({ label, onClick, tags }: FilterType) => {
  const isActive = tags.includes(label);

  return (
    <div
      className={`${
        isActive
          ? 'border-[#CE93D8] bg-digitayoWhite shadow-lg shadow-[#9C27B01A] text-digitayoBlack4'
          : 'bg-digitayoGray1 border-digitayoGray1'
      } text-sm text-digitayoBlack2 px-4 py-2 border-2 rounded-3xl cursor-pointer`}
      onClick={onClick}
    >
      {label}
    </div>
  );
};

export default CapsuleButton;
