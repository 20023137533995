import { forwardRef, useEffect, useState } from 'react';
import TextareaField from './TextareaField';

type BooleanFieldProps = {
  label: string;
  value?: boolean | string;
  withText?: boolean;
  onChange?: (val: boolean | string) => void;
};

const BooleanField = forwardRef<HTMLDivElement, BooleanFieldProps>(
  ({ label, value, onChange, withText }, ref) => {
    const defaultValue = withText ? !!value : value;
    const [boolOption, setBoolOption] = useState(defaultValue);
    const [customAnswer, setCustomAnswer] = useState<string>('');

    useEffect(() => {
      if (!value) onChange?.(false);

      setCustomAnswer(withText ? (value as string) : '');
    }, []);

    useEffect(() => {
      if (withText && typeof value === 'string') {
        setCustomAnswer(value);
        setBoolOption(true);
      } else if (withText && !value) {
        setCustomAnswer('');
      }
    }, [value]);

    const boolOptionChange = (val: boolean) => {
      setBoolOption(val);
      onChange?.(val);
    };

    const onTextChange = (val: string) => {
      onChange?.(val);
    };

    return (
      <div className="text-sm text-digitayoBlack1" ref={ref}>
        <div className="my-2 text-xl">{label}</div>
        <div className="my-2 grid grid-cols-2 gap-3 text-lg">
          <div
            className={`border flex justify-between p-3 rounded-xl cursor-pointer ${
              boolOption ? 'bg-digitayoPurple1 text-white' : 'border-digitayoGray3 bg-transparent'
            }`}
            onClick={() => {
              boolOptionChange(true);
            }}
          >
            <svg
              width="27"
              height="28"
              viewBox="0 0 27 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.9375 6.9375L10.125 20.4375L5.0625 15.375"
                stroke={`${boolOption ? '#fff' : '#43A047'}`}
                strokeWidth="2.7"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <div>Yes</div>
            <div></div>
          </div>
          <div
            className={`border flex justify-between p-3 rounded-xl cursor-pointer ${
              !boolOption ? 'bg-digitayoPurple1 text-white' : 'border-digitayoGray3 bg-transparent'
            }`}
            onClick={() => {
              boolOptionChange(false);
            }}
          >
            <svg
              width="27"
              height="28"
              viewBox="0 0 27 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.5 20.6875L6.5 6.6875M20.5 6.6875L6.5 20.6875"
                stroke={`${boolOption ? '#D32F2F' : '#fff'}`}
                strokeWidth="2.7"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <div>No</div>
            <div></div>
          </div>
        </div>
        {withText && boolOption && (
          <TextareaField
            isValid={customAnswer}
            label="Please Specify Course/s"
            value={customAnswer}
            onChange={(val) => {
              onTextChange(val.target.value);
            }}
          />
        )}
      </div>
    );
  }
);

export default BooleanField;
