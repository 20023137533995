import { RouteComponentProps, useHistory } from 'react-router';
import Card2 from '../../../assets/dashboard/courses/ecommerece.jpg';
import CardCourses from '../../../components/CardCourses';
import DashboardLayout from '../../../layouts/DashboardLayout';
// import Menu from '../../../assets/dashboard/courses/orange-menu.svg';
import debounce from 'lodash/debounce';
import qs from 'qs';
import { useCallback, useEffect, useState } from 'react';
import Bookmark from '../../../assets/dashboard/courses/bookmark.svg';
import Bookmarked from '../../../assets/dashboard/courses/bookmarked.svg';
import Search from '../../../assets/dashboard/courses/search.png';
import filterIcon from '../../../assets/filter-icon.svg';
import FilterSidebar, { Filters } from '../../../components/sidebar/FilterSidebar';
import SMEHubButton from '../../../components/SMEHubButton';
import ToastPopup from '../../../components/Toastr';
import {
  getCourses as apiGetCourses,
  getDrcScore,
  getSmeDashboard,
} from '../../../services/https/api';
import { GetCoursesParams } from '../../../types/http';

// type CourseType = {
//   id: string;
//   title: string;
//   description: string;
//   url: string;
//   tags: Tag[];
//   thumbnail?: string;
//   isSaved: boolean;
// };

type Tag = {
  title: string;
  color: string;
};

type TabType = {
  tab: boolean;
  label: string;
  onClick: () => void;
};

type Course = {
  id: string;
  title: string;
  category: string;
  url: string;
  tags: Tag[];
  created_at: string;
  updated_at: string;
  level: string;
  drc_score: number[];
  thumbnail: string;
  description: string;
  isSaved: boolean;
};

type CourseDrcScore = {
  id: string;
  value: number;
  course_id: string;
};

type ScoreType = {
  overall_score: number;
  has_digital_profile: string;
  took_assessment: boolean;
  maturity_level: number;
  readiness_level: number;
  createdAt: Date;
};

const TabItem = ({ tab, label, onClick }: TabType) => {
  const isTabActive = tab
    ? 'text-digitayoBlack4 border-b-4 border-b-digitayoOrange1'
    : 'text-digitayoGray4';

  return (
    <div className={`w-full px-auto py-3 ${isTabActive}`} onClick={onClick}>
      {label}
    </div>
  );
};

const DashboardCourses = (props: RouteComponentProps) => {
  const history = useHistory();
  const [isOpenfilterSidebar, setOpenFiltersidebar] = useState<boolean>(false);

  let search = props.location?.search ?? '';

  if (search.startsWith('?')) {
    search = search.substring(1);
  }

  const query = qs.parse(search) as { saved?: string };

  const defaultTab = !!query.saved ? 'saved' : 'recommended';

  const [tab, setTab] = useState<string>(defaultTab);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [tags, setTags] = useState<string[]>([]);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [showFilterCapsules, setShowFilterCapsules] = useState(false);

  const [filters, setFilters] = useState<GetCoursesParams>({
    category: !!query.saved ? '' : 'recommended',
    orderBy: 'asc',
    bookmarked: !!query.saved,
  });

  const [searchQuery, setSearchQuery] = useState('');
  const [courses, setCourses] = useState<Course[]>([]);

  const getCoursesInstant = async () => {
    try {
      const dCourses = await apiGetCourses(filters);
      const dScore = await getSmeDashboard();

      const dataCourses: Course[] = dCourses.data.courses;
      const dataScore: ScoreType = dScore.data;

      const updatedCourse: Course[] = [];
      const filteredCourses: Course[] = [];

      if (dataCourses.length > 0) {
        const responseScore = await getDrcScore();
        const scoreList = responseScore.data.scores;

        if (scoreList.length > 0) {
          for (let index = 0; index < dataCourses.length; index++) {
            const course = dataCourses[index];

            const filteredScore: CourseDrcScore[] = scoreList.filter(
              (score: CourseDrcScore) => score.course_id == course.id
            );

            if (filteredScore.length > 0) {
              const drcScores = filteredScore.map((score: CourseDrcScore) => score.value);

              updatedCourse.push({
                ...course,
                drc_score: drcScores,
              });
            } else {
              updatedCourse.push({
                ...course,
                drc_score: [],
              });
            }
          }
        } else {
          dataCourses.forEach((course: Course) => {
            updatedCourse.push(course);
          });
        }

        if (updatedCourse.length > 0) {
          for (let index = 0; index < updatedCourse.length; index++) {
            const course = updatedCourse[index];

            const recommendedCourseList = course.drc_score.find(
              (score: number) => score == dataScore?.overall_score
            );

            if (recommendedCourseList) {
              filteredCourses.push(course);
            }
          }
        }
      }

      setCourses(filteredCourses ?? []);
    } catch (error: any) {
      handleErrorCode(error?.response?.data?.error_code);
    }
  };

  const getCourses = useCallback(() => {
    console.log(filters, tab);
    setLoading(true);
    debounce(async () => {
      await getCoursesInstant();
      setLoading(false);
    }, 1000)();
  }, [filters]);

  useEffect(() => {
    getCourses();
  }, [filters]);

  useEffect(() => {
    setFilters({ ...filters, search: searchQuery });
  }, [searchQuery]);

  useEffect(() => {
    if (tab === 'recommended') {
      setFilters({ ...filters, category: 'recommended', bookmarked: false });
    } else {
      setFilters({ ...filters, category: '', bookmarked: true });
    }
  }, [tab]);

  const onApplyFilter = (sideFilter: Filters) => {
    setShowFilterCapsules(true);

    setFilters({ ...filters, tags: sideFilter.tags, orderBy: sideFilter.order });
    setOpenFiltersidebar(false);
  };

  const onResetFilter = () => {
    setFilters({
      ...filters,
      tags: '',
      orderBy: 'asc',
    });
    setShowFilterCapsules(false);
    setOpenFiltersidebar(false);
  };

  const handleErrorCode = (errorCode: string) => {
    if (errorCode === 'UNAUTHORIZED') {
      setErrorMessage('Session has ended. Please login again');
      window.sessionStorage.clear();
      history.push('/login');
    } else {
      setErrorMessage('There was an error. Please try again later.');
      console.log(errorCode);
    }
  };

  const toCourseDetails = (id: string) => {
    window.sessionStorage.setItem('course-id', id);
    history.push(`/courses/${id}/${tab}/details`);
  };

  return (
    <DashboardLayout label="Courses and Materials" showBackButton>
      <div className="mt-8 flex flex-col flex-auto">
        {!!errorMessage && (
          <div className="mx-4">
            <ToastPopup error={true} message={errorMessage} onClose={() => setErrorMessage('')} />
          </div>
        )}

        <div className="mx-auto w-11/12 mb-2">
          <SMEHubButton label="Access SME Hub" />
        </div>
        <form>
          <div className="text-center mb-3 mx-auto bg-gradient-to-r from-digitayoCreated1 to-digitayoCreated2 w-11/12 rounded-lg flex flex-row justify-between">
            <div className="flex items-center mx-4 cursor-pointer">
              <img width="20" src={Search} alt="search-icon" />
            </div>
            <input
              type="text"
              placeholder="Search"
              className="py-2 bg-transparent w-3/4 outline-0 text-black"
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.target.value)}
            />
            <div
              className="p-1 m-[10px] bg-digitayoWhite rounded-lg cursor-pointer"
              onClick={() => setOpenFiltersidebar(!isOpenfilterSidebar)}
            >
              <img width="36" src={filterIcon} alt="filter-icon" />
            </div>
            {isOpenfilterSidebar && (
              <FilterSidebar
                onApplyFilter={onApplyFilter}
                onResetFilter={onResetFilter}
                isOpen={isOpenfilterSidebar}
                onClose={() => setOpenFiltersidebar(false)}
                tags={tags}
                setTags={setTags}
                order={order}
                setOrder={setOrder}
                setShowViewAll={() => ({})}
              />
            )}
          </div>
        </form>

        <div className="my-2 mx-6 flex flex-row gap-2 text-xs overflow-x-auto">
          {showFilterCapsules && (
            <div className="py-1 px-2 bg-digitayoGray1 rounded-[13px] text-digitayoBlack2 whitespace-nowrap">
              {order === 'asc' ? 'Sort from A-Z' : 'Sort from Z-A'}
            </div>
          )}
          {tags?.map((item, index) => {
            return (
              <div key={index}>
                {item && (
                  <div className="py-1 px-4 bg-digitayoGray1 rounded-[13px] text-digitayoBlack2 whitespace-nowrap">
                    {item}
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <div className="grid grid-cols-2 text-center cursor-pointer font-semibold">
          <TabItem
            tab={tab === 'recommended'}
            label="Recommended"
            onClick={() => setTab('recommended')}
          />
          <TabItem tab={tab === 'saved'} label="Saved" onClick={() => setTab('saved')} />
        </div>

        <div className="bg-gradient-to-r from-digitayoCreated1 to-digitayoCreated2 py-4 flex-1">
          {loading && <p className="mx-6 text-black text-center">Loading...</p>}
          {!loading && (
            <div className="mx-6">
              {courses.length > 0 ? (
                courses.map((course, index) => {
                  return (
                    <CardCourses
                      key={index}
                      imageSrc={course?.thumbnail ?? Card2}
                      bookmarkSrc={course.isSaved ? Bookmarked : Bookmark}
                      label={course.title}
                      description={course.description}
                      tags={course.tags}
                      onClick={() => toCourseDetails(course.id)}
                    />
                  );
                })
              ) : (
                <div className="text-md text-digitayoBlack4 h-60 flex flex-col justify-center align-middle items-center">
                  No results found.
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default DashboardCourses;
