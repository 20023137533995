type AnswerType = {
  option?: string;
  icon?: string;
  description: string;
  selected: boolean;
  onSelect: () => void;
};

const DasAnswerButton = ({ option, icon, description, selected, onSelect }: AnswerType) => {
  return (
    <div
      className={`rounded-lg flex cursor-pointer my-3 ${
        selected
          ? 'text-white bg-digitayoPurple1'
          : 'border border-digitayoGray3 text-digitayoBlack4'
      }`}
      onClick={onSelect}
    >
      <div
        className={`border-r border-digitayoGray3 flex items-center justify-center font-bold text-xl ${
          icon ? 'p-2' : 'p-5'
        }`}
      >
        {icon ? <img src={icon} alt="not-applicable" width="34" /> : <b>{option}</b>}
      </div>
      <div className="p-4 text-left text-lg">{description}</div>
    </div>
  );
};

export default DasAnswerButton;
