type OptionType = {
  icon?: string;
  key: string;
  label: string;
};

type MultiSelectProps = {
  label: string;
  value?: string;
  options: OptionType[];
  onChange?: (val: string) => void;
};

const MultiSelect = ({ label, options, onChange, value }: MultiSelectProps) => {
  const handleCheckboxChange = (item: OptionType) => {
    const selectedOptions = value?.split(',') ?? [];

    if (selectedOptions.includes(item.key)) {
      const newOptions = selectedOptions.filter((key) => key !== item.key && key !== '');
      // setSelectedOptions(newOptions);
      onChange?.(newOptions.join(','));
    } else {
      const newOptions = [...selectedOptions, item.key];
      // setSelectedOptions(newOptions);
      onChange?.(newOptions.filter((key) => key !== '').join(','));
    }
  };

  return (
    <div className="text-sm text-digitayoBlack1 mt-2">
      <div className="my-2 text-xl">{label}</div>
      <div className="grid grid-cols-2 gap-3 my-2">
        {options.map((item, i) => {
          const isSelected = value?.split(',').includes(item.key);
          return (
            <label
              className={` ${
                isSelected ? 'border-digitayoPurple3' : 'border-digitayoGray3'
              } p-3 border flex flex-col items-center justify-end rounded-lg cursor-pointer`}
              key={item.key}
            >
              <input
                id={item.key}
                type="checkbox"
                name={`option${i}`}
                value={item.label}
                className="ml-auto accent-green-600 bg-digitayoWhite cursor-pointer"
                onChange={() => handleCheckboxChange(item)}
                checked={isSelected}
              />
              <div className="-mt-3">
                <img src={item.icon} width={27} height={27} alt="icon" />
              </div>
              <div>{item.label}</div>
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default MultiSelect;
