import OnboardingImg1 from '../assets/onboarding/onboarding-1.png';
import OnboardingImg2 from '../assets/onboarding/onboarding-2.png';
import { motion } from 'framer-motion';
import Carousel from '../components/Carousel';

type SlideType = {
  header: string;
  subHeader: string;
  image: string;
};

const data: SlideType[] = [
  {
    header: 'Unlock digital success for your business',
    subHeader: `Alamin kung paano mapapalago ang iyong customer base at income gamit ang online platforms.`,
    image: OnboardingImg1,
  },
  {
    header: 'Libreng courses para matuto ng digital skills',
    subHeader: `Learn and train your staff to have new capabilities—mula pag-set up ng digital inventory hanggang online marketing.`,
    image: OnboardingImg2,
  },
];

const Onboarding = () => {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <Carousel slides={data} />
    </motion.div>
  );
};

export default Onboarding;
