import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import isAuthenticated from './IsAuthenticatedUtils';

interface ProtectedRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, ...rest }) => {
  const renderComponent = ({ ...props }) => {
    if (isAuthenticated()) {
      return <Component {...props} />;
    } else {
      return <Redirect to="/login" />;
    }
  };

  return <Route {...rest} render={renderComponent} />;
};

export default ProtectedRoute;
