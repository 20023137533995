import { useQuery } from '@tanstack/react-query';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { RouteComponentProps, useHistory } from 'react-router';
import ToastPopup from '../../../components/Toastr';
import { GetResourcesParams } from '../../../types/http';

import Search from '../../../assets/dashboard/courses/search.png';
import filterIcon from '../../../assets/filter-icon.svg';
import FilterSidebar, { Filters } from '../../../components/sidebar/FilterSidebar';
import DashboardLayout from '../../../layouts/DashboardLayout';

import AllResourcesTab from '../Resources/AllResourcesTab';
import BlogsTab from '../Resources/BlogsTab';

import PrimaryButton from '../../../components/button/PrimaryButton';
import CustomSelect from '../../../components/form/CustomSelect';
import InputField from '../../../components/form/InputField';
import { getAllResources, getBlogTags } from '../../../services/https/api';

type TabType = {
  tab: boolean;
  label: string;
  onClick: () => void;
};

const TabItem = ({ tab, label, onClick }: TabType) => {
  const isTabActive = tab
    ? 'text-digitayoBlack4 border-b-4 border-b-digitayoOrange1'
    : 'text-digitayoGray4';

  return (
    <div className={`w-full px-auto py-3 ${isTabActive}`} onClick={onClick}>
      {label}
    </div>
  );
};

export type Tag = {
  tagColor: string;
  tagName: string;
};

// export type ContactNumber = {
//   number: string;
// };

export type Promo = {
  title?: string;
  description?: string;
};

export type Resource = {
  id: string;
  title: string;
  description: string;
  url: string;
  contact_numbers?: string[];
  image: string;
  type: 'tools' | 'blogs' | 'enablers';
  email_address?: string;
  promo?: Promo;
  tags?: Tag[];
  category?: string;
};

interface Option {
  code: string;
  name: string;
}

const DashboardCourses = (props: RouteComponentProps) => {
  const history = useHistory();
  const [isOpenfilterSidebar, setOpenFiltersidebar] = useState<boolean>(false);

  let search = props.location?.search ?? '';

  if (search.startsWith('?')) {
    search = search.substring(1);
  }
  const query = qs.parse(search) as { allResources?: string };
  const defaultTab = !!query.allResources ? 'blogs' : 'allResources';
  const [tab, setTab] = useState<string>(defaultTab);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [tags, setTags] = useState<string[]>([]);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [showFilterCapsules, setShowFilterCapsules] = useState(false);
  const [showViewAll, setShowViewAll] = useState(true);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);

  const [filters, setFilters] = useState<GetResourcesParams>({});
  const [searchQuery, setSearchQuery] = useState('');

  const { data: blogTags = [] } = useQuery<string[]>({
    queryKey: ['resource', 'tags'],
    queryFn: async () => {
      try {
        const { data } = await getBlogTags();

        return data.tags;
      } catch (error) {
        const err = error as { response: { data: { error_code: string } } };
        handleErrorCode(err?.response?.data?.error_code);
      }
    },
    initialData: [],
  });

  const { data: blogs, error: blogError } = useQuery<Resource[]>({
    queryKey: ['resource', 'blogs', filters],
    queryFn: async ({ queryKey: [, type, filters] }) => {
      try {
        const q: GetResourcesParams = {
          ...(filters as GetResourcesParams),
          type: type as 'blogs' | 'enablers' | 'tools' | undefined,
          limit: 5,
        };

        const { data } = await getAllResources(q);

        return data.resources.filter((resource: Resource) => resource.type === 'blogs');
      } catch (error) {
        const err = error as { response: { data: { error_code: string } } };
        handleErrorCode(err?.response?.data?.error_code);
      }
    },
  });

  const { data: tools, error: toolsError } = useQuery<Resource[]>({
    queryKey: ['resource', 'tools', filters],
    queryFn: async ({ queryKey: [, type, filters] }) => {
      try {
        const q: GetResourcesParams = {
          ...(filters as GetResourcesParams),
          type: type as 'blogs' | 'enablers' | 'tools' | undefined,
        };

        const { data } = await getAllResources(q);

        return data.resources.filter((resource: Resource) => resource.type === 'tools');
      } catch (error) {
        const err = error as { response: { data: { error_code: string } } };
        handleErrorCode(err?.response?.data?.error_code);
      }
    },
  });

  const { data: enablers, error: enablersError } = useQuery<Resource[]>({
    queryKey: ['resource', 'enablers', filters],
    queryFn: async ({ queryKey: [, type, filters] }) => {
      try {
        const q: GetResourcesParams = {
          ...(filters as GetResourcesParams),
          type: type as 'blogs' | 'enablers' | 'tools' | undefined,
          limit: 5,
          orderBy: 'desc',
        };

        const { data } = await getAllResources(q);

        return data.resources.filter((resource: Resource) => resource.type === 'enablers');
      } catch (error) {
        const err = error as { response: { data: { error_code: string } } };
        handleErrorCode(err?.response?.data?.error_code);
      }
    },
  });

  const {
    control,
    watch,
    formState: { isValid },
    handleSubmit,
    register,
  } = useForm<{ message?: string; inquiryType?: string; search?: string }>();

  const submitForm = async (values: {
    message?: string;
    inquiryType?: string;
    search?: string;
  }) => {
    const params: GetResourcesParams = { ...filters };
    if (values.search) {
      params.search = values.search;
    }

    if (values.inquiryType !== 'Others') {
      params.category = values.inquiryType;
    }

    try {
      setFilters(params);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setFilters({ ...filters, search: searchQuery });
  }, [searchQuery]);

  useEffect(() => {
    if (tab === 'recommended') {
      setFilters({ ...filters, type: undefined });
    } else {
      setFilters({ ...filters, type: 'blogs' });
    }
  }, [tab]);

  const onApplyFilter = (sideFilter: Filters) => {
    setShowFilterCapsules(true);

    setFilters({ ...filters, tags: sideFilter.tags, orderBy: sideFilter.order });
    setOpenFiltersidebar(false);
  };

  const onResetFilter = () => {
    setFilters({
      ...filters,
      type: undefined,
    });
    setShowFilterCapsules(false);
    setOpenFiltersidebar(false);
    setShowViewAll(false);
  };

  useEffect(() => {
    if (tags.length) {
      setShowViewAll(false);
    } else {
      setShowViewAll(true);
    }
  }, [tags]);

  const handleErrorCode = (errorCode: string) => {
    if (errorCode === 'UNAUTHORIZED') {
      setErrorMessage('Session has ended. Please login again');
      window.sessionStorage.clear();
      history.push('/login');
    } else {
      setErrorMessage('There was an error. Please try again later.');
      console.log(errorCode);
    }
  };

  const category = watch('inquiryType');

  const inquiryTypes = [
    'Human Resources (HR)',
    'Communication and Collaboration',
    'Project Management',
    'Customer Relationship - Management',
    'Digital Marketing',
    'Payments',
    'Logistics',
    'Others',
  ];

  return (
    <DashboardLayout label="Resources" showBackButton>
      <div className="mt-8 flex flex-col flex-auto">
        {!!errorMessage && (
          <div className="mx-4">
            <ToastPopup error={true} message={errorMessage} onClose={() => setErrorMessage('')} />
          </div>
        )}

        {tab === 'allResources' && (
          <div className="mx-6 mb-7">
            <form className="">
              <div className="bg-digitayoWhite rounded-xl">
                <Controller
                  control={control}
                  name="inquiryType"
                  render={({ field }) => (
                    <CustomSelect
                      value={field.value}
                      label="I want to learn more about"
                      selectedOption={{ code: field.value, name: field.value } as Option}
                      setSelectedOption={(opt) => {
                        field.onChange(opt.code);
                      }}
                      options={inquiryTypes.map((str) => ({ code: str, name: str }))}
                    />
                  )}
                />
              </div>
              {category === 'Others' && (
                <div className="mt-4">
                  <Controller
                    control={control}
                    name="search"
                    render={({ field }) => (
                      <InputField label="Others" placeholder="Input specific keywords" {...field} />
                    )}
                  />
                </div>
              )}
              <div className="mt-8">
                <PrimaryButton isInvalid={!isValid} onClick={handleSubmit(submitForm)}>
                  Submit
                </PrimaryButton>
              </div>
            </form>
          </div>
        )}

        {tab === 'blogs' && (
          <>
            <form onSubmit={handleSubmit(submitForm)}>
              <div className="text-center mb-3 mx-auto bg-gradient-to-r from-digitayoCreated1 to-digitayoCreated2 w-11/12 rounded-lg flex flex-row justify-between">
                <div className="flex items-center mx-4 cursor-pointer">
                  <img width="20" src={Search} alt="search-icon" />
                </div>
                <input
                  type="text"
                  placeholder="Search"
                  className="py-2 bg-transparent w-3/4 outline-0 text-black"
                  {...register('search')}
                  onSubmit={handleSubmit(submitForm)}
                />
                <div
                  className="p-1 m-[10px] bg-digitayoWhite rounded-lg cursor-pointer"
                  onClick={() => setOpenFiltersidebar(!isOpenfilterSidebar)}
                >
                  <img width="36" src={filterIcon} alt="filter-icon" />
                </div>

                <FilterSidebar
                  onApplyFilter={onApplyFilter}
                  onResetFilter={onResetFilter}
                  isOpen={isOpenfilterSidebar}
                  onClose={() => setOpenFiltersidebar(false)}
                  tags={tags}
                  setTags={setTags}
                  order={order}
                  setOrder={setOrder}
                  tagOptions={[
                    'Success',
                    'Digital',
                    'Valuation',
                    'Customer Service',
                    'Social Media',
                    'Logistics',
                    'Marketing',
                    ...(blogTags ?? []),
                  ]}
                  viewAll
                  showViewAll={showViewAll}
                  setShowViewAll={() => {
                    setShowViewAll((prev) => {
                      if (!prev) {
                        setTags([]);
                      }

                      return !prev;
                    });

                    if (showViewAll) {
                      setTags([]);
                    }
                  }}
                />
              </div>
            </form>

            <div className="my-2 mx-6 flex flex-row gap-2 text-xs overflow-x-auto">
              {showViewAll && (
                <div className="py-1 px-2 bg-digitayoGray1 rounded-[13px] text-digitayoBlack2 whitespace-nowrap">
                  View All
                </div>
              )}
              {tags?.map((item, index) => {
                return (
                  <div key={index}>
                    {item && (
                      <div className="py-1 px-4 bg-digitayoGray1 rounded-[13px] text-digitayoBlack2 whitespace-nowrap">
                        {item}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </>
        )}

        <div className="grid grid-cols-2 text-center cursor-pointer font-semibold">
          <TabItem
            tab={tab === 'allResources'}
            label="All Resources"
            onClick={() => setTab('allResources')}
          />
          <TabItem tab={tab === 'blogs'} label="Blogs" onClick={() => setTab('blogs')} />
        </div>
        <div className="bg-gradient-to-r from-digitayoCreated1 to-digitayoCreated2 py-4 flex-1">
          {/* {loading && <p className="mx-6 text-black text-center">Loading...</p>} */}
          {tab === 'allResources' && (
            <AllResourcesTab
              inquiryType={filters?.category ?? ''}
              others={filters?.search ?? ''}
              blogs={blogs ?? []}
              tools={tools ?? []}
              partners={enablers ?? []}
              onClick={() => setTab('blogs')}
            />
          )}
          {tab === 'blogs' && <BlogsTab filters={filters} setFilters={setFilters} />}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default DashboardCourses;
