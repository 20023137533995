import { useHistory } from 'react-router';
import PrimaryButton from '../../components/button/PrimaryButton';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { resetPassword } from '../../services/https/api';
import { useState } from 'react';
import ToastPopup from '../../components/Toastr';
import ForgotPasswordIcon from '../../assets/password/password.png';
import PasswordField from '../../components/form/PasswordField';

const ResetPassword = () => {
  const history = useHistory();
  const [isNewPasswordValid, setpasswordValid] = useState<string | boolean>();
  const [isRetypeNewPasswordValid, setRetypeNewPasswordValid] = useState<string | boolean>();

  const { getValues, setValue, handleSubmit } = useForm<{
    password: string;
    retypePassword: string;
  }>();
  const { id, code } = useParams<{ id: string; code: string }>();
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [passwordError, setPasswordError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const validatePassword = (password: string) => {
    if (
      password.match(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[-#!$@%^&*()_+|~=`{}\[\]:";'<>?,.\/ ]).{8,}$/g
      )
    ) {
      return true;
    } else {
      return 'Password must be at least 8 characters long.';
    }
  };

  const handleNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    const passwordError = validatePassword(password);
    setValue('password', password);
    setpasswordValid(passwordError);

    const retypePassword = getValues('retypePassword');

    setPasswordMatch(password === retypePassword);
  };

  const handleRetypePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const retypePassword = event.target.value;
    const passwordError = validatePassword(retypePassword);
    setValue('retypePassword', retypePassword);
    setRetypeNewPasswordValid(passwordError);

    setPasswordMatch(getValues('password') === retypePassword);
  };

  const handleErrorCode = (errorCode: string) => {
    if (errorCode === 'INVALID_CODE' || errorCode === 'RESET_PASSWORD_EXPIRED') {
      setErrorMessage('Link expired, try requesting for change password again.');
    } else if (errorCode) {
      setErrorMessage('There was an error changing your password, Please try again later.');
      console.log(errorCode);
    }
  };

  const onSubmit = async () => {
    const password = getValues('password');
    const retypePassword = getValues('retypePassword');

    setPasswordMatch(password === retypePassword);
    if (!passwordMatch) {
      return;
    }

    try {
      await resetPassword({
        code,
        id,
        password,
      });

      window.localStorage.setItem('isForgotPassword', 'true');
      history.push('/updated-password');
    } catch (error: any) {
      console.log(error.response.data.error_code);
      handleErrorCode(error.response.data.error_code);
    }
  };

  return (
    <div className="bg-digitayoWhite min-h-screen h-100 p-7 font-archivo flex flex-col justify-between">
      <div>
        <div className="my-6 bg-gradient-to-r from-digitayoOrange1 to-digitayoPurple1 bg-clip-text text-transparent font-bold text-[34px]">
          Forgot Password
        </div>

        <img src={ForgotPasswordIcon} alt="forgot-password-image" className="w-1/2 mx-auto py-4" />

        <div className="text-xl my-8">
          <div className="text-digitayoBlack4 flex flex-col">
            <div>
              <b>Create New Password</b>
            </div>
            <div>Your new password must be different from your previously used password</div>
          </div>
        </div>
        {!!errorMessage && (
          <ToastPopup error={true} message={errorMessage} onClose={() => setErrorMessage('')} />
        )}
        {!!passwordError && (
          <ToastPopup
            error={true}
            message={
              'password should be: min 8 chars, at least 1 lowercase, uppercase, number, and symbol'
            }
            onClose={() => setPasswordError(false)}
          />
        )}

        <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
          <PasswordField
            id="newPassword"
            label="New Password"
            placeholder="Your password"
            isValid={isNewPasswordValid !== true}
            onChange={handleNewPassword}
          />

          <PasswordField
            id="retypePassword"
            label="Re-type New Password"
            placeholder="Type your password again"
            isValid={isRetypeNewPasswordValid !== true}
            onChange={handleRetypePassword}
          />
        </form>
      </div>

      <div className="flex flex-col my-6">
        <PrimaryButton
          type="submit"
          isInvalid={passwordError || !passwordMatch}
          onClick={() => onSubmit()}
        >
          Continue
        </PrimaryButton>
      </div>
    </div>
  );
};

export default ResetPassword;
