import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import score0 from '../../assets/das/0black.svg';
import score1 from '../../assets/das/1black.svg';
import score2 from '../../assets/das/2black.svg';
import score3 from '../../assets/das/3black.svg';
import score4 from '../../assets/das/4black.svg';
import score5 from '../../assets/das/5black.svg';
import SdmestIcon from '../../assets/das/modal-icon.svg';
import Bookmark from '../../assets/dashboard/courses/bookmark.svg';
import CheckIcon from '../../assets/dashboard/home/check.svg';
import DigiTayoLogo from '../../assets/dashboard/home/digi-tayo-logo.png';
import DigitalReadiness from '../../assets/dashboard/home/digital-readiness.jpg';
import HelpCenterIcon from '../../assets/dashboard/home/help-center.svg';
import SavedCoursesIcon from '../../assets/dashboard/home/saved-courses.svg';
import UsaidLogo from '../../assets/dashboard/home/usaid-logo.png';
import ErrorIcon from '../../assets/error.svg';
import DigitalProfileIcon from '../../assets/modal/digital-profile.svg';
import PrimaryButton from '../../components/button/PrimaryButton';
import SecondaryButton from '../../components/button/SecondaryButton';
import CardCourses from '../../components/CardCourses';
import Modal from '../../components/modal/Modal';
// import SocialLinks from '../../components/social-links';
import ToastPopup from '../../components/Toastr';
import UnderlineLink from '../../components/UnderlineLink';
import DashboardLayout from '../../layouts/DashboardLayout';
import {
  getCourses as apiGetCourses,
  getDrcScore,
  getProfile,
  getSmeDashboard,
} from '../../services/https/api';

type CourseType = {
  id: string;
  title: string;
  description: string;
  url: string;
  tags: Tag[];
  thumbnail: string;
  isSaved: boolean;
};

type Tag = {
  title: string;
  color: string;
};

type ScoreType = {
  overall_score: number;
  has_digital_profile: string;
  took_assessment: boolean;
  maturity_level: number;
  readiness_level: number;
  createdAt: Date;
};

type Course = {
  id: string;
  title: string;
  category: string;
  url: string;
  tags: Tag[];
  created_at: string;
  updated_at: string;
  level: string;
  drc_score: number[];
  thumbnail: string;
  description: string;
  isSaved: boolean;
};

type CourseDrcScore = {
  id: string;
  value: number;
  course_id: string;
};

const DashboardHome = () => {
  const history = useHistory();
  const [popup, setPopup] = useState<boolean>(false);
  const [popup2, setPopup2] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [scoreDetails, setScoreDetails] = useState<ScoreType>();
  const [profile, setProfile] = useState<any>({});
  const [courses, setCourses] = useState<CourseType[]>([]);
  const sdmestData = {
    title: 'What is the DRC?',
    icon: SdmestIcon,
  };

  const handleErrorCode = (errorCode: string) => {
    if (errorCode === 'UNAUTHORIZED') {
      setErrorMessage('Access is denied due to invalid credentials.');
      window.sessionStorage.clear();
      history.push('/login');
    } else if (errorCode === 'BAD_REQUEST') {
      setErrorMessage('Validation error. Please check the required fields');
    } else if (errorCode) {
      setErrorMessage('There was an error. Please try again later.');
      console.log(errorCode);
    }
  };

  const toCourseDetails = (id: string) => {
    window.sessionStorage.setItem('course-id', id);
    history.push(`/courses/${id}/recommended/details`);
  };

  // const getScore = async () => {
  //   try {
  //     const score = await getSmeDashboard();
  //     setScoreDetails(score.data);
  //   } catch (error: any) {
  //     console.log(error);
  //     handleErrorCode(error.response.data.error_code);
  //   }
  // };

  const getProfileDetails = async () => {
    try {
      const profile = await getProfile();
      setProfile(profile.data.sme);
    } catch (error: any) {
      console.log(error);
      handleErrorCode(error.response?.data.error_code);
    }
  };

  const handleOnSavedCourses = () => {
    history.push('/courses?saved=1');
    window.localStorage.setItem('isSavedCourses', 'true');
  };

  const modalData = {
    title: 'What is a digital profile?',
    icon: DigitalProfileIcon,
  };

  const getCourses = async () => {
    try {
      const dCourses = await apiGetCourses({
        category: 'recommended',
        orderBy: 'asc',
      });

      const dScore = await getSmeDashboard();
      const scoreData: ScoreType = dScore.data;

      const dataCourses: Course[] = dCourses.data.courses;

      const updatedCourse: Course[] = [];
      const filteredCourses: Course[] = [];

      if (dataCourses.length > 0) {
        const responseScore = await getDrcScore();
        const scoreList = responseScore.data.scores;

        if (scoreList.length > 0) {
          for (let index = 0; index < dataCourses.length; index++) {
            const course = dataCourses[index];

            const filteredScore: CourseDrcScore[] = scoreList.filter(
              (score: CourseDrcScore) => score.course_id == course.id
            );

            if (filteredScore.length > 0) {
              const drcScores = filteredScore.map((score: CourseDrcScore) => score.value);

              updatedCourse.push({
                ...course,
                drc_score: drcScores,
              });
            } else {
              updatedCourse.push({
                ...course,
                drc_score: [],
              });
            }
          }
        } else {
          dataCourses.forEach((course: Course) => {
            updatedCourse.push(course);
          });
        }

        if (updatedCourse.length > 0) {
          for (let index = 0; index < updatedCourse.length; index++) {
            const course = updatedCourse[index];

            const recommendedCourseList = course.drc_score.find(
              (score: number) => score == scoreData?.overall_score
            );

            if (recommendedCourseList) {
              filteredCourses.push(course);
            }
          }
        }
      }

      setScoreDetails(scoreData);
      setCourses(filteredCourses);
    } catch (error: any) {
      handleErrorCode(error?.response?.data?.error_code);
    }
  };

  useEffect(() => {
    getCourses();
    // getScore();
    getProfileDetails();
  }, []);

  return (
    <DashboardLayout
      isHome={true}
      businessName={profile.business_name || 'Business Name'}
      city={profile.city || 'City'}
      region={profile.region || 'Region'}
      isDashboard={true}
    >
      <ul className="flex justify-between items-center gap-2 absolute -top-12 left-5 right-5">
        <li
          className="bg-digitayoWhite text-digitayoBlack4 text-center rounded-xl p-3 flex-1 shadow-lg shadow-[#9C27B01A] cursor-pointer"
          onClick={() => history.push('/score')}
        >
          {scoreDetails?.overall_score === 0 && (
            <img src={score0} alt="score-0" className="w-10 my-0 mx-auto" />
          )}
          {scoreDetails?.overall_score === 1 && (
            <img src={score1} alt="score-1" className="w-10 my-0 mx-auto" />
          )}
          {scoreDetails?.overall_score === 2 && (
            <img src={score2} alt="score-2" className="w-10 my-0 mx-auto" />
          )}
          {scoreDetails?.overall_score === 3 && (
            <img src={score3} alt="score-3" className="w-10 my-0 mx-auto" />
          )}
          {scoreDetails?.overall_score === 4 && (
            <img src={score4} alt="score-4" className="w-10 my-0 mx-auto" />
          )}
          {scoreDetails?.overall_score === 5 && (
            <img src={score5} alt="score-5" className="w-10 my-0 mx-auto" />
          )}
          <span className="font-bold text-xs">Score</span>
        </li>
        <li
          className="bg-digitayoWhite text-digitayoBlack4 text-center rounded-xl p-3 flex-1 shadow-lg shadow-[#9C27B01A] cursor-pointer"
          onClick={handleOnSavedCourses}
        >
          <img src={SavedCoursesIcon} className="w-10 my-0 mx-auto" alt="Saved Courses Icon" />
          <span className="font-bold text-xs">Saved Courses</span>
        </li>
        <li
          className="bg-digitayoWhite text-digitayoBlack4 text-center rounded-xl p-3 flex-1 shadow-lg shadow-[#9C27B01A] cursor-pointer"
          onClick={() => history.push('/helpdesk')}
        >
          <img src={HelpCenterIcon} className="w-10 my-0 mx-auto" alt="Help Center Icon" />
          <span className="font-bold text-xs">Help Center</span>
        </li>
      </ul>
      <div className="mt-16 mx-6 text-digitayoBlack4 text-md">
        {!!errorMessage && (
          <ToastPopup error={true} message={errorMessage} onClose={() => setErrorMessage('')} />
        )}
        {!profile.hasDigitalProfile && (
          <div className="mb-4 border p-4 border-digitayoBlack4 rounded-lg bg-digitayoWhite">
            <div className="flex justify-start text-sm">
              <img src={ErrorIcon} alt="error-icon" width="30" className="mx-2" />
              <div>
                Build your <b>digital profile</b> to get personalized recommendations for your
                business.
              </div>
            </div>
            <div className="flex justify-between gap-4 my-2">
              <PrimaryButton onClick={() => history.push('/digital-profile/1')} isInvalid={false}>
                <div className="text-xs">Build Digital Profile</div>
              </PrimaryButton>
              <SecondaryButton
                onClick={() => console.log('digital profile info')}
                isValid={false}
                isOrange={true}
              >
                <div></div>
                <div className="text-xs" onClick={() => setPopup2(true)}>
                  What's a Digital Profile?
                </div>
                <div></div>
              </SecondaryButton>
              {popup2 && (
                <Modal
                  modalData={modalData}
                  hasDigitalProfile={true}
                  onClose={() => setPopup2(false)}
                />
              )}
            </div>
          </div>
        )}
      </div>
      <div className="mb-5 mx-6 shadow-lg shadow-[#9C27B01A] text-digitayoBlack4 text-md rounded-xl bg-digitayoWhite">
        <img src={DigitalReadiness} className="w-full rounded-t-xl" alt="Digital Readiness" />
        <div className="p-4">
          <h3 className="bg-gradient-to-r from-digitayoOrange1 to-digitayoPurple1 bg-clip-text text-transparent font-bold text-xl pb-4 mt-3">
            Your current Digital Maturity and Readiness Level is {scoreDetails?.overall_score}.
          </h3>
          <p className="pb-4">
            Welcome to DigiTayo! Here, you'll find free resources to prepare your business for
            success in the digital landscape.
          </p>
          <p className="pb-4">
            Alamin ang iyong tunay na digital maturity at readiness gamit ang &nbsp;
            <span className="font-bold">Digital Readiness Check (DRC)</span>
            &nbsp; and unlock more features with your results.
          </p>
          <div className="mt-4">
            <PrimaryButton isInvalid={!true} onClick={() => history.push('/digital-assessment')}>
              Take the Test
            </PrimaryButton>
            <div className="mt-5 text-center text-md mb-3 font-semibold">
              <UnderlineLink onClick={() => setPopup(true)}>What is the DRC?</UnderlineLink>
            </div>
          </div>
        </div>
        {popup && (
          <Modal modalData={sdmestData} hasDashboard={true} onClose={() => setPopup(false)} />
        )}
      </div>
      <div className="mb-5 mx-6 shadow-lg shadow-[#9C27B01A] rounded-xl border bg-digitayoWhite">
        <div className="p-4 mt-4">
          <h3 className="bg-gradient-to-r from-digitayoOrange1 to-digitayoPurple1 bg-clip-text text-transparent font-bold text-xl">
            Recommended Courses
          </h3>
          {/* {courses.length >= 2 && (
            <>
              <CardCourses
                imageSrc={courses[0].thumbnail}
                bookmarkSrc={Bookmark}
                label={courses[0].title}
                description={courses[0].description}
                tags={courses[0].tags}
                onClick={() => toCourseDetails(courses[0].id)}
              />
              <CardCourses
                imageSrc={courses[1].thumbnail}
                bookmarkSrc={Bookmark}
                label={courses[1].title}
                description={courses[1].description}
                tags={courses[1].tags}
                onClick={() => toCourseDetails(courses[1].id)}
              />
            </>
          )} */}
          {courses.length > 0 ? (
            <>
              {courses.slice(0, 2).map((course) => (
                <CardCourses
                  imageSrc={course.thumbnail}
                  bookmarkSrc={Bookmark}
                  label={course.title}
                  description={course.description}
                  tags={course.tags}
                  onClick={() => toCourseDetails(course.id)}
                  key={course.id}
                />
              ))}
              <div className="my-3 text-center text-md font-semibold">
                <UnderlineLink onClick={() => history.push('/courses')}>See more</UnderlineLink>
              </div>
            </>
          ) : (
            <div className="text-md font-semibold text-digitayoBlack4 h-60 flex flex-col justify-center align-middle items-center">
              No results found.
            </div>
          )}
          {/* <div className="my-3 text-center text-md font-semibold">
            <UnderlineLink onClick={() => history.push('/courses')}>See more</UnderlineLink>
          </div> */}
        </div>
      </div>
      <div className="mb-7 mx-6 shadow-lg shadow-[#9C27B01A] text-digitayoBlack4 rounded-xl border bg-digitayoWhite">
        <div className="p-4 my-4">
          <h3 className="bg-gradient-to-r from-digitayoOrange1 to-digitayoPurple1 bg-clip-text text-transparent font-bold text-[20px] mb-10">
            Know More About DigiTayo
          </h3>
          <img src={DigiTayoLogo} className="w-1/2 mx-auto my-5" alt="DigiTayo Logo" />
          <p className="pb-6">
            DigiTayo is a program dedicated to enable Filipino SMEs for digital success. This
            platform offers a diverse selection of certified trainings and courses, endorsed by the
            Department of Trade and Industry and USAID, enabling businesses to acquire essential
            skills for growth.
          </p>
          <ul className="flex gap-4 pb-6">
            <li className="shadow-lg shadow-[#9C27B01A] rounded-xl py-4 px-2 text-center w-1/2">
              <img src={CheckIcon} className="w-10 mx-auto -mt-6 mb-2" alt="Check Icon" />
              Assess your business' digital readiness
            </li>
            <li className="shadow-lg shadow-[#9C27B01A] rounded-xl py-4 px-2 text-center w-1/2">
              <img src={CheckIcon} className="w-10 mx-auto -mt-6 mb-2" alt="Check Icon" />
              Accelerate your business with free courses
            </li>
          </ul>
          <div className="flex gap-4 items-center">
            <div className="w-1/2">
              <img src={UsaidLogo} className="w-full" alt="USAID Logo" />
            </div>
            <p className="w-1/2 text-xs text-digitayoBlack1">
              DigiTayo is a program under the US Agency for International Development's (USAID)
              Strengthening Private Enterprise for the Digital Economy (SPEED) project.
            </p>
          </div>
          {/* <SocialLinks /> */}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default DashboardHome;
