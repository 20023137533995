import { ReactElement } from 'react';
import DigitayoAuthBG from '../assets/signup/auth-bg.jpg';

type SignupLayoutType = {
  title: string;
  children: ReactElement;
  subTitle?: string;
};

const SignupLayout = ({ title, children, subTitle }: SignupLayoutType) => {
  return (
    <div className="min-h-screen h-100 flex flex-col bg-gradient-to-br from-digitayoOrange2 to-digitayoPurple1">
      <div className="relative w-full h-96">
        <img className="object-cover w-full h-96" src={DigitayoAuthBG} alt="Digitayo Logo" />
      </div>
      <div className="bg-digitayoWhite p-7 rounded-t-[30px] flex-1 relative">
        <div className="bg-gradient-to-r from-digitayoOrange1 to-digitayoPurple1 bg-clip-text text-transparent font-bold text-[34px] mt-2 leading-10">
          {title}
        </div>
        <div className="text-lg font-extralight text-digitayoBlack4 tracking-wider">
          {subTitle ? subTitle : 'Enter your details below'}
        </div>
        {children}
      </div>
    </div>
  );
};

export default SignupLayout;
