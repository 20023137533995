import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import Bookmark from '../../../assets/dashboard/courses/bookmark.svg';
import Bookmarked from '../../../assets/dashboard/courses/bookmarked.svg';
import BackIcon from '../../../assets/left-arrow-white.svg';
import SecondaryButton from '../../../components/button/SecondaryButton';
import Capsules, { CourseTag } from '../../../components/Capsules';
import FooterNavigation from '../../../components/FooterNavigation';
import SMEHubButton from '../../../components/SMEHubButton';
import ToastPopup from '../../../components/Toastr';
import { getCourse, setBookmarkCourse } from '../../../services/https/api';

type CourseType = {
  id: string;
  title: string;
  description: string;
  url: string;
  tags: string;
  isSaved?: boolean;
  thumbnail: string;
};

const CourseDetails = () => {
  const [courseDetails, setCourseDetails] = useState<CourseType>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const history = useHistory();
  const { tab } = useParams<{ tab: string }>();
  const [capsules, setCapsules] = useState<CourseTag[]>([]);

  const getCourseDetails = async (id: string) => {
    try {
      const result = await getCourse({ id: id });
      setCourseDetails(result.data.course);
      setCapsules(JSON.parse(result?.data?.course?.tags ?? '[]'));
    } catch (error: any) {
      console.log(error);
      handleErrorCode(error.response.data.error_code);
    }
  };

  const handleErrorCode = (errorCode: string) => {
    if (errorCode === 'UNAUTHORIZED') {
      setErrorMessage('Session has ended. Please login again');
      window.sessionStorage.clear();
      history.push('/login');
    } else {
      setErrorMessage('There was an error. Please try again later.');
      console.log(errorCode);
    }
  };

  const bookmarkCourse = async (id: string) => {
    try {
      await setBookmarkCourse({ id });
      history.push('/courses');
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    const id = window.sessionStorage.getItem('course-id');
    if (id) {
      getCourseDetails(id);
    }
  }, []);

  return (
    <div className="flex flex-col justify-between min-h-screen h-full relative">
      <div className="ml-2 cursor-pointer absolute top-4 p-2 z-30" onClick={() => history.goBack()}>
        <img src={BackIcon} alt="back-icon" width="32" />
      </div>
      <img src={courseDetails?.thumbnail} alt="course-image" className="flex flex-1" />
      <div className="z-20">
        <div className="w-full h-[375px] bg-gradient-to-t from-transparent/0 to-black/10 z-10 absolute top-0 left-0"></div>
        <div className="h-20 bg-gradient-to-t from-zinc-50 to-transparent -mt-20"></div>
        <div className="p-4 bg-zinc-50 relative">
          {!!errorMessage && (
            <div className="mx-4">
              <ToastPopup error={true} message={errorMessage} onClose={() => setErrorMessage('')} />
            </div>
          )}
          <div className="bg-gradient-to-r from-digitayoOrange1 to-digitayoPurple1 bg-clip-text text-transparent text-[28px] font-bold mr-32 leading-8">
            {courseDetails?.title}
          </div>
          <div className="my-2 text-lg text-digitayoBlack2 mr-24">{courseDetails?.description}</div>
          <Capsules capsules={capsules} />

          <div className="my-4 mb-28 flex flex-row gap-6 items-center">
            <div className="w-8/12">
              <SMEHubButton label="Open Course" courseLink={courseDetails?.url ?? ''} />
            </div>
            <div className="w-4/12 my-0">
              <SecondaryButton isOrange={true} isValid={false}>
                {courseDetails?.id && (
                  <div
                    className="flex flex-row mx-auto justify-center"
                    onClick={() => bookmarkCourse(courseDetails.id)}
                  >
                    {!courseDetails.isSaved ? (
                      <div className="flex justify-center mx-auto text-center">
                        <img src={Bookmark} alt="bookmark-icon" className="mr-2" width="26" /> Save
                      </div>
                    ) : (
                      <div className="flex justify-center mx-auto text-center">
                        <img src={Bookmarked} alt="bookmark-icon" className="mr-2" width="26" />{' '}
                        Unsave
                      </div>
                    )}
                  </div>
                )}
              </SecondaryButton>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 max-w-md w-full z-20">
        <FooterNavigation></FooterNavigation>
      </div>
    </div>
  );
};

export default CourseDetails;
