import PrimaryButton from '../components/button/PrimaryButton';
import SecondaryButton from '../components/button/SecondaryButton';

type CompletedType = {
  icon: string;
  title: string;
  alignment?: string;
  primaryBtnLabel: string;
  secondaryBtnLabel?: string;
  children: React.ReactNode;
  onSecondaryClick?: () => void;
  onPrimaryClick: () => void;
  iconWidth?: string;
};

const CompletedLayout = ({
  icon,
  title,
  alignment,
  primaryBtnLabel,
  secondaryBtnLabel,
  children,
  onSecondaryClick,
  onPrimaryClick,
  iconWidth,
}: CompletedType) => {
  return (
    <div className="min-h-screen h-full text-gray-500 bg-gradient-to-r from-digitayoCreated1 to-digitayoCreated2">
      <div className="bg-gradient-to-b from-transparent to-white px-10 min-h-screen h-full flex flex-col justify-evenly items-center">
        <div className="py-4">
          <img
            className="img-fluid scale-110"
            src={icon}
            width={iconWidth ? iconWidth : '200'}
            alt="created-image"
          />
        </div>
        <div className={`font-inter ${alignment === 'center' ? 'text-center' : ''}`}>
          <div className="bg-gradient-to-r from-digitayoOrange1 to-digitayoPurple1 bg-clip-text text-transparent font-bold text-[34px] leading-10">
            {title}
          </div>
          {children}
        </div>
        <div className="flex flex-col w-full pb-8 gap-3">
          {onSecondaryClick && (
            <SecondaryButton isValid={false} isOrange={true} onClick={onSecondaryClick}>
              <div></div>
              {secondaryBtnLabel}
              <div></div>
            </SecondaryButton>
          )}
          <PrimaryButton isInvalid={false} onClick={onPrimaryClick}>
            {primaryBtnLabel}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default CompletedLayout;
