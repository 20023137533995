import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PrimaryButton from '../../components/button/PrimaryButton';
import { initiateResetPassword } from '../../services/https/api';
import ToastPopup from '../../components/Toastr';
import ForgotPasswordIcon from '../../assets/password/password.png';

const ForgotPassword = () => {
  const [isButtonInvalid, setButtonInvalid] = useState<string | boolean>();
  const [isEmailValid, setEmailValid] = useState<string | boolean>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [successMessage, setSuccessMessage] = useState('');
  const { setValue, watch } = useForm();

  const validateEmail = (email: string) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (pattern.test(email)) {
      return true;
    } else {
      return 'Invalid email format.';
    }
  };

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;
    const emailError = validateEmail(email);
    setValue('email', email);
    setEmailValid(emailError);
    setButtonInvalid(false);
  };

  const handleErrorCode = (errorCode: string) => {
    if (errorCode === 'WRONG_CREDENTIALS') {
      setErrorMessage('Invalid email.');
    } else if (errorCode) {
      setErrorMessage('Email does not exist.');
    }
  };

  const onSubmit = async () => {
    const email = watch('email');

    try {
      await initiateResetPassword({
        email,
      });

      setSuccessMessage('We have sent a reset link to your email.');
      setErrorMessage('');
    } catch (error: any) {
      // TODO: handle error
      handleErrorCode(error.response.data.error_code);
    }
  };

  useEffect(() => {
    setButtonInvalid(true);
  }, []);

  return (
    <div className="flex flex-col justify-between bg-digitayoWhite min-h-screen h-100 p-7 font-archivo">
      <div className="">
        <div className="my-6 bg-gradient-to-r from-digitayoOrange1 to-digitayoPurple1 bg-clip-text text-transparent font-bold text-[34px]">
          Forgot Password
        </div>

        <img src={ForgotPasswordIcon} alt="Forgot-password-image" className="w-1/2 mx-auto py-4" />

        <div className="text-xl my-8">
          <div className="text-digitayoBlack4">
            Please enter your email address to receive a link that will allow you to reset your
            password.
          </div>
        </div>

        {!!errorMessage && (
          <ToastPopup error={true} message={errorMessage} onClose={() => setErrorMessage('')} />
        )}
        <div className="my-6">
          {!!successMessage && (
            <ToastPopup
              success={true}
              message={successMessage}
              onClose={() => setSuccessMessage('')}
            />
          )}
          {!successMessage && (
            <form onSubmit={onSubmit}>
              <div className="border rounded-lg border-digitayoGray3 p-2">
                <div
                  className={`text-sm text-digitayoBlack1 p-1 pb-0 mb-0 ${
                    isEmailValid !== true || 'text-green-600'
                  }`}
                >
                  Email Address
                </div>
                <div className="flex justify-between text-xl">
                  <input
                    id="email"
                    type="text"
                    className="flex flex-grow rounded py-2 text-digitayoBlack4 px-1 outline-0 bg-digitayoWhite"
                    placeholder="e.g. jdelacruz@yahoo.com"
                    onChange={handleEmail}
                  />
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
      <div className="">
        {!successMessage && (
          <PrimaryButton
            onClick={onSubmit}
            isInvalid={isButtonInvalid == true || isEmailValid !== true}
          >
            Update
          </PrimaryButton>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
