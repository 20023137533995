import { useHistory } from 'react-router';
import CompletedLayout from '../layouts/CompletedLayout';
import UnderlineLink from '../components/UnderlineLink';
import Created from '../assets/password/account-confetti.svg';
import { useState } from 'react';
import Modal from '../components/modal/Modal';
import DigitalProfileIcon from '../assets/modal/digital-profile.svg';

const AccountCreated = () => {
  const history = useHistory();
  const [popup, setPopup] = useState<boolean>(false);

  const modalData = {
    title: 'What is a digital profile?',
    icon: DigitalProfileIcon,
  };

  return (
    <CompletedLayout
      icon={Created}
      title="Your DigiTayo account has been created!"
      primaryBtnLabel="Build Digital Profile"
      secondaryBtnLabel="Go to Dashboard"
      onSecondaryClick={() => history.push('/dashboard')}
      onPrimaryClick={() => history.push('/digital-profile/1')}
      iconWidth="400"
    >
      <div className="py-4 text-digitayoBlack4">
        <div className="py-4 text-[20px] leading-7">
          You’re now one step closer to being a digital-ready business!
        </div>
        <div className="font-bold text-lg">You may choose to:</div>
        <ul className="my-4 list-disc mx-4 text-lg flex flex-col gap-3">
          <li>Go to the dashboard and answer your digital profile at a later time</li>
          <li>Proceed to build your digital profile by answering a few more questions</li>
        </ul>
        <div className="mt-4 mb-8 text-md ml-4">
          What is <UnderlineLink onClick={() => setPopup(true)}>digital profile</UnderlineLink>?
        </div>
        {popup && (
          <Modal modalData={modalData} hasDigitalProfile={true} onClose={() => setPopup(false)} />
        )}
      </div>
    </CompletedLayout>
  );
};

export default AccountCreated;
