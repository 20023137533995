import React, { useState } from 'react';

interface Option {
  code: string;
  name: string;
}

type CustomSelect = {
  label?: string;
  value?: string;
  options: { code: string; name: string }[];
  placeholder?: string;
  selectedOption: Option;
  setSelectedOption: (...args: any[]) => void;
};

const CustomSelect = ({
  label,
  value,
  placeholder,
  options,
  selectedOption,
  setSelectedOption,
}: CustomSelect) => {
  const [isOpen, setIsOpen] = useState(false);
  //   const [selectedOption, setSelectedOption] = useState<Option | null>(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option: Option) => {
    setSelectedOption(option);
    setIsOpen(false);
    value = option.name;
  };

  return (
    <div className="border rounded-lg border-digitayoGray3 p-2 mt-2 relative">
      <label
        className={`text-sm text-digitayoBlack1 p-1 pb-0 mb-0 ${
          selectedOption && selectedOption.name ? 'text-green-600' : ''
        }`}
      >
        {label}
      </label>
      <div
        className="flex justify-between items-center py-1 px-1 cursor-pointer"
        onClick={handleToggle}
      >
        <span
          className={`text-xl ${
            selectedOption && selectedOption.name ? 'text-digitayoBlack4' : 'text-digitayoBlack3'
          }`}
        >
          {selectedOption && selectedOption.name
            ? selectedOption.name
            : 'e.g. Human Resources (HR)'}
        </span>
        <svg
          className="w-4 h-4 text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
        </svg>
      </div>
      {isOpen && (
        <ul className="absolute mt-4 -ml-2 z-10 w-full overflow-hidden bg-white border border-digitayoGray3 rounded-md">
          {options.map((option) => (
            <li
              key={option.code}
              className="py-[10px] px-4 text-xl text-digitayoBlack4 hover:bg-digitayoOrange1 hover:text-digitayoWhite cursor-pointer border-b border-digitayoGray3 last:border-none transition duration-500 ease-in-out hover:transition hover:ease-in-out"
              onClick={() => handleSelect(option)}
            >
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomSelect;
