import { useHistory } from 'react-router';
import CompletedLayout from '../layouts/CompletedLayout';
import CheckConfetti from '../assets/password/check-confetti.svg';
import AccountConfetti from '../assets/password/account-confetti.svg';
import { useEffect, useState } from 'react';
import isAuthenticated from '../utils/IsAuthenticatedUtils';

const UpdatedPassword = () => {
  const history = useHistory();
  // const [isChangePassword, setIsChangePassword] = useState<string | null>('');

  const isLoggedIn = isAuthenticated();

  // useEffect(() => {
  //   const data = window.localStorage.getItem('changedPassword');
  //   setIsChangePassword(data);
  // }, []);

  return (
    <CompletedLayout
      icon={isLoggedIn ? AccountConfetti : CheckConfetti}
      title="Your password has been updated!"
      alignment="center"
      primaryBtnLabel={`${!isLoggedIn ? 'Login' : 'Go to Dashboard'}`}
      onPrimaryClick={() => {
        if (!isLoggedIn) {
          history.push('/login');
        } else {
          history.push('/dashboard');
        }
      }}
      iconWidth="400"
    >
      <div className="text-xl py-4 text-digitayoBlack4 text-center mx-auto">
        For added protection, consider changing your password every three months.
      </div>
    </CompletedLayout>
  );
};

export default UpdatedPassword;
