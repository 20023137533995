import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import UnderlineLink from '../components/UnderlineLink';
import R1 from '../assets/signup/requirement1.svg';
import R2 from '../assets/signup/requirement2.svg';
import R3 from '../assets/signup/requirement3.svg';

type ContentDataType = {
  header: string;
  item1: string;
  item2: string;
  item3: string;
};

type OnboardingType = {
  imgSrc: string;
  imgAlt: string;
  content: ContentDataType;
  btnTitle: string;
  onRoute: () => void;
  hasCheckboxes?: boolean;
};

const OnboardingLayout = ({
  imgSrc,
  imgAlt,
  content,
  btnTitle,
  onRoute,
  hasCheckboxes,
}: OnboardingType) => {
  const location = useLocation();
  const [checked1, setChecked1] = useState<boolean>(false);
  const [checked2, setChecked2] = useState<boolean>(false);
  const [checked3, setChecked3] = useState<boolean>(false);
  const history = useHistory();

  const handleCheckboxChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked1(event.target.checked);
  };
  const handleCheckboxChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked2(event.target.checked);
  };
  const handleCheckboxChange3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked3(event.target.checked);
  };

  useEffect(() => {
    if (!window.sessionStorage.getItem('access_token')) {
      history.push('/signup');
      return;
    }
  }, []);

  return (
    <div className="bg-digitayoWhite min-h-screen h-100 p-7 font-archivo flex flex-col justify-between">
      <div>
        <div className="my-6 bg-gradient-to-r from-digitayoOrange1 to-digitayoPurple1 bg-clip-text text-transparent font-bold text-[34px]">
          Before we begin...
        </div>

        <img src={imgSrc} alt={imgAlt} className="w-1/2 mx-auto py-4" />
      </div>

      <div>
        <div className="mt-8 mb-6">
          <div className="text-digitayoBlack4 text-xl">{content.header}</div>
        </div>

        {!hasCheckboxes && (
          <div className="mx-6 mt-4 text-digitayoBlack4 text-lg">
            <div className="flex gap-4 pb-4 border-b border-gray-200">
              <div>
                <img src={R1} alt="r1-icon" width="22" />
              </div>
              <div>{content.item1}</div>
            </div>
            <div className="mt-4 flex gap-4 pb-3 border-b border-gray-200">
              <div>
                <img src={R2} alt="r2-icon" width="22" />
              </div>
              <div>{content.item2}</div>
            </div>
            <div className="mt-4 flex gap-4 pb-3">
              <div>
                <img src={R3} alt="r3-icon" width="22" />
              </div>
              <div>{content.item3}</div>
            </div>
          </div>
        )}

        {hasCheckboxes && (
          <div className="mx-6 mt-4">
            <div className="flex pb-3">
              <input
                type="checkbox"
                checked={checked1}
                className="accent-green-600 mb-3 bg-digitayoWhite w-5 cursor-pointer"
                onChange={handleCheckboxChange1}
              />
              <label
                className={`px-2 border-b border-gray-200 ml-3 pb-3 w-80 text-lg ${
                  checked1 ? 'text-digitayoBlack4' : 'text-digitayoBlack1'
                }`}
              >
                {content.item1}
                {location.pathname === '/terms-and-conditions' ? (
                  <UnderlineLink
                    onClick={() =>
                      window.open('https://digitayoph-terms-and-conditions.webflow.io/', '_blank')
                    }
                  >
                    Terms and Conditions
                  </UnderlineLink>
                ) : (
                  <></>
                )}
              </label>
            </div>
            <div className="flex pb-3">
              <input
                type="checkbox"
                checked={checked2}
                className="accent-green-600 mb-3 bg-digitayoWhite w-5 cursor-pointer"
                onChange={handleCheckboxChange2}
              />
              <label
                className={`px-2 border-b border-gray-200 ml-3 pb-3 w-80 text-lg ${
                  checked2 ? 'text-digitayoBlack4' : 'text-digitayoBlack1'
                }`}
              >
                {content.item2}
                {location.pathname === '/terms-and-conditions' ? (
                  <UnderlineLink
                    onClick={() =>
                      window.open('https://digitayoph-privacy-policy.webflow.io/', '_blank')
                    }
                  >
                    Privacy Policy
                  </UnderlineLink>
                ) : (
                  <></>
                )}
              </label>
            </div>
            <div className="flex mb-3">
              <input
                type="checkbox"
                checked={checked3}
                className="accent-green-600 bg-digitayoWhite w-5 cursor-pointer"
                onChange={handleCheckboxChange3}
              />
              <label
                className={`px-2 border-b border-gray-200 ml-3 pb-3 w-80 text-lg ${
                  checked3 ? 'text-digitayoBlack4' : 'text-digitayoBlack1'
                }`}
              >
                {content.item3}
              </label>
            </div>
          </div>
        )}
      </div>

      <div className="flex flex-col mt-16 mb-6">
        <button
          className={`text-lg text-white rounded-md py-4 px-3 w-full font-bold ${
            hasCheckboxes && !(checked1 && checked2 && checked3)
              ? 'cursor-not-allowed bg-digitayoOrange4'
              : 'cursor-pointer bg-digitayoOrange1'
          }`}
          onClick={onRoute}
          disabled={hasCheckboxes && !(checked1 && checked2 && checked3)}
        >
          {btnTitle}
        </button>
      </div>
    </div>
  );
};

export default OnboardingLayout;
