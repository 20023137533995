import { useContext, useEffect, useState } from 'react';
import InputField from '../../components/form/InputField';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import BusinessInfoFormSection from '../../components/section/BusinessInfoFormSection';
import { BusinessInfoContext } from '../../context/BusinessInfoContext';

const BusinessInfo1 = () => {
  const [completed, setCompleted] = useState<boolean>(false);
  const history = useHistory();

  const { register, watch, getValues, setValue } = useForm<{ business_name: string }>();

  const businessInfo = useContext(BusinessInfoContext);

  const data = {
    title: 'Business Information',
    heading: 'What’s the name of your business?',
    progress: 33.3,
  };

  useEffect(() => {
    const sme = JSON.parse(window.sessionStorage.getItem('profile') as string);
    const accessToken = window.sessionStorage.getItem('access_token');

    if (sme && accessToken) {
      setValue('business_name', sme.business_name);
      if (sme.business_name === undefined) {
        setCompleted(false);
      } else {
        setCompleted(true);
      }
    }
  }, []);

  useEffect(() => {
    const subscription = watch((value) => {
      if (!!value.business_name) {
        businessInfo.setValue({
          ...Object(businessInfo.value),
          business_name: value.business_name,
        });
        setCompleted(true);
      } else {
        setCompleted(false);
      }
    });
    return () => subscription.unsubscribe();
  }, [businessInfo, watch]);

  const nextPage = () => {
    const storedData = window.sessionStorage.getItem('profile');
    const myObject = storedData ? JSON.parse(storedData) : {};
    myObject['business_name'] = getValues('business_name');
    window.sessionStorage.setItem('profile', JSON.stringify(myObject));
    history.push('/business-info/2');
  };

  return (
    <BusinessInfoFormSection
      name={data.title}
      nProgress={data.progress}
      heading={data.heading}
      isComplete={completed}
      onClick={() => nextPage()}
    >
      <>
        <InputField
          label="Business Name (as stated Certificate of Business Name Registration)"
          placeholder="Enter Business Name"
          isValid={watch('business_name')}
          {...register('business_name', {
            required: 'Business Name is required.',
          })}
        />
      </>
    </BusinessInfoFormSection>
  );
};

export default BusinessInfo1;
