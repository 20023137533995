import { useState } from 'react';
import ArrowRight from '../assets/carousel/right-arrow.png';
import ArrowLeft from '../assets/carousel/left-arrow.png';
import { useHistory } from 'react-router';
import PrimaryButton from './button/PrimaryButton';
import SecondaryButton from './button/SecondaryButton';

type SlideType = {
  header: string;
  subHeader: string;
  image: string;
};

type PropType = {
  onboarding?: boolean;
  slides: SlideType[];
};

const Carousel = ({ slides }: PropType) => {
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const history = useHistory();

  const nextSlide = () => {
    const index: number = currentImageIndex < 1 ? currentImageIndex + 1 : 0;
    setCurrentImageIndex(index);
  };

  const prevSlide = () => {
    const index: number = currentImageIndex > 0 ? currentImageIndex - 1 : 1;
    setCurrentImageIndex(index);
  };

  const toSignupPage = () => {
    history.push('/signup');
  };

  const toLoginPage = () => {
    history.push('/login');
  };

  return (
    <div
      className={`min-h-screen h-100 flex flex-col justify-between bg-gradient-to-br
      ${
        currentImageIndex === 0
          ? 'from-digitayoOrange1 to-digitayoOrange2'
          : currentImageIndex === 1
          ? 'from-digitayoPurple1 to-digitayoPurple2'
          : 'from-digitayoBlue1 to-digitayoBlue2'
      }
    `}
    >
      <div className="flex flex-row justify-between text-white">
        <div className="w-1/6 m-auto" onClick={prevSlide}>
          {currentImageIndex !== 0 && (
            <img className="cursor-pointer mx-auto w-4" src={ArrowLeft} alt="Arrow Left" />
          )}
        </div>
        <div className="w-4/6 flex flex-col justify-between text-center">
          <div className="mt-20 font-bold text-[34px] leading-10">
            <span>{slides[currentImageIndex].header}</span>
          </div>
          <div className="text-md font-normal my-2">{slides[currentImageIndex].subHeader}</div>
        </div>
        <div className="w-1/6 m-auto" onClick={nextSlide}>
          {currentImageIndex !== 1 && (
            <img className="cursor-pointer mx-auto w-4" src={ArrowRight} alt="Arrow Right" />
          )}
        </div>
      </div>

      <div className="flex flex-row justify-center items-center">
        {slides.map((dot, index) => {
          return (
            <div key={index}>
              <div
                className={`rounded-full w-3 h-3 mx-1 my-8 cursor-pointer
                  ${index === currentImageIndex ? 'bg-digitayoWhite' : 'border-slate-50 border-2'}`}
                onClick={() => setCurrentImageIndex(index)}
              ></div>
            </div>
          );
        })}
      </div>

      <div className="relative flex flex-col justify-center items-center h-full">
        <img
          className="relative w-full"
          src={slides[currentImageIndex].image}
          alt="Onboarding image"
        />
        <div className="absolute bottom-0 left-0 w-full h-60 bg-gradient-to-b from-transparent to-digitayoBlack4"></div>
        <div className="absolute bottom-10 w-80 md:w-96">
          <PrimaryButton isInvalid={false} onClick={toSignupPage}>
            Sign Up
          </PrimaryButton>
          <div className="bg-black bg-opacity-20 mt-3">
            <SecondaryButton isValid={false} isOrange={false} onClick={toLoginPage}>
              <div></div>
              Login
              <div></div>
            </SecondaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
