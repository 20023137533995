import { ReactElement } from 'react';
import ProgressBar from '../ProgressBar';
import PrimaryButton from '../button/PrimaryButton';

type BusinessInfoFormSectionProps = {
  name?: string;
  progress?: string;
  nProgress?: number;
  image?: string;
  heading?: string;
  children: ReactElement;
  onClick: () => void;
  isComplete: boolean;
  isHeadingSmaller?: boolean;
  onBack?: () => void;
  onClose?: () => void;
};

export default function BusinessInfoFormSection({
  name,
  progress,
  nProgress,
  image,
  heading,
  onClick,
  isComplete,
  isHeadingSmaller,
  children,
  onBack,
  onClose,
}: BusinessInfoFormSectionProps) {
  return (
    <div className="bg-digitayoWhite min-h-screen h-100 flex flex-col justify-between">
      <div>
        <div className="text-digitayoBlack4 flex justify-between text-center items-end h-24 pb-2 font-inter font-semibold text-lg">
          <span className="w-10 h-8">
            {onBack && (
              <button className="ml-6 h-7" onClick={onBack}>
                <svg
                  width="12"
                  height="19"
                  viewBox="0 0 12 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.0903 1.32031L1.90967 9.50098L10.0903 17.6816"
                    stroke="#263238"
                    strokeWidth="2.01"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            )}
          </span>
          <span>{name}</span>
          <span className="w-10 h-8">
            {onClose && (
              <button className="mr-6 h-7" onClick={onClose}>
                <svg
                  width="18"
                  height="17"
                  viewBox="0 0 18 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 15.5L2 1.5M16 1.5L2 15.5"
                    stroke="#263238"
                    strokeWidth="2.01"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            )}
          </span>
        </div>
        <ProgressBar progress={progress} nProgress={nProgress} />
        <div className="flex flex-col justify-start items-center font-inter text-gray-500 px-10 h-100">
          <div className="mt-8">
            <div
              className={`bg-gradient-to-r from-digitayoOrange1 to-digitayoPurple1 bg-clip-text text-transparent font-bold ${
                isHeadingSmaller ? 'text-[28px]' : 'text-[34px]'
              } leading-10 pb-6`}
            >
              {image ? <img src={image} className="text-center mx-auto" alt="icon" /> : heading}
            </div>
            {children}
          </div>
        </div>
      </div>
      <div className="mx-auto w-80 md:w-96 mt-10 mb-10">
        <PrimaryButton isInvalid={!isComplete} onClick={onClick}>
          Continue
        </PrimaryButton>
      </div>
    </div>
  );
}
