import { useContext, useEffect, useMemo, useState } from 'react';
import BusinessInfoFormSection from '../../components/section/BusinessInfoFormSection';
import SelectField from '../../components/form/SelectField';
import InputField from '../../components/form/InputField';
import { useForm } from 'react-hook-form';
import BooleanField from '../../components/form/BooleanField';
import TextareaField from '../../components/form/TextareaField';
import MultiSelect from '../../components/form/MultiSelect';
import { RouteChildrenProps, useHistory } from 'react-router';
import MobileField from '../../components/form/MobileField';
import {
  DigitalProfileContext,
  Question,
  QuestionOption,
  QuestionnaireCategory,
} from '../../context/DigitalProfileContext';
import _chunk from 'lodash/chunk';
import SelectOneOrTextField from '../../components/form/SelectOneOrTextField';

const DigitalProfile = ({ match }: RouteChildrenProps<{ page: string }>) => {
  const [currentQuestions, setCurrentQuestions] = useState<Question[]>([]);
  const [currentCategory, setCurrentCategory] = useState<QuestionnaireCategory>();
  const {
    questionnaire,
    loadQuestions,
    totalQuestions,
    answers,
    setAnswer,
    editMode,
    submitQuestionnaireAnswer,
  } = useContext(DigitalProfileContext);
  const page = match?.params?.page ? parseInt(match.params.page) : 1;
  const history = useHistory();
  const { register, watch, setValue } = useForm<{
    [key: string]: string | number | boolean;
  }>();

  const questionnaireCount: number = useMemo(() => {
    let nQuestions = 0;
    questionnaire?.categories.map((c) => {
      c.questions.map(() => {
        nQuestions++;
      });
    });

    return nQuestions;
  }, [questionnaire]);

  useEffect(() => {
    (async () => {
      await loadQuestions();
      loadQuestionsByPage();
    })();
  }, []);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name) setAnswer(name, value[name] as string);
    });
    return () => subscription.unsubscribe();
  }, [watch, setAnswer]);

  useEffect(() => {
    loadQuestionsByPage();
  }, [questionnaire, page]);

  const mappedAnswers: { [key: string]: string } =
    useMemo(() => {
      const map = answers?.reduce((acc, val) => {
        return { ...acc, [val.question_id]: val.answer };
      }, {});
      return map;
    }, [answers, page]) ?? {};

  const currentQuestionErrors = useMemo(() => {
    const errors: { [questionId: string]: string } = {};

    // validations
    currentQuestions.map((q) => {
      // optional question
      if (q.code === 'participant-profile-under-represented-groups') return;

      if (q.question_type === 'boolean' || q.question_type === 'boolean_with_text') return;

      if (q.required && !mappedAnswers[q.id]) {
        errors[q.id] = 'required';
      }

      if (!mappedAnswers[q.id]) return;

      if (q.validation_type?.startsWith('number')) {
        // check if not a number.
        if (isNaN(mappedAnswers[q.id] as unknown as number)) {
          errors[q.id] = 'This must be a number.';
        } else {
          const pattern = /number\((\d+),(\d+)\)/;
          const matches = q.validation_type.match(pattern);

          if (matches) {
            const ans = parseInt(mappedAnswers[q.id]);
            const min = parseInt(matches[1]);
            const max = parseInt(matches[2]);

            if (ans < min || ans > max) {
              errors[q.id] = `This must be between ${min} and ${max}`;
            }
          }
        }
      } else if (q.validation_type === 'email') {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!regex.test(mappedAnswers[q.id])) {
          errors[q.id] = 'This must be a valid email';
        }
      }
    });

    return errors;
  }, [mappedAnswers, currentQuestions]);

  const loadQuestionsByPage = () => {
    let categoryIndex = 0;

    function chunkedQuestion() {
      if (!questionnaire) return [];
      if (!questionnaire.categories.length) return;

      const tempCategory = questionnaire.categories[categoryIndex];
      if (!tempCategory) {
        setCurrentCategory(undefined);
        setCurrentQuestions([]);
        return;
      }

      const chunkedQuestions = _chunk(tempCategory.questions, 5);
      const previousCategoryMax =
        categoryIndex > 0
          ? _chunk(questionnaire.categories[categoryIndex - 1]?.questions, 5).length
          : 0;

      if (!chunkedQuestions[page - previousCategoryMax - 1]?.length) {
        categoryIndex++;

        chunkedQuestion();
        return;
      }

      setCurrentCategory(tempCategory);
      setCurrentQuestions(chunkedQuestions[page - previousCategoryMax - 1]);
    }

    chunkedQuestion();
  };

  const nextPage = async () => {
    const nextP = page + 1;

    if (page * 5 > totalQuestions) {
      if (!questionnaire || !answers || !answers.length) return;

      submitQuestionnaireAnswer();
      history.push('/digital-profile-created' + (editMode ? '?edit=1' : ''));
      return;
    }

    history.push('/digital-profile/' + nextP);
  };

  const movePreviousPage = () => {
    history.push('/digital-profile/' + (page - 1));
  };

  return (
    <BusinessInfoFormSection
      name={currentCategory?.label}
      nProgress={((answers?.length ?? 0) / questionnaireCount) * 100}
      image={currentCategory?.icon}
      isComplete={!Object.keys(currentQuestionErrors).length}
      onClick={() => nextPage()}
      onBack={page > 1 ? movePreviousPage : undefined}
      onClose={() => {
        history.push('/dashboard');
      }}
    >
      <div className="flex flex-col gap-3">
        {currentQuestions &&
          currentQuestions.map((item: any) => {
            const errorMessage =
              currentQuestionErrors[item.id] !== 'required'
                ? currentQuestionErrors[item.id]
                : undefined;

            if (item.question_type === 'text') {
              return (
                <InputField
                  key={item.id + page}
                  label={item.question}
                  placeholder={item.question_hint}
                  isValid={watch(item.id)}
                  {...register(item.id, {
                    required: `${item.question} is required.`,
                  })}
                  value={mappedAnswers?.[item.id] ?? ''}
                  errorMessage={errorMessage}
                />
              );
            } else if (item.question_type === 'email') {
              return (
                <InputField
                  key={item.id + page}
                  isEmail={true}
                  label={item.question}
                  placeholder={item.question_hint}
                  isValid={watch(item.id)}
                  {...register(item.id, {
                    required: `${item.question} is required.`,
                  })}
                  value={mappedAnswers?.[item.id] ?? ''}
                  errorMessage={errorMessage}
                />
              );
            } else if (
              item.question_type === 'boolean' ||
              item.question_type === 'boolean_with_text'
            ) {
              return (
                <BooleanField
                  key={item.id + page}
                  label={item.question}
                  value={
                    item.question_type === 'boolean'
                      ? !!mappedAnswers?.[item.id]
                      : mappedAnswers?.[item.id] ?? false
                  }
                  onChange={(value) => {
                    setValue(item.id, value);
                  }}
                  withText={item.question_type === 'boolean_with_text'}
                />
              );
            } else if (item.question_type === 'select_many' && item.options) {
              return (
                <MultiSelect
                  key={item.id + page}
                  label={item.question}
                  options={item.options}
                  value={mappedAnswers?.[item.id] ?? ''}
                  onChange={(value) => {
                    setValue(item.id, value);
                  }}
                />
              );
            } else if (item.question_type === 'select_one') {
              return (
                <SelectField
                  key={item.id + page}
                  value={mappedAnswers?.[item.id] ?? ''}
                  label={item.question}
                  {...register(item.id, {
                    required: `${item.question} is required.`,
                  })}
                  placeholder={item.question_hint}
                  options={item.options.map((o: QuestionOption) => ({
                    code: o.key,
                    name: o.label,
                    icon: o.icon,
                  }))}
                  hasOptionsWithId={false}
                />
              );
            } else if (item.question_type === 'text_area') {
              return (
                <TextareaField
                  key={item.id + page}
                  label={item.question}
                  placeholder={item.question_hint}
                  isValid={watch(item.id) as string}
                  {...register(item.id, {
                    required: `${item.question} is required.`,
                  })}
                />
              );
            } else if (item.question_type === 'mobile_number') {
              return (
                <MobileField
                  key={item.id + page}
                  label={item.question}
                  placeholder={item.question_hint}
                  isValid={watch(item.id) as string}
                  value={mappedAnswers?.[item.id] ?? ''}
                  {...register(item.id, {
                    required: `${item.question} is required.`,
                  })}
                />
              );
            } else if (item.question_type === 'select_one_or_text') {
              return (
                <SelectOneOrTextField
                  key={item.id + page}
                  label={item.question}
                  placeholder={item.question_hint}
                  options={item.options}
                  value={mappedAnswers?.[item.id]}
                  onChange={(value: string | number | boolean) => {
                    setValue(item.id, value);
                  }}
                />
              );
            }
          })}
      </div>
    </BusinessInfoFormSection>
  );
};

export default DigitalProfile;
