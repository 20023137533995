import { useForm } from 'react-hook-form';
import PrimaryButton from '../../components/button/PrimaryButton';
import PasswordField from '../../components/form/PasswordField';
import SignupLayout from '../../layouts/SignupLayout';

const SMEHUB_URL =
  process.env.REACT_APP_SMEHUB_URL ?? window?._env_?.REACT_APP_SMEHUB_URL ?? '/sme-hub';

const SmeHubAdmin = () => {
  const {
    formState: { errors, isValid },
    register,
  } = useForm({
    mode: 'onChange',
    // reValidateMode: 'all',
  });

  return (
    <SignupLayout title="Log in as teacher" subTitle="Please enter the following to continue">
      <div>
        <form className="mt-4" method="POST" action={`${SMEHUB_URL}/login/index.php`}>
          <div className="border rounded-lg border-digitayoGray3 p-2 mb-5">
            <div
              className={`text-sm text-digitayoBlack1 p-1 pb-0 mb-0 ${
                errors?.username || 'text-green-600'
              }`}
            >
              Username
            </div>
            <div className="flex justify-between text-xl">
              <input
                id="email"
                type="text"
                className="flex flex-grow rounded py-2 text-digitayoBlack4 px-1 outline-0 bg-digitayoWhite"
                placeholder="SME Hub Teacher Username"
                {...register('username', {
                  required: 'Username is required',
                })}
              />
            </div>
          </div>

          <PasswordField
            id="password"
            label="Password"
            placeholder="Your Password"
            isValid={!errors?.password}
            {...register('password', {
              required: 'Password is required',
            })}
          />

          <div className="mt-8">
            <PrimaryButton
              isInvalid={!isValid}
              onClick={() => {
                console.log('Attempting login to SME Hub');
              }}
              type="submit"
            >
              Log in
            </PrimaryButton>
          </div>
        </form>
        <div className="flex flex-1 justify-start my-4">
          <a
            href={`${SMEHUB_URL}/login/forgot_password.php`}
            className="text-center text-lg text-digitayoOrange1"
          >
            Forgot your password?
          </a>
        </div>
      </div>
    </SignupLayout>
  );
};

export default SmeHubAdmin;
