import { Language } from '../../types/http';

type LanguageSelect = {
  languages: Language[];
  selectedValue: string;
  onSelect: (value: string) => void;
};

const LanguageSelect = ({ onSelect, selectedValue, languages }: LanguageSelect) => {
  const handleClick = (value: string) => {
    onSelect(value);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="text-xl font-bold text-digitayoBlack4 leading-6">Select Language:</div>
      <div className="rounded-lg p-[10px] bg-gradient-to-r from-digitayoCreated1 to-digitayoCreated2">
        <ul className="flex flex-row justify-between items-center gap-2 w-full">
          {languages.map((language) => (
            <li
              key={language.id}
              className={`p-2 rounded-lg ${
                selectedValue && selectedValue == language.code
                  ? 'font-bold bg-white text-digitayoOrange1 shadow-lg border-2 border-digitayoOrange1 shadow-[#9C27B01A]'
                  : ''
              } text-base text-digitayoGray5 text-center cursor-pointer w-full hover:bg-white`}
              onClick={() => handleClick(language.code)}
            >
              {language.label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default LanguageSelect;
