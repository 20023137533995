import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import PrimaryButton from '../../components/button/PrimaryButton';
import PasswordField from '../../components/form/PasswordField';
import ToastPopup from '../../components/Toastr';
import UnderlineLink from '../../components/UnderlineLink';
import { AuthUserContext } from '../../context/AuthUserContextProvider';
import SignupLayout from '../../layouts/SignupLayout';
import { login } from '../../services/https/api';

const Login = () => {
  const [isEmailValid, setEmailValid] = useState<string | boolean>();
  const [isPasswordValid, setPasswordValid] = useState<string | boolean>();
  const [loginError, setLoginError] = useState<string>('');
  const { setValue, watch } = useForm();
  const history = useHistory();
  // const [googleUrl, setGoogleUrl] = useState<string>('');
  const { profile, refreshProfile } = useContext(AuthUserContext);

  const validateEmail = (email: string) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (pattern.test(email)) {
      return true;
    } else {
      return 'Invalid email format.';
    }
  };

  const redirectToAuthPage = () => {
    // get the value of the 'redirect' query parameter

    const urlParams = new URLSearchParams(window.location.search);

    const redirectUrl = urlParams.get('redirect') ?? '/dashboard';

    if (profile!.status === 'BUSINESS_INFO_PROVIDED') {
      // redirect to the specified URL if the user has provided business info
      history.push(redirectUrl!);
    } else {
      history.push('/requirements');
    }
  };

  useEffect(() => {
    if (profile && window.sessionStorage.getItem('access_token')) {
      redirectToAuthPage();
    }
  }, [profile]);

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;
    const emailError = validateEmail(email);
    setValue('email', email);
    setEmailValid(emailError);
  };

  const validatePassword = (password: string) => {
    if (password.length < 8) {
      return 'Password must be at least 8 characters long.';
    } else {
      return true;
    }
  };

  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    const passwordError = validatePassword(password);
    setValue('password', password);
    setPasswordValid(passwordError);
  };

  const handleErrorCode = (errorCode: string) => {
    if (errorCode === 'WRONG_CREDENTIALS') {
      setLoginError('Invalid email or password.');
    } else if (errorCode === 'INVALID_PASSWORD') {
      setLoginError('Your current password is incorrect.');
      console.log(errorCode);
    } else if (errorCode) {
      setLoginError('There was an error logging in. Please try again later.');
    }
  };

  const loginUser = async () => {
    const email = watch('email');
    const password = watch('password');

    try {
      const { data } = await login({
        email: email,
        password: password,
      });
      window.sessionStorage.setItem('access_token', data.access_token);
      window.sessionStorage.setItem('refresh_token', data.refresh_token);
      refreshProfile();
    } catch (error: unknown) {
      const err = error as { response: { data: { error_code: string } } };
      // TODO: handle error
      handleErrorCode(err?.response?.data?.error_code);
    }
  };

  // useEffect(() => {
  //   getGoogleAuthUrl().then(({ data }: { data: { url: string } }) => {
  //     setGoogleUrl(data.url);
  //   });
  // }, []);

  return (
    <SignupLayout title="Login" subTitle="Please sign in to continue">
      <div>
        <div className="mt-7 mb-9">
          {!!loginError && (
            <ToastPopup error={true} message={loginError} onClose={() => setLoginError('')} />
          )}
          <form>
            <div className="border rounded-lg border-digitayoGray3 p-2 mb-5">
              <div
                className={`text-sm text-digitayoBlack1 p-1 pb-0 mb-0 ${
                  isEmailValid !== true || 'text-green-600'
                }`}
              >
                Email Address
              </div>
              <div className="flex justify-between text-xl">
                <input
                  id="email"
                  type="text"
                  className="flex flex-grow rounded py-2 text-digitayoBlack4 px-1 outline-0 bg-digitayoWhite"
                  placeholder="e.g. jdelacruz@gmail.com"
                  onChange={handleEmail}
                />
              </div>
            </div>

            <PasswordField
              id="password"
              label="Password"
              placeholder="Your Password"
              isValid={isPasswordValid !== true}
              onChange={handlePassword}
            />
          </form>
        </div>
        <PrimaryButton
          isInvalid={!(isEmailValid === true && isPasswordValid === true)}
          onClick={loginUser}
        >
          Login
        </PrimaryButton>

        <div className="my-3 flex justify-center items-center text-gray-500">
          <div className="w-2/3">
            <div className="border-t border-spacing-2 border-digitayoBlack3 opacity-50"></div>
          </div>
          <div className="justify-center flex mx-4 font-thin text-sm my-1">or</div>
          <div className="w-2/3">
            <div className="border-t border-spacing-2 border-digitayoBlack3 opacity-50"></div>
          </div>
        </div>

        {/* <SecondaryButton isValid={false} isOrange={true}>
          <a className="flex flex-row justify-between w-full px-4" href={googleUrl}>
            <img src={GoogleIcon} className="w-5" alt="Google Icon" />
            <div>Login with Google</div>
            <div></div>
          </a>
        </SecondaryButton> */}
        <div className="text-md flex justify-between items-center mt-4 mb-6 font-semibold">
          <UnderlineLink onClick={() => history.push('/forgot-password')}>
            Forgot Password?
          </UnderlineLink>
          <UnderlineLink onClick={() => history.push('/signup')}>Sign Up</UnderlineLink>
        </div>
      </div>
    </SignupLayout>
  );
};

export default Login;
