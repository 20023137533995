import { InputHTMLAttributes, forwardRef } from 'react';
import PH from '../../assets/dashboard/ph.svg';

type InputFieldProps = {
  label?: string;
  className?: string;
  placeholder?: string;
  isValid?: string;
} & InputHTMLAttributes<HTMLInputElement>;

const MobileField = forwardRef<HTMLInputElement, InputFieldProps>((props, ref) => {
  const { label, isValid, ...otherProps } = props;

  return (
    <div className="border rounded-lg border-digitayoGray3 mt-2 flex">
      <div className="w-14 flex flex-col justify-center items-center text-digitayoBlack4">
        <div>
          <img src={PH} alt="PH" />
        </div>
        <div className="text-sm">+63</div>
      </div>
      <div className="border-l pl-2 p-2">
        <div
          className={`text-xs text-digitayoBlack1 p-1 pb-0 mb-0 ${isValid ? 'text-green-600' : ''}`}
        >
          {label}
        </div>
        <div className="flex justify-between text-sm">
          <input
            ref={ref}
            type="number"
            className="flex flex-grow rounded py-2 text-digitayoBlack4 px-1 outline-0 bg-digitayoWhite"
            {...otherProps}
          />
        </div>
      </div>
    </div>
  );
});

export default MobileField;
