import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { BusinessInfoContext } from '../../context/BusinessInfoContext';
import SignupLayout from '../../layouts/SignupLayout';
import { useForm } from 'react-hook-form';
import PrimaryButton from '../../components/button/PrimaryButton';
import UnderlineLink from '../../components/UnderlineLink';
import GoogleIcon from '../../assets/signup/google.svg';
import SecondaryButton from '../../components/button/SecondaryButton';
import { getAccounts, getGoogleAuthUrl } from '../../services/https/api';
import InputField from '../../components/form/InputField';
import ToastPopup from '../../components/Toastr';

const SignupPage = () => {
  const history = useHistory();
  const info = useContext(BusinessInfoContext);
  const [googleUrl, setGoogleUrl] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const {
    register,
    watch,
    getValues,
    formState: { isValid },
  } = useForm<{ email: string }>();

  const toSetPassword = async () => {
    console.log('inside');
    try {
      const { data } = await getAccounts();

      const val = data.smes.filter((data: any) => data.email === getValues('email'));
      if (val.length > 0) {
        setErrorMessage('User already exist. Please login or try another email.');
      } else {
        history.push('/set-password');
      }
    } catch (error: any) {
      setErrorMessage('An error has occured during signup. Please try again later.');
    }
  };

  useEffect(() => {
    const subscription = watch((value) => {
      if (!!value.email) {
        info.setValue({
          ...Object(info.value),
          email: value.email,
        });
      }
    });

    getGoogleAuthUrl().then(({ data }: { data: { url: string } }) => {
      setGoogleUrl(data.url);
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <SignupLayout title="Create an account" subTitle="Enter your details below">
      <div>
        {!!errorMessage && (
          <ToastPopup error={true} message={errorMessage} onClose={() => setErrorMessage('')} />
        )}
        <div className="mt-7 mb-9">
          <form>
            <InputField
              isEmail={true}
              label="Email Address"
              placeholder="e.g. jdelacruz@yahoo.com"
              isValid={getValues('email')}
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'Invalid email format',
                },
              })}
            />
          </form>
        </div>
        <PrimaryButton isInvalid={!isValid} onClick={toSetPassword}>
          Sign Up with Email
        </PrimaryButton>

        <div className="my-3 flex justify-center items-center text-gray-500">
          <div className="w-2/3">
            <div className="border-t border-spacing-2 border-digitayoBlack3 opacity-50"></div>
          </div>
          <div className="justify-center flex mx-4 font-thin text-sm my-1">or</div>
          <div className="w-2/3">
            <div className="border-t border-spacing-2 border-digitayoBlack3 opacity-50"></div>
          </div>
        </div>

        {/* <SecondaryButton isValid={false} isOrange={true}>
          <a className="flex flex-row justify-between w-full px-4" href={googleUrl}>
            <img src={GoogleIcon} className="w-6" alt="Google Icon" />
            <div>Sign Up with Google</div>
            <div></div>
          </a>
        </SecondaryButton> */}
        <div className="text-md text-center mt-4 mb-6 font-semibold">
          <UnderlineLink onClick={() => history.push('/login')}>
            Already have an account?
          </UnderlineLink>
        </div>
      </div>
    </SignupLayout>
  );
};

export default SignupPage;
