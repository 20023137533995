import { useEffect } from 'react';
import DigiTayoLogo from '../assets/logo/digitayo-logo.svg';
import { motion, AnimatePresence } from 'framer-motion';
import { useHistory } from 'react-router';

const Splash = () => {
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history.push('/onboarding');
    }, 3500);
  });

  return (
    <AnimatePresence>
      <div className="bg-digitayoWhite min-h-screen h-100 flex flex-col justify-center items-center">
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1, delay: 1 }}
          exit={{ opacity: 0 }}
          className="flex justify-center"
        >
          <img className="w-5/6 pb-20" src={DigiTayoLogo} alt="Digitayo Logo" />
        </motion.div>
      </div>
    </AnimatePresence>
  );
};

export default Splash;
