import { useContext, useEffect, useRef } from 'react';
import S1 from '../../assets/settings/settings1.svg';
import S2 from '../../assets/settings/settings2.svg';
import S3 from '../../assets/settings/settings3.svg';
import S4 from '../../assets/settings/settings4.svg';
import { useHistory } from 'react-router';
import { AuthUserContext } from '../../context/AuthUserContextProvider';
// import SocialLinks from '../social-links';

type SettingsType = {
  isOpen: boolean;
  onClose: () => void;
};

type ListItemType = {
  icon: string;
  label: string;
  classes?: string;
  onClick: () => void;
};

const ListItem = ({ icon, label, classes, onClick }: ListItemType) => {
  return (
    <div
      className={`flex flex-row items-center gap-6 text-digitayoBlack4 text-lg py-[18px] mx-4 cursor-pointer ${classes}`}
      onClick={onClick}
    >
      <div>
        <img src={icon} alt="icon" width="24" />
      </div>
      <div>{label}</div>
    </div>
  );
};

const SettingsSidebar = ({ isOpen, onClose }: SettingsType) => {
  const history = useHistory();
  const modalRef = useRef<HTMLDivElement>(null);
  const { profile, refreshProfile } = useContext(AuthUserContext);

  const handleClickOutside = (event: React.MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onClose();
    }
  };

  const handleLogout = () => {
    window.localStorage.clear();
    window.sessionStorage.clear();
    refreshProfile();
    history.push('/login');
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }
  }, []);

  const handleEditDigitalProfile = () => {
    history.push('/digital-profile/1');
  };

  return (
    <div
      className="fixed max-w-md mx-auto inset-0 z-50 w-full flex items-center justify-end bg-black bg-opacity-50"
      onClick={handleClickOutside}
    >
      <div
        ref={modalRef}
        className="bg-digitayoWhite w-4/6 h-full shadow-lg ease-in-out transform transition-transform duration-100 min-h-screen"
      >
        <div className="bg-gradient-to-r from-digitayoCreated1 to-digitayoCreated2 h-28 flex flex-col justify-end ">
          <div className="mr-auto m-4 text-digitayoBlack4 text-xl font-bold">Settings</div>
        </div>
        {/* <div className="flex flex-col justify-between h-[calc(100%-154px)]"> */}
        <div className="flex flex-col justify-start h-[calc(100%-154px)]">
          <>
            <ListItem
              icon={S1}
              label="Edit Digital Profile"
              classes="border-b border-gray-200"
              onClick={handleEditDigitalProfile}
            />
            <ListItem
              icon={S2}
              label="View Help Center"
              classes="border-b border-gray-200"
              onClick={() => history.push('/helpdesk')}
            />
            {!profile?.isLoginWithGoogle && (
              <ListItem
                icon={S3}
                label="Change Password"
                classes="border-b border-gray-200"
                onClick={() => history.push('/change-password')}
              />
            )}
            <ListItem icon={S4} label="Logout" onClick={handleLogout} />
          </>
          {/* <SocialLinks sidebar /> */}
        </div>
      </div>
    </div>
  );
};

export default SettingsSidebar;
