import { useRef } from 'react';
import CapsuleButton from '../button/CapsuleButton';
import FilterButton from '../button/FilterButton';
import PrimaryButton from '../button/PrimaryButton';
import SecondaryButton from '../button/SecondaryButton';

export type Filters = {
  order: 'asc' | 'desc';
  tags: string;
};

type FilterType = {
  isOpen: boolean;
  onClose: () => void;
  onApplyFilter: (filters: Filters) => void;
  onResetFilter: () => void;
  tags: string[];
  setTags: any;
  order: 'asc' | 'desc';
  setOrder: any;
  tagOptions?: string[];
  viewAll?: boolean;
  showViewAll?: boolean;
  setShowViewAll?: () => void;
  resources?: boolean;
};

const FilterSidebar = ({
  isOpen,
  onClose,
  onApplyFilter,
  onResetFilter,
  tags,
  setTags,
  order,
  setOrder,
  tagOptions,
  viewAll,
  showViewAll,
  setShowViewAll,
  resources,
}: FilterType) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const defaultTagOptions = [
    'Accounting',
    'Business Management',
    'Branding',
    'Customer Service',
    'Google',
    'Logistics',
    'Marketing',
    'Pricing',
    'Shopify',
    'Social Media',
    'Tax',
    'Valuation',
    'Website',
  ];

  const handleClickOutside = (event: React.MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onClose();
    }
  };

  const handleTagsSelection = (label: string) => {
    setTags((prev: string[]) => {
      if (prev.includes(label)) {
        return prev.filter((item) => item !== label);
      } else {
        return [...prev, label];
      }
    });
  };

  const handleFilters = () => {
    onApplyFilter({
      order,
      tags: tags.join(','),
    });
  };

  const handleReset = () => {
    setOrder('asc');
    setTags([]);
    setTimeout(() => {
      onResetFilter();
    }, 500);
  };

  // useEffect(() => {
  //   const myOrder = window.sessionStorage.getItem('order');
  //   const myTags = window.sessionStorage.getItem('tags');
  //   myOrder && setOrder(myOrder);
  //   myTags && setTags(JSON.parse(myTags));
  // }, [window.sessionStorage.getItem('order'), window.sessionStorage.getItem('tags')]);

  if (!isOpen) {
    return null;
  }

  let mapTagsOptions = defaultTagOptions;
  if (viewAll && tagOptions && tagOptions.length) {
    mapTagsOptions = tagOptions;
  }

  return (
    <div
      className="fixed max-w-md mx-auto inset-0 z-50 w-full flex items-center justify-end bg-black bg-opacity-50"
      onClick={handleClickOutside}
    >
      <div
        ref={modalRef}
        className="bg-digitayoWhite w-5/6 h-full shadow-lg ease-in-out transform transition-transform duration-100 min-h-screen flex flex-col justify-between"
      >
        <div>
          <div className="h-28 bg-gradient-to-r from-digitayoCreated1 to-digitayoCreated2 flex flex-col justify-end">
            <div className="mr-auto m-4 text-digitayoBlack4 font-bold text-xl">
              {resources ? 'Resources Filter' : 'Search Filter'}
            </div>
          </div>
          {viewAll ? (
            <div className="text-left m-4 pt-2">
              <div className="flex gap-2 text-xs text-digitayoGray3">
                <FilterButton
                  label="View All"
                  selected={showViewAll === true}
                  onClick={setShowViewAll}
                />
              </div>
            </div>
          ) : (
            <div className="text-left m-4 pt-2">
              <div className="text-digitayoBlack4 my-2 text-lg">Order</div>
              <div className="flex gap-2 text-xs text-digitayoGray3">
                <FilterButton
                  label="Sort from A-Z"
                  selected={order === 'asc'}
                  onClick={() => {
                    setOrder('asc');
                  }}
                />
                <FilterButton
                  label="Sort from Z-A"
                  selected={order === 'desc'}
                  onClick={() => {
                    setOrder('desc');
                  }}
                />
              </div>
            </div>
          )}
          <div className="text-left m-4 pt-4">
            <div className="text-digitayoBlack4 my-2 text-lg">
              {resources ? 'Filter by' : 'Tag/s'}
            </div>
            <div className="flex flex-row gap-2 flex-wrap">
              {mapTagsOptions.map((item, index) => {
                return (
                  <CapsuleButton
                    key={index}
                    label={item}
                    tags={tags}
                    onClick={() => handleTagsSelection(item)}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-4 mx-4 mb-10">
          <PrimaryButton onClick={handleFilters} isInvalid={false}>
            Apply
          </PrimaryButton>
          <SecondaryButton onClick={handleReset} isValid={false} isOrange={true}>
            <div></div>
            Reset
            <div></div>
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
};

export default FilterSidebar;
