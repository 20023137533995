import { ReactElement } from 'react';
import PrimaryButton from '../../components/button/PrimaryButton';
import MultiToggleLanguageButton from '../../components/button/MultiToggleLanguageButton';
import ProgressBar from '../../components/ProgressBar';
import BackIcon from '../../assets/left-arrow.svg';
import CloseIcon from '../../assets/close-icon.svg';

type SurveyType = {
  name: string;
  icon: string;
  progress?: string;
  nProgress?: number;
  heading: string;
  children: ReactElement;
  onClick: () => void;
  onBack?: () => void;
  onClose: () => void;
  isComplete: boolean;
  showBackButton: boolean;
  multiToggle: ReactElement;
};

const SurveyModal = ({
  name,
  icon,
  progress,
  heading,
  children,
  isComplete,
  onClick,
  onBack,
  onClose,
  nProgress,
  showBackButton,
  multiToggle,
}: SurveyType) => {
  return (
    <div className="bg-digitayoWhite min-h-screen h-100 flex flex-col justify-between">
      <div>
        <div className="flex justify-between items-end mx-4">
          {showBackButton ? (
            <div
              className="mb-3 cursor-pointer flex justify-center items-center pb-0.5"
              onClick={onBack}
            >
              <img src={BackIcon} alt="back-icon" width="20" />
            </div>
          ) : (
            <div></div>
          )}
          <div className="text-digitayoBlack4 text-lg flex justify-center items-end h-24 pb-2 font-inter font-semibold">
            {name}
          </div>
          <div
            className="mb-3 cursor-pointer flex justify-center items-center pb-0.5"
            onClick={onClose}
          >
            <img src={CloseIcon} alt="back-icon" width="20" />
          </div>
        </div>
        <ProgressBar progress={progress} nProgress={nProgress} />
        <div className="flex flex-col justify-start items-center font-inter text-gray-500 px-6 h-100">
          <div className="mt-8">{multiToggle}</div>
          <div className="mt-8 w-full">
            <div className="text-digitayoBlack4 text-md pb-4">
              <img src={icon} alt="icon" className="w-2/5 mx-auto mb-8" />
              <div className="text-xl font-bold">{heading}</div>
            </div>
            {children}
          </div>
        </div>
      </div>
      <div className="mx-auto w-80 md:w-96 mb-10 mt-6">
        <PrimaryButton isInvalid={!isComplete} onClick={onClick}>
          Continue
        </PrimaryButton>
      </div>
    </div>
  );
};

export default SurveyModal;
