import { createContext } from 'react';
import { infoFormType } from '../types/infoForm';

export const BusinessInfoContext = createContext({
  value: {} as infoFormType | null | undefined,
  setValue: (info: infoFormType) => {
    id: info.id;
    email: info.email;
    business_name: info.business_name;
    region: info.region;
    city: info.city;
    barangay: info.barangay;
    first_name: info.first_name;
    last_name: info.last_name;
    designation: info.designation;
    work_email: info.work_email;
    payment_methods: {
      cash: info.payment_methods.cash;
      maya: info.payment_methods.maya;
      gcash: info.payment_methods.gcash;
      others: info.payment_methods.others;
    }
    logistic_platforms: {
      grab: info.logistic_platforms.grab;
      lalamove: info.logistic_platforms.lalamove;
      gogo: info.logistic_platforms.gogo;
      others: info.logistic_platforms.others;
    }
    ecommerce_platforms: {
      shopify: info.ecommerce_platforms.shopify;
      facebook: info.ecommerce_platforms.facebook;
      lazada: info.ecommerce_platforms.lazada;
      others: info.ecommerce_platforms.others;
    }
    security_platforms: {
      one_password: info.security_platforms.one_password;
      bitwarden: info.security_platforms.bitwarden;
      norton: info.security_platforms.norton;
      others: info.security_platforms.others;
    }
    trainings_and_certifications: info.trainings_and_certifications;
    facebook_url: info.facebook_url;
    instagram_url: info.instagram_url;
  },
});
