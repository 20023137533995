import React from 'react';
import FooterNavigation from '../components/FooterNavigation';
import Shop from '../assets/store.png';
import Location from '../assets/dashboard/home/location.svg';
import ShopIcon from '../assets/dashboard/home/shop.png';
import LocationIcon from '../assets/dashboard/home/location.svg';
import BackIcon from '../assets/left-arrow-white.svg';
import { useHistory } from 'react-router';

type DashboardType = {
  isProfile?: boolean;
  isHome?: boolean;
  label?: string;
  businessName?: string;
  city?: string;
  region?: string;
  children: React.ReactNode;
  isDashboard?: boolean;
  showBackButton?: boolean;
};

const DashboardLayout = ({
  isProfile,
  isHome,
  label,
  businessName,
  city,
  region,
  children,
  isDashboard,
  showBackButton,
}: DashboardType) => {
  const history = useHistory();

  return (
    <div className="min-h-screen flex flex-col">
      {isProfile ? (
        <div className="h-60 flex flex-col justify-center items-start bg-gradient-to-br from-digitayoOrange1 via-digitayoPurple1 to-digitayoBlue1">
          <div className="h-40 flex justify-center py-6 items-end font-inter text-lg font-bold d-flex justify-content-around mx-10">
            <div className="flex flex-row">
              <img src={Shop} alt="logo" width="60" className="mx-4" />
              <div className="text-white">
                <div className="text-2xl font-bold">{businessName}</div>
                <div className="muted text-sm font-thin flex flex-row text-digitayoCreated1">
                  <img src={Location} alt="location-icon" width="19" className="mr-2" />
                  <div>{city + ', ' + region}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : isHome ? (
        <div className="h-72 flex flex-col justify-center items-start bg-gradient-to-br from-digitayoOrange1 via-digitayoPurple1 to-digitayoBlue1">
          <div className="mx-auto text-white text-lg w-11/12 pb-16">
            <div className="flex space-x-5 items-center">
              <img src={ShopIcon} className="w-20" alt="Shop Icon" />
              <div>
                <h1 className="font-bold text-[22px]">{businessName}</h1>
                <div className="flex space-x-2 items-start mt-1">
                  <img src={LocationIcon} className="w-5" alt="Location Icon" />
                  <div className="text-[16px] leading-6">{city + ', ' + region}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="h-56 flex flex-col justify-center items-start bg-gradient-to-br from-digitayoOrange1 via-digitayoPurple1 to-digitayoBlue1 relative">
          {showBackButton && (
            <div
              className="ml-2 cursor-pointer absolute top-4 p-2"
              onClick={() => history.goBack()}
            >
              <img src={BackIcon} alt="back-icon" width="32" />
            </div>
          )}
          <div className="mx-auto text-white text-[22px] font-bold">
            <div className="">{label}</div>
          </div>
        </div>
      )}
      <div
        className={`flex-1 overflow-y-visible rounded-t-3xl relative -mt-16 flex flex-col pb-20 ${
          isDashboard ? 'bg-gradient-to-r from-digitayoCreated1 to-digitayoCreated2' : 'bg-zinc-50'
        }`}
      >
        {children}
      </div>
      <div className="fixed bottom-0 max-w-md w-full">
        <FooterNavigation></FooterNavigation>
      </div>
    </div>
  );
};

export default DashboardLayout;
