import { RouteChildrenProps, RouteComponentProps, useHistory } from 'react-router';
import PrimaryButton from '../../components/button/PrimaryButton';
import Created from '../../assets/password/account-confetti.svg';
import qs from 'qs';

const DigitalProfileCreated = (props: RouteComponentProps) => {
  const history = useHistory();
  let search = props.location.search;
  if (search.startsWith('?')) {
    search = search.substring(1);
  }
  const query = qs.parse(search) as { edit?: string };

  const editMode = !!query?.edit;

  return (
    <div className="min-h-screen text-gray-500 bg-gradient-to-r from-digitayoCreated1 to-digitayoCreated2">
      <div className="bg-gradient-to-b from-transparent to-white px-10 min-h-screen h-full flex flex-col justify-evenly items-center">
        <div className="py-4">
          <img className="img-fluid" src={Created} width="400" alt="created-image" />
        </div>
        <div className="font-inter text-center">
          <div className="bg-gradient-to-r from-digitayoOrange1 to-digitayoPurple1 bg-clip-text text-transparent font-bold text-[34px] leading-10">
            {editMode
              ? 'Your digital profile has been updated!'
              : 'Thank you for completing your digital profile!'}
          </div>

          <div className="text-sm py-4 text-digitayoBlack4">
            <div className="py-4 text-lg">
              Your digital profile helps us give you personal recommendations on how to make your
              business more digitally-equipped
            </div>
          </div>
        </div>
        <div className="pt-3 pb-8 w-full">
          <PrimaryButton
            isInvalid={false}
            onClick={() => {
              history.push('/dashboard');
            }}
          >
            Go to Dashboard
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default DigitalProfileCreated;
