import { CSSProperties } from 'react';

type ProgressType = {
  progress?: string;
  nProgress?: number;
  rounded?: boolean;
};

const ProgressBar = ({ progress, nProgress, rounded }: ProgressType) => {
  const style: CSSProperties = {};

  if (nProgress !== null || nProgress !== undefined) {
    style.width = nProgress + '%';
  }

  return (
    <div className="bg-gradient-to-br from-digitayoCreated1 to-digitayoCreated2 w-100">
      <div
        className={`h-2 bg-gradient-to-br from-digitayoOrange2 to-digitayoPurple2 ${progress} ${
          rounded && 'rounded-lg'
        }`}
        style={style}
      ></div>
    </div>
  );
};

export default ProgressBar;
