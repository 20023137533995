import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import OtpInput from 'react18-input-otp';
import Otp from '../../assets/signup/otp.svg';
import PrimaryButton from '../../components/button/PrimaryButton';
import SecondaryButton from '../../components/button/SecondaryButton';
import ToastPopup from '../../components/Toastr';
import { BusinessInfoContext } from '../../context/BusinessInfoContext';
import { requestOtp as resendSmesOtp, verifyEmailAccount } from '../../services/https/api';

const ConfirmEmail = () => {
  const COUNTDOWN_TIME = 30;
  const [submitOtpBtn, setSubmitOtpBtn] = useState<boolean>(true);
  const [email, setEmail] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string | null>('');
  const [errorMessage, setErrorMessage] = useState<string | null>('');
  const [countdownTimer, setCountdownTimer] = useState<number>(COUNTDOWN_TIME);

  const [resendOtp, setResendOtp] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>('');
  const history = useHistory();
  const info = useContext(BusinessInfoContext);

  useEffect(() => {
    // Run resendOtpTrigger only on mount
    if (countdownTimer === COUNTDOWN_TIME) {
      resendOtpTrigger();
    }

    // Check sessionStorage (on reload page)
    const data = JSON.parse(window.sessionStorage.getItem('profile') as string);
    setEmail(data?.email);

    setTimeout(() => {
      if (countdownTimer <= 0) {
        setSuccessMessage(null);
        return;
      }
      setCountdownTimer(countdownTimer - 1);
    }, 1000);
  }, [countdownTimer]);

  const handleOTP = (value: React.ChangeEvent<string>[]) => {
    setOtp(value.toString());
    if (value.length === 6) {
      setSubmitOtpBtn(false);
    } else {
      setSubmitOtpBtn(true);
    }
  };

  const handleErrorCode = (errorCode: string) => {
    if (errorCode === 'INVALID_OTP') {
      setErrorMessage('Invalid OTP. Please check your email.');
      console.log(errorCode);
    } else if (errorCode === 'BAD_REQUEST') {
      setErrorMessage('Validation error. Please check the required fields');
      console.log(errorCode);
    } else if (errorCode) {
      setErrorMessage('There was an error submitting the OTP.');
    }
  };

  const resendOtpTrigger = async () => {
    setCountdownTimer(COUNTDOWN_TIME);
    const authId = window.sessionStorage.getItem('auth_id');

    if (!authId) {
      history.push('/signup');
      return;
    }

    try {
      const { data } = await resendSmesOtp({
        id: authId,
      });

      window.sessionStorage.setItem('otp_token', data.otp_token);
      setResendOtp(false);
      setSuccessMessage('OTP has been successfully sent to your email.');
      setErrorMessage(null);
    } catch (error) {
      setSuccessMessage(null);
      setErrorMessage(
        'There was an error submitting the OTP. Please check your email or resend OTP again.'
      );
    }
  };

  const submitOtp = async () => {
    const otpToken = window.sessionStorage.getItem('otp_token');
    const authId = window.sessionStorage.getItem('auth_id');

    if (!authId) {
      history.push('/signup');
      return;
    }

    try {
      await verifyEmailAccount({
        otp_token: otpToken as string,
        otp_code: otp,
        id: authId as string,
      });

      history.push('/requirements');
    } catch (error: any) {
      setSuccessMessage(null);
      console.log(error.response.data.error_code);
      handleErrorCode(error.response.data.error_code);
    }
  };

  return (
    // TODO: Would be nice to create a shared component for the registration pages (title, imageSrc, children, buttons)
    <div className="bg-digitayoWhite min-h-screen h-100 p-7 font-archivo">
      <div className="my-6 bg-gradient-to-r from-digitayoOrange1 to-digitayoPurple1 bg-clip-text text-transparent font-bold text-[34px] leading-10 pr-10">
        Let's confirm your email address
      </div>
      {!!errorMessage && (
        <ToastPopup error={true} message={errorMessage} onClose={() => setErrorMessage('')} />
      )}
      {!!successMessage && (
        <ToastPopup success={true} message={successMessage} onClose={() => setSuccessMessage('')} />
      )}

      <img src={Otp} alt="Otp Image" className="w-2/3 mx-auto" />

      <div className="text-xl text-digitayoBlack4 my-8">
        <div>An OTP has been sent to</div>
        <div className="font-bold">{email || info?.value?.email}</div>
      </div>

      <div className="flex justify-around">
        <OtpInput
          className="otp bg-digitayoWhite border border-digitayoBlack3 px-2 py-3 mx-1.5 rounded-lg text-[34px] text-black"
          value={otp}
          onChange={handleOTP}
          numInputs={6}
          isDisabled={countdownTimer <= 0}
          separator={<span> </span>}
        />
      </div>
      {resendOtp && (
        <div className="my-2 mx-5 px-4 text-sm">
          If you are unable to receive the OTP, please try again after a few minutes or contact{' '}
          <u>support@digitayo.ph</u>
        </div>
      )}

      <div className="flex flex-col mt-16 mb-6">
        <SecondaryButton onClick={resendOtpTrigger} isValid={countdownTimer > 0} isOrange={true}>
          <div></div>
          Resend OTP {`${countdownTimer > 0 ? '(' + countdownTimer + 's)' : ''}`}
          <div></div>
        </SecondaryButton>
        <div className="my-3">
          <PrimaryButton isInvalid={submitOtpBtn} onClick={submitOtp}>
            Submit OTP
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmail;
