import PrimaryButton from '../button/PrimaryButton';
import SecondaryButton from '../button/SecondaryButton';

import contactIcon from '../../assets/contact-icon.svg';
import emailIcon from '../../assets/email-icon.svg';
import siteIcon from '../../assets/site-icon.svg';
// import { ContactNumber } from '../../pages/Dashboard/Resources/DashboardResources';

type Promo = {
  title?: string;
  description?: string;
};

type ModalData = {
  title?: string;
  icon?: string;
  description?: string;
  highlight?: string;
  url?: string;
  email?: string;
  contacts?: string[];
  promo?: Promo;
  // contacts?: ContactNumber[];
};

type MyProps = {
  modalData: ModalData;
  hasPasswordDescription?: boolean;
  hasMultipleEmails?: boolean;
  hasDigitalProfile?: boolean;
  hasDashboard?: boolean;
  isResources?: boolean;
  onClose: () => void;
};

const Modal = ({
  modalData,
  hasPasswordDescription,
  hasMultipleEmails,
  hasDigitalProfile,
  hasDashboard,
  isResources,
  onClose,
}: MyProps) => {
  return (
    <div className="fixed bg-black inset-0 max-w-md mx-auto bg-opacity-30 flex justify-center items-start p-6 z-10 overflow-auto">
      <div className="bg-neutral-100 py-10 px-7 m-auto flex justify-center items-center rounded-[10px] max-w-[375px] w-full">
        <div className="flex flex-col font-inter text-digitayoBlack4 text-left w-full">
          {isResources ? (
            <div className="w-full h-[150px] flex justify-center items-center -mt-5 mb-5">
              <img
                className="w-auto h-auto max-w-[290px] max-h-[150px] object-contain object-center"
                src={modalData.icon}
                alt="card-image"
              />
            </div>
          ) : (
            <img src={modalData.icon} alt="icon" width="126" className="mx-auto mb-6" />
          )}
          {modalData.title && (
            <div className="flex flex-row items-center gap-6">
              <div className="text-lg font-bold">{modalData.title}</div>
            </div>
          )}
          {hasPasswordDescription && (
            <div className="text-lg my-4 text-digitayoBlack2">
              <ul className="list-disc">
                <div>Password must:</div>
                <div className="ml-4">
                  <li>be at least 8 characters long</li>
                  <li>contain at least one uppercase letter</li>
                  <li>contain at least one lowercase letter</li>
                  <li>contain at least one numeric digit</li>
                  <li>contain at least one special character (!@#$%^&*)</li>
                </div>
              </ul>
            </div>
          )}
          {hasMultipleEmails && (
            <div className="text-lg my-4 text-digitayoBlack2">
              You should use the email address most frequently used to handle your business
              transactions. The email address should also be one that is regularly checked for new
              messages.
            </div>
          )}
          {hasDigitalProfile && (
            <div className="text-lg my-4 text-digitayoBlack2">
              Your digital profile contains essential information about you and your business, as
              well as your learning progress. Please review and ensure its accuracy to get the best
              recommended courses.
            </div>
          )}
          {hasDashboard && (
            <div className="text-lg my-4 text-digitayoBlack2">
              The Digital Readiness Check (DRC) is an assessment test that helps determine the
              digital maturity of your business. Based on your results, you get customized
              upskilling courses that address your unique needs, enabling you to harness the full
              potential of digital technologies and stay competitive.
            </div>
          )}
          {modalData.description && (
            <div className="text-base my-4 text-digitayoBlack2">{modalData.description}</div>
          )}
          {isResources && (
            <div className="flex flex-col gap-3 mt-4 pl-3 text-base text-digitayoBlack2">
              {modalData.url && (
                <div className="flex items-center gap-5">
                  <img src={siteIcon} alt="icon" width="20" />
                  <a
                    href={
                      modalData?.url.indexOf('http') > -1
                        ? modalData?.url
                        : `https://${modalData?.url}`
                    }
                    target="_blank"
                  >
                    {modalData.url}
                  </a>
                </div>
              )}
              {modalData.email && (
                <div className="flex items-center gap-5">
                  <img src={emailIcon} alt="icon" width="20" />
                  <a href={`mailto:${modalData.email}`} target="_blank">
                    {modalData.email}
                  </a>
                </div>
              )}
              {modalData.contacts && (
                <div className="flex items-start gap-5">
                  <img src={contactIcon} alt="icon" width="20" />
                  <div className="flex flex-col gap-2 items-start">
                    {modalData.contacts.map((contact) => (
                      <a href={`tel:${contact}`} target="_blank">
                        {contact}
                      </a>
                    ))}
                  </div>
                </div>
              )}
              {modalData.promo && (modalData.promo.title || modalData.promo.description) && (
                <div className="bg-digitayoCreated1 rounded-[10px] p-6 mt-8">
                  <span className="text-digitayoGreen text-sm font-bold">
                    {modalData.promo.title}
                  </span>
                  <p className="text-digitayoBlack2 text-sm mt-3">{modalData.promo.description}</p>
                </div>
              )}
            </div>
          )}
          {modalData.highlight && (
            <div className="bg-digitayoCreated1 rounded-[10px] p-6 mt-8">
              <span className="text-digitayoGreen text-sm font-bold">{modalData.highlight}</span>
              <p className="text-digitayoBlack2 text-sm mt-3">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. 
              </p>
            </div>
          )}
          <div className={`text-white ${isResources ? 'mt-12' : 'mt-4'}`}>
            {!isResources ? (
              <PrimaryButton onClick={onClose} isInvalid={false}>
                Got it!
              </PrimaryButton>
            ) : (
              <SecondaryButton isOrange={true} onClick={onClose} isValid={false}>
                <span className="w-full">OK</span>
              </SecondaryButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
