import { Answer as DigitalProfileAnswer } from '../context/DigitalProfileContext';
import { AnswerType } from '../types/http';

const ASSESSMENT_ANSWERS = 'assessment_answers';
const ASSESSMENT_DETAILS = 'assessment_details';
const DIGITAL_PROFILE_ANSWERS = 'digital_profile_answers';

export type AssessmentDetails = {
  surveyId: string;
  maturityQuestionsLength: number;
  readinessQuestionsLength: number;
};

export function saveAssessmentDetails(details: AssessmentDetails | null) {
  if (!details) {
    window.localStorage.removeItem(ASSESSMENT_DETAILS);
    return;
  }
  window.localStorage.setItem(ASSESSMENT_DETAILS, JSON.stringify(details));
}

export function getAssessmentDetails() {
  const result = window.localStorage.getItem(ASSESSMENT_DETAILS);
  return result ? JSON.parse(result) : [];
}

export function saveAssessmentAnswers(answers: AnswerType[]) {
  if (!answers.length) {
    window.localStorage.removeItem(ASSESSMENT_ANSWERS);
    return;
  }
  window.localStorage.setItem(ASSESSMENT_ANSWERS, JSON.stringify(answers));
}

export function getAssessmentAnswers() {
  const result = window.localStorage.getItem(ASSESSMENT_ANSWERS);
  return result ? JSON.parse(result) : [];
}

export function saveDigitalProfileAnswers(answers: DigitalProfileAnswer[]) {
  if (!answers.length) {
    window.localStorage.removeItem(DIGITAL_PROFILE_ANSWERS);
    return;
  }
  window.localStorage.setItem(DIGITAL_PROFILE_ANSWERS, JSON.stringify(answers));
}

export function getDigitalProfileAnswers() {
  const result = window.localStorage.getItem(DIGITAL_PROFILE_ANSWERS);
  return result ? JSON.parse(result) : [];
}
