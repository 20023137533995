import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import PrimaryButton from '../../components/button/PrimaryButton';
import ToastPopup from '../../components/Toastr';

import SignupLayout from '../../layouts/SignupLayout';

const SMEHUB_URL =
  process.env.REACT_APP_SMEHUB_URL ?? window?._env_?.REACT_APP_SMEHUB_URL ?? '/sme-hub';

const SmeHub = () => {
  const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
  const [accessToken, setAccessToken] = useState<string>('');
  const [loginError, setLoginError] = useState<string>('');
  const history = useHistory();

  useEffect(() => {
    const token = window.sessionStorage.getItem('access_token');
    setLoggedIn(!!token);
    if (!token) {
      history.push('/login?redirect=/smehub');
    }

    setAccessToken(token!);
  }, []);

  return (
    <SignupLayout
      title="Login to SME Hub"
      subTitle="Click the button below to automatically log in with your DigiTayo account."
    >
      <div>
        <div className="mt-7">
          {!!loginError && (
            <ToastPopup error={true} message={loginError} onClose={() => setLoginError('')} />
          )}

          {isLoggedIn ? (
            <form method="POST" action={`${SMEHUB_URL}/login/index.php`}>
              <input type="hidden" name="Authorization" value={accessToken} />
              <div>
                <PrimaryButton
                  isInvalid={false}
                  onClick={() => {
                    console.log('clicked');
                  }}
                  type="submit"
                >
                  Continue to the SME Hub
                </PrimaryButton>
              </div>
            </form>
          ) : null}
        </div>
      </div>
    </SignupLayout>
  );
};

export default SmeHub;
