import { useQuery } from '@tanstack/react-query';
import React, { FC, useEffect, useState } from 'react';
import { LuChevronFirst, LuChevronLast, LuChevronLeft, LuChevronRight } from 'react-icons/lu';
import { useHistory } from 'react-router';
import CardCarousel from '../../../components/CardCarousel';
import { getAllResources } from '../../../services/https/api';
import { GetResourcesParams } from '../../../types/http';
import { handleErrorCode } from '../../../utils/handleError';
import { Resource } from './DashboardResources';

type BlogsTabType = {
  filters: GetResourcesParams;
  setFilters: (filters: GetResourcesParams) => void;
};

const blogsTab: FC<BlogsTabType> = ({ filters, setFilters }) => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(4);
  const { data: blogs = [] } = useQuery<Resource[]>({
    queryKey: ['resource', 'blogs', filters],
    queryFn: async ({ queryKey: [, type, filters] }) => {
      try {
        const q: GetResourcesParams = {
          ...(filters as GetResourcesParams),
          type: type as 'blogs' | 'enablers' | 'tools' | undefined,
          limit: 5,
        };

        const { data } = await getAllResources(q);

        if (data.meta.totalPages) {
          setTotalPages(data.meta.totalPages);
        }

        return data.resources.filter((resource: Resource) => resource.type === 'blogs');
      } catch (error) {
        const err = error as { response: { data: { error_code: string } } };
        handleErrorCode(
          err?.response?.data?.error_code,
          () => {
            // placeholder
          },
          history
        );
      }
    },
    initialData: [],
  });

  const handlePageChange = (page: React.SetStateAction<number>) => {
    setCurrentPage(page);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  useEffect(() => {
    setFilters({ ...filters, offset: (currentPage - 1) * 5 });
  }, [currentPage]);

  const buttonStyle =
    'flex justify-center items-center rounded-md text-digitayoBlack2 bg-transparent text-sm font-bold w-[26px] h-[26px] hover:bg-white hover:text-digitayoOrange1';

  return (
    <div className="mb-5 mt-3">
      <CardCarousel data={blogs ?? []} blogs />
      <div className="flex items-center justify-center space-x-2 mt-5">
        <button
          className={
            currentPage === 1
              ? `${buttonStyle} text-digitayoGray3 hover:bg-transparent hover:text-digitayoGray3`
              : `${buttonStyle} `
          }
          onClick={() => handlePageChange(1)}
          disabled={currentPage === 1}
        >
          <LuChevronFirst fontSize={20} />
        </button>
        <button
          className={
            currentPage === 1
              ? `${buttonStyle} text-digitayoGray3 hover:bg-transparent hover:text-digitayoGray3`
              : buttonStyle
          }
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          <LuChevronLeft fontSize={20} />
        </button>

        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index + 1}
            className={`px-2 rounded-md ${
              currentPage === index + 1
                ? 'bg-white text-digitayoOrange1 border border-digitayoOrange1 shadow-lg shadow-[#9C27B01A]'
                : 'text-digitayoBlack2'
            }  hover:bg-white hover:text-digitayoOrange1 text-sm font-bold min-w-[26px] h-[26px]`}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </button>
        ))}

        <button
          className={
            currentPage === totalPages
              ? `${buttonStyle} text-digitayoGray3 hover:bg-transparent hover:text-digitayoGray3`
              : buttonStyle
          }
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          <LuChevronRight fontSize={20} />
        </button>
        <button
          className={
            currentPage === totalPages
              ? `${buttonStyle} text-digitayoGray3 hover:bg-transparent hover:text-digitayoGray3`
              : buttonStyle
          }
          onClick={() => handlePageChange(totalPages)}
          disabled={currentPage === totalPages}
        >
          <LuChevronLast fontSize={20} />
        </button>
      </div>
    </div>
  );
};

export default blogsTab;
