import { useEffect, useState } from 'react';
import PrimaryButton from '../../components/button/PrimaryButton';
import InputField from '../../components/form/InputField';
import SelectField from '../../components/form/SelectField';
import TextareaField from '../../components/form/TextareaField';
import DashboardLayout from '../../layouts/DashboardLayout';
import { useForm } from 'react-hook-form';
import { getProfile, sendInquiry } from '../../services/https/api';
import { Sme } from '../../types/http';
import ToastPopup from '../../components/Toastr';
import { useHistory } from 'react-router';

const DashboardHelpdesk = () => {
  const {
    register,
    watch,
    getValues,
    formState: { isValid },
  } = useForm<{ message: string; inquiryType: string }>();
  const history = useHistory();

  const [profile, setProfile] = useState<Sme>();

  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const profileData = await getProfile();
        setProfile(profileData.data.sme);

        const inquiryTypeValue = watch('inquiryType');
        console.log(inquiryTypeValue);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const submitForm = async () => {
    const inquiryType = watch('inquiryType');
    const message = watch('message');

    try {
      await sendInquiry(inquiryType, message);
      console.log(inquiryType, message);
      setSubmitted(true);
      history.push('/inquiry-submitted');
    } catch (error) {
      console.log(error);
    }
  };

  const inquiryTypes = [
    'General inquiry',
    'Partnerships',
    'Learning Modules Feedback',
    'Bugs / Issues with the app',
    'Suggestions/ Recommendations',
    'Other Inquiries',
  ];

  return (
    <DashboardLayout label="Help Center" showBackButton>
      {submitted && (
        <ToastPopup
          success={true}
          message="Your inquiry is successfully sent."
          className="mx-10"
          onClose={() => setSubmitted(false)}
        />
      )}
      {!submitted && (
        <form className="py-10 px-8 bg-digitayoWhite rounded-t-3xl">
          <h2 className="font-bold text-[20px] text-digitayoBlack4">Your Details</h2>

          <InputField
            label="Name"
            placeholder="Juan Dela Cruz"
            value={`${profile?.first_name ?? ''} ${profile?.last_name ?? ''}`}
            readOnly
            inputClassName="bg-gray-100 text-slate-500 border-slate-300 shadow-none"
            className="bg-gray-100 text-slate-500 border-slate-300 shadow-none"
          />
          <InputField
            label="Email Address"
            placeholder="jdelacruz@gmail.com"
            value={profile?.email ?? ''}
            readOnly
            inputClassName="bg-gray-100 text-slate-500 border-slate-300 shadow-none"
            className="bg-gray-100 text-slate-500 border-slate-300 shadow-none"
          />
          <InputField
            label="Business Name"
            placeholder="Pasalubong Enterprise"
            inputClassName="bg-gray-100 text-slate-500 border-slate-300 shadow-none"
            className="bg-gray-100 text-slate-500 border-slate-300 shadow-none"
            readOnly
            value={profile?.business_name ?? ''}
          />

          <h2 className="pt-10 font-bold text-[20px] text-black">Inquiry:</h2>

          <SelectField
            value={getValues('inquiryType')}
            label="Inquiry Type"
            {...register('inquiryType', {
              required: 'Inquiry Type is required.',
            })}
            placeholder="e.g. General Inquiry"
            options={inquiryTypes.map((str) => ({ code: str, name: str }))}
            hasOptionsWithId={false}
          />

          <TextareaField
            label="Message"
            placeholder="Your message here"
            isValid={watch('message')}
            {...register('message', {
              required: 'Message is required',
            })}
          />

          <div className="pt-10">
            <PrimaryButton isInvalid={!isValid} onClick={submitForm}>
              Submit
            </PrimaryButton>
          </div>
        </form>
      )}
    </DashboardLayout>
  );
};

export default DashboardHelpdesk;
