import React, { useState, useEffect, useContext } from 'react';
import {
  getRegions,
  getCities,
  getBarangays,
  getBarangaysFromDistrict,
} from '../../services/https/ph-location-api';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import BusinessInfoFormSection from '../../components/section/BusinessInfoFormSection';
import { BusinessInfoContext } from '../../context/BusinessInfoContext';
import SelectField from '../../components/form/SelectField';

type LocationPairs = {
  code: string;
  name: string;
  regionName: string;
  islandGroupCode: string;
  psgc10DigitCode: string;
  provinceCode: string;
  districtCode: string;
};

type Location = {
  code: string;
  name: string;
};

type Values = {
  business_name: string;
  region: Location;
  city: Location;
  barangay: Location;
};

const BusinessInfo2 = () => {
  const [regionOptions, setRegionOptions] = useState<LocationPairs[]>([]);
  const [cityOptions, setCityOptions] = useState<LocationPairs[]>([]);
  const [barangayOptions, setBarangayOptions] = useState<LocationPairs[]>([]);

  const history = useHistory();
  const [completed, setCompleted] = useState<boolean>(false);
  const [businessName, setBusinessName] = useState<string>('');
  const { setValue, getValues, watch } = useForm<Values>();

  const businessInfo = useContext(BusinessInfoContext);

  const handleRegion = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = regionOptions.find((o) => o.code === event.target.value);
    console.log('region selectedOption: ', selectedOption);
    if (selectedOption) {
      setValue('region', { code: selectedOption.code, name: selectedOption.name });
      setCityOptions(await getCities(event.target.value));
    }
  };

  const handleCity = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = cityOptions.find((o) => o.code === event.target.value);
    console.log('city selectedOption: ', selectedOption);
    if (selectedOption) {
      setValue('city', { code: selectedOption.code, name: selectedOption.name });
      if (!selectedOption.provinceCode) {
        setBarangayOptions(await getBarangaysFromDistrict(selectedOption.districtCode));
      } else {
        setBarangayOptions(await getBarangays(selectedOption.provinceCode));
      }
    }
  };

  const handleBarangay = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = barangayOptions.find((o) => o.code === event.target.value);
    console.log('barangay selectedOption: ', selectedOption);
    if (selectedOption) {
      setValue('barangay', { code: selectedOption.code, name: selectedOption.name });
    }
  };

  const data = {
    title: 'Business Information',
    heading: `${'Where is ' + (businessName || businessInfo.value?.business_name) + ' located?'}`,
    progress: 66.6,
  };

  useEffect(() => {
    const sme = JSON.parse(window.sessionStorage.getItem('profile') as string);
    const accessToken = window.sessionStorage.getItem('access_token');

    if (sme && accessToken) {
      setBusinessName(sme.business_name);
      setValue('business_name', sme.business_name);
      setValue('region', sme.region);
      setValue('city', sme.city);
      setValue('barangay', sme.barangay);
      if ((sme.region && sme.city && sme.barangay) === undefined) {
        setCompleted(false);
      } else {
        setCompleted(true);
      }
    }

    // Provinces
    (async () => {
      setRegionOptions(await getRegions());
    })();
  }, []);

  useEffect(() => {
    const subscription = watch((value) => {
      if (!!(value.region && value.city && value.barangay)) {
        businessInfo.setValue({
          ...Object(businessInfo.value),
          region: value.region.name,
          city: value.city.name,
          barangay: value.barangay.name,
        });
        setCompleted(true);
      } else {
        setCompleted(false);
      }
    });
    return () => subscription.unsubscribe();
  }, [businessInfo, watch]);

  const nextPage = () => {
    const storedData = window.sessionStorage.getItem('profile');
    const myObject = storedData ? JSON.parse(storedData) : {};
    myObject['region'] = getValues('region.name');
    myObject['city'] = getValues('city.name');
    myObject['barangay'] = getValues('barangay.name');
    window.sessionStorage.setItem('profile', JSON.stringify(myObject));
    console.log(myObject);
    history.push('/business-info/3');
  };

  return (
    <BusinessInfoFormSection
      name={data.title}
      nProgress={data.progress}
      heading={data.heading}
      isComplete={completed}
      onClick={() => nextPage()}
    >
      <div className="flex flex-col gap-3">
        <SelectField
          value={getValues('region.code')}
          label="Region"
          options={regionOptions}
          placeholder="e.g. Region V (Bicol)"
          onChange={handleRegion}
          hasOptionsWithId={true}
        />

        <SelectField
          value={getValues('city.code')}
          label="Town/City"
          options={cityOptions}
          placeholder="e.g. Naga"
          onChange={handleCity}
          hasOptionsWithId={true}
        />

        <SelectField
          value={getValues('barangay.code')}
          label="Barangay"
          options={barangayOptions}
          placeholder="e.g. Balatas"
          onChange={handleBarangay}
          hasOptionsWithId={true}
        />
      </div>
    </BusinessInfoFormSection>
  );
};

export default BusinessInfo2;
