import RedCircle from '../assets/modal/alert-red-circle-outline.svg';
import WarningCircle from '../assets/modal/alert-warning-circle-outline.svg';
import GreenCircle from '../assets/modal/check-circle-icon.png';
import InfoCircle from '../assets/modal/information-icon.png';
import CloseIcon from '../assets/close-icon.svg';

type ToastType = {
  message: string;
  className?: string;
  success?: boolean;
  error?: boolean;
  info?: boolean;
  warning?: boolean;
  onClose: () => void;
};

const ToastPopup = (props: ToastType) => {
  return (
    <>
      {props.error && (
        <div className="border border-[#D32F2F] rounded-xl bg-[#FFEBEE] p-4 flex justify-between gap-2 mb-3">
          <div className="w-14">
            <img src={RedCircle} alt="icon" width="50" />
          </div>
          <div className="text-sm text-[#D32F2F] self-center mr-auto w-3/4">{props.message}</div>
          <div className="w-8 cursor-pointer" onClick={props.onClose}>
            <img src={CloseIcon} alt="icon" width="25" />
          </div>
        </div>
      )}

      {props.success && (
        <div className="border border-[#388E3C] rounded-xl bg-[#F1F8E9] p-4 flex justify-between gap-2 mb-3">
          <div className="w-14">
            <img src={GreenCircle} alt="icon" width="50" />
          </div>
          <div className="text-sm text-[#388E3C] self-center mr-auto w-3/4">{props.message}</div>
          <div className="w-8 cursor-pointer" onClick={props.onClose}>
            <img src={CloseIcon} alt="icon" width="25" />
          </div>
        </div>
      )}

      {props.info && (
        <div className="border border-[#263238] rounded-xl bg-[#FAFAFA] p-4 flex justify-between gap-2 mb-3">
          <div className="w-14">
            <img src={InfoCircle} alt="icon" width="50" />
          </div>
          <div className="text-sm text-[#263238] self-center mr-auto w-3/4">{props.message}</div>
          <div className="w-8 cursor-pointer" onClick={props.onClose}>
            <img src={CloseIcon} alt="icon" width="25" />
          </div>
        </div>
      )}

      {props.warning && (
        <div className="border border-[#FF6D00] rounded-xl bg-[#FFF1E0] p-4 flex justify-between gap-2 mb-3">
          <div className="w-14">
            <img src={WarningCircle} alt="icon" width="50" />
          </div>
          <div className="text-sm text-[#FF6D00] self-center mr-auto w-3/4">{props.message}</div>
          <div className="w-8 cursor-pointer" onClick={props.onClose}>
            <img src={CloseIcon} alt="icon" width="25" />
          </div>
        </div>
      )}
    </>
  );
};

export default ToastPopup;
