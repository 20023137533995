import DashboardLayout from '../../layouts/DashboardLayout';

const DashboardAssessment = () => {
  return (
    <DashboardLayout>
      <h1>Assessment Page</h1>
    </DashboardLayout>
  );
};

export default DashboardAssessment;
