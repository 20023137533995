import { useHistory } from 'react-router';
import Tac from '../../assets/signup/terms-and-conditions.svg';
import OnboardingLayout from '../../layouts/OnboardingLayout';

type ContentDataType = {
  header: string;
  item1: string;
  item2: string;
  item3: string;
  item4?: string;
};

const TermsAndConditions = () => {
  const history = useHistory();

  const onRoute = () => {
    history.push('/business-info/1');
  };

  const contentData: ContentDataType = {
    header: 'We have a few terms and conditions that we want to run by you:',
    item1: `I agree to DigiTayo’s`,
    item2: `I agree to DigiTayo’s`,
    item3: 'I agree to receive occasional product updates through my provided contact details',
  };

  return (
    <OnboardingLayout
      imgSrc={Tac}
      imgAlt="Onboarding Image"
      content={contentData}
      btnTitle="Start Application"
      onRoute={onRoute}
      hasCheckboxes={true}
    />
  );
};

export default TermsAndConditions;
