import { forwardRef, useState } from 'react';
import Hide from '../../assets/password/hide.svg';
import Show from '../../assets/password/show.svg';

type PasswordType = {
  id: string;
  label: string;
  placeholder: string;
  isValid: boolean;
  name?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const PasswordField = forwardRef<HTMLInputElement, PasswordType>((props, ref) => {
  const { id, label, placeholder, isValid, onChange, name } = props;
  const [passwordShow, setPasswordShow] = useState<boolean>(false);

  return (
    <div className="border rounded-lg border-digitayoGray3 p-2 mt-2 flex flex-row justify-between items-center w-100">
      <div>
        <div className={`text-sm text-digitayoBlack1 p-1 pb-0 mb-0 ${isValid || 'text-green-600'}`}>
          {label}
        </div>
        <div className="flex justify-between text-xl">
          <input
            id={id}
            ref={ref}
            name={name ?? 'password'}
            type={`${passwordShow ? 'text' : 'password'}`}
            className="grow rounded py-2 text-digitayoBlack4 px-1 outline-0 bg-digitayoWhite w-64"
            placeholder={placeholder}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="w-7 mr-1 cursor-pointer" onClick={() => setPasswordShow(!passwordShow)}>
        {!passwordShow && <img src={Show} alt="show-password" />}
        {passwordShow && <img src={Hide} alt="hide-password" />}
      </div>
    </div>
  );
});

export default PasswordField;
