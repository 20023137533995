import SetPasswordIcon from '../../assets/password/password.png';
import PrimaryButton from '../../components/button/PrimaryButton';
import { useForm } from 'react-hook-form';
import { changePassword } from '../../services/https/api';
import { useContext, useEffect, useState } from 'react';
import ToastPopup from '../../components/Toastr';
import PasswordField from '../../components/form/PasswordField';
import { useHistory } from 'react-router';
import { AuthUserContext } from '../../context/AuthUserContextProvider';

const ChangePassword = () => {
  const history = useHistory();
  const [isOldPasswordValid, setIsOldPasswordValid] = useState<string | boolean>();
  const [isNewPasswordValid, setNewPasswordValid] = useState<string | boolean>();
  const [isRetypeNewPasswordValid, setRetypeNewPasswordValid] = useState<string | boolean>();

  const { getValues, setValue, handleSubmit } = useForm<{
    currentPassword: string;
    password: string;
    retypePassword: string;
  }>();

  const [success, setSuccess] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [passwordError, setPasswordError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { profile } = useContext(AuthUserContext);

  useEffect(() => {
    if (profile && profile.isLoginWithGoogle) {
      history.push('/dashboard');
    }
  }, [profile]);

  const validatePassword = (password: string) => {
    if (
      password.match(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[-#!$@%^&*()_+|~=`{}\[\]:";'<>?,.\/ ]).{8,}$/g
      )
    ) {
      return true;
    } else {
      return 'Password must be at least 8 characters long.';
    }
  };

  const handleCurrentPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    const passwordError = validatePassword(password);
    setValue('currentPassword', password);
    setIsOldPasswordValid(passwordError);
  };

  const handleNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    const passwordError = validatePassword(password);
    setValue('password', password);
    setNewPasswordValid(passwordError);

    const retypePassword = getValues('retypePassword');

    setPasswordMatch(password === retypePassword);
  };

  const handleRetypePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const retypePassword = event.target.value;
    const passwordError = validatePassword(retypePassword);
    setValue('retypePassword', retypePassword);
    setRetypeNewPasswordValid(passwordError);

    setPasswordMatch(getValues('password') === retypePassword);
  };

  const handleErrorCode = (errorCode: string) => {
    console.log('errorCdode', errorCode);
    if (errorCode === 'INVALID_CODE' || errorCode === 'RESET_PASSWORD_EXPIRED') {
      setErrorMessage('Link expired, try requesting for change password again.');
    } else if (errorCode === 'INVALID_PASSWORD') {
      setErrorMessage('Your current password is incorrect.');
      console.log(errorCode);
    } else if (errorCode === 'NOT_EMAIL_LOGIN') {
      setErrorMessage(
        'You are not able to change your password since you are signed up via google'
      );
      console.log(errorCode);
    } else if (errorCode === 'UNAUTHORIZED') {
      setErrorMessage('Session has ended. Please login again');
      window.sessionStorage.clear();
      history.push('/login');
    } else {
      setErrorMessage('There was an error changing your password, Please try again later.');
      console.log(errorCode);
    }
  };

  const onSubmit = async () => {
    const currentPassword = getValues('currentPassword');
    const password = getValues('password');
    const retypePassword = getValues('retypePassword');
    setErrorMessage('');
    setSuccess(false);

    setPasswordMatch(password === retypePassword);
    if (!passwordMatch) {
      return;
    }

    try {
      await changePassword({
        password: currentPassword,
        newPassword: password,
      });

      console.log('success');
      setSuccess(true);
      window.localStorage.setItem('changedPassword', 'true');
      history.push('/updated-password');
    } catch (error: any) {
      console.log(error.response.data.message);
      handleErrorCode(error.response.data.message);
    }
  };

  return (
    <div className="bg-digitayoWhite min-h-screen h-100 p-7 font-archivo">
      <div className="my-6 bg-gradient-to-r from-digitayoOrange1 to-digitayoPurple1 bg-clip-text text-transparent font-bold text-[34px]">
        Change Password
      </div>

      <img src={SetPasswordIcon} alt="Otp Image" className="w-1/2 mx-auto py-6" />

      <div className="text-digitayoBlack4 text-xl my-6">
        Your new password must be different from your previously used password
      </div>

      {!!errorMessage && (
        <ToastPopup error={true} message={errorMessage} onClose={() => setErrorMessage('')} />
      )}
      {!!passwordError && (
        <ToastPopup
          error={true}
          message={
            'password should be: min 8 chars, at least 1 lowercase, uppercase, number, and symbol'
          }
          onClose={() => setPasswordError(false)}
        />
      )}
      {!!success && (
        <ToastPopup
          success={true}
          message={'You have successfully changed your password!'}
          onClose={() => setSuccess(false)}
        />
      )}

      <form className="flex flex-col gap-3 pb-8" onSubmit={handleSubmit(onSubmit)}>
        <PasswordField
          id="oldPassword"
          label="Old Password"
          placeholder="Your old password"
          isValid={isOldPasswordValid !== true}
          onChange={handleCurrentPassword}
        />

        <PasswordField
          id="newPassword"
          label="New Password"
          placeholder="Your new password"
          isValid={isNewPasswordValid !== true}
          onChange={handleNewPassword}
        />

        <PasswordField
          id="retypeNewPassword"
          label="Re-type New Password"
          placeholder="Retype your new password"
          isValid={isRetypeNewPasswordValid !== true}
          onChange={handleRetypePassword}
        />
      </form>

      <div className="flex flex-col my-6">
        <PrimaryButton
          type="submit"
          isInvalid={
            (isOldPasswordValid && isNewPasswordValid && isRetypeNewPasswordValid) !== true
          }
          onClick={() => onSubmit()}
        >
          Update
        </PrimaryButton>
      </div>
    </div>
  );
};

export default ChangePassword;
