import { useHistory } from 'react-router';
import Req from '../../assets/signup/requirements.svg';
import OnboardingLayout from '../../layouts/OnboardingLayout';

type ContentDataType = {
  header: string;
  item1: string;
  item2: string;
  item3: string;
  item4?: string;
};

const Requirements = () => {
  const history = useHistory();

  const onRoute = () => {
    history.push('/terms-and-conditions');
  };

  const contentData: ContentDataType = {
    header: 'Please provide the following information:',
    item1: 'Business Information',
    item2: 'Authorized Representative',
    item3: 'Digital Tools Used',
  };

  return (
    <OnboardingLayout
      imgSrc={Req}
      imgAlt="Onboarding Image"
      content={contentData}
      btnTitle="I'm Ready!"
      onRoute={onRoute}
      hasCheckboxes={false}
    />
  );
};

export default Requirements;
