import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import DigitalMaturityBG from '../../assets/das/digital-maturity.jpg';
import DigitalReadinessBG from '../../assets/das/digital-readiness.jpg';
import NotApplicableIcon from '../../assets/das/not-applicable.svg';
import DasAnswerButton from '../../components/button/DasAnswerButton';
import MultiToggleLanguageButton from '../../components/button/MultiToggleLanguageButton';
import PrimaryButton from '../../components/button/PrimaryButton';
import SecondaryButton from '../../components/button/SecondaryButton';
import SurveyModal from '../../components/modal/SurveyModal';
import ToastPopup from '../../components/Toastr';
import {
  answerDigitalAssessmentSurvey,
  getDigitalAssessmentSurvey,
  getDigitalAssessmentSurveyAllQuestionTranslate,
  getLanguages,
} from '../../services/https/api';
import { Language, Question } from '../../types/http';
import {
  getAssessmentAnswers,
  getAssessmentDetails,
  saveAssessmentAnswers,
  saveAssessmentDetails,
} from '../../utils/StorageHelper';

type ListItemType = {
  step: string;
  description: string;
};

type QuestionType = {
  id: string;
  order: number;
  weight: number;
  question: string;
  l1_option: string;
  l2_option: string;
  l3_option: string;
  l4_option: string;
  l5_option: string;
};

type DigitalMaturityType = {
  icon: string;
  id: string;
  label: string;
  order: number;
  questions: QuestionType[];
  code: string;
};

type AnswerType = {
  question_id: string;
  answer: number | null;
  skip: boolean;
};

type LanguageType = {
  [x: string]: QuestionType;
};

type QuestionLanguage = {
  language: LanguageType;
};

const ListItem = ({ step, description }: ListItemType) => {
  return (
    <div className={`flex gap-4 mb-4 pb-4 ${step !== '5' && 'border-b border-digitayoGray3'}`}>
      <div className="flex justify-center items-center text-digitayoOrange1 w-11 text-[20px] font-bold">
        {step}
      </div>
      <div className="text-digitayoBlack4 text-md flex-1">{description}</div>
    </div>
  );
};

const DigitalAssessment = () => {
  const history = useHistory();
  const [isMaturitySurveyOpen, setIsMaturitySurveyOpen] = useState<boolean>(false);
  const [isReadinessSurveyOpen, setIsReadinessSurveyOpen] = useState<boolean>(false);
  const [isDigitalMaturity, setIsDigitalMaturity] = useState<boolean>(true);
  const [isDigitalReadiness, setIsDigitalReadiness] = useState<boolean>(false);
  const [digitalMaturityData, setDigitalMaturityData] = useState<DigitalMaturityType>();
  const [digitalReadinessData, setDigitalReadinessData] = useState<DigitalMaturityType>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [answer, setAnswer] = useState<AnswerType | null>(null);
  const [answers, setAnswers] = useState<AnswerType[]>([]);
  const [surveyLoaded, setSurveyLoaded] = useState(false);
  const maturitySteps = [
    {
      step: '1',
      description:
        'SMEs who are still generally a traditional business, mostly offline (not using any technology) and most business processes are manual.',
    },
    {
      step: '2',
      description:
        'SMEs who are using devices like mobile phone or laptop for basic productivity tools to manage orders, but transactions are mostly offline.',
    },
    {
      step: '3',
      description:
        'SMEs who are using devices for basic business operations like tracking orders via messaging apps and spreadsheet; has static website for product or service information.',
    },
    {
      step: '4',
      description:
        'SMEs who are using devices to manage real-time orders coming from e-commerce platforms, with integration to payments and logistics/delivery service providers.',
    },
    {
      step: '5',
      description:
        'SMEs who are using advanced digital technologies (artificial intelligence, internet of things, virtual/augmented reality) , analytics, and business process automations.',
    },
  ];
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [languages, setLanguages] = useState([]);
  const [selectedValue, setSelectedValue] = useState('english');
  // const languages = ['English', 'Tagalog', 'Cebuano'];
  const [translatedQuestions, setTranslatedQuestions] = useState([]);

  const goToPreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleErrorCode = (errorCode: string) => {
    if (errorCode === 'UNAUTHORIZED') {
      setErrorMessage('Access is denied due to invalid credentials.');
      window.sessionStorage.clear();
      history.push('/login');
    } else if (errorCode === 'BAD_REQUEST') {
      setErrorMessage('Validation error. Please check the required fields');
    } else if (errorCode) {
      setErrorMessage('There was an error. Please try again later.');
      console.log(errorCode);
    }
  };

  const getSurvey = async () => {
    try {
      const response = await getDigitalAssessmentSurvey();
      const digitalMaturityData = response.data.survey.categories[0];
      const digitalReadinessData = response.data.survey.categories[1];
      setDigitalMaturityData(digitalMaturityData);
      setDigitalReadinessData(digitalReadinessData);

      window.sessionStorage.setItem('surveyId', response.data.survey.id);
      saveAssessmentDetails({
        surveyId: response.data.survey.id,
        maturityQuestionsLength: digitalMaturityData.questions.length,
        readinessQuestionsLength: digitalReadinessData.questions.length,
      });
      setSurveyLoaded(true);
    } catch (error: any) {
      console.log(error.response.data.error_code);
      handleErrorCode(error.response.data.error_code);
    }
  };

  const getDigitalAssessmentLanguages = async () => {
    try {
      const response = await getLanguages();
      setLanguages(response.data);
    } catch (error: any) {
      console.log(error.response.data.error_code);
      handleErrorCode(error.response.data.error_code);
    }
  };

  const getTranslatedQuestions = (question: QuestionType) => {
    const updatedQuestions: QuestionLanguage = { language: {} };

    const hasTranslations =
      translatedQuestions.filter((tq: Question) => tq.assessment_question_id == question.id)
        .length > 0;

    if (hasTranslations) {
      const filteredTranslationQuestion = translatedQuestions.filter(
        (tq: Question) => tq.assessment_question_id == question.id
      );

      filteredTranslationQuestion.forEach((tqd: Question) => {
        languages.forEach((language: Language) => {
          if (question.id == tqd.assessment_question_id) {
            if (tqd.language_id == language.id) {
              updatedQuestions.language[language.code] = tqd;
            }
          }
        });
      });
    } else {
      updatedQuestions.language.english = question;
    }
    return updatedQuestions;
  };

  const getQuestionTranslations = async () => {
    try {
      const surveyId = window.sessionStorage.getItem('surveyId') || '';

      const response = await getDigitalAssessmentSurveyAllQuestionTranslate(surveyId);
      setTranslatedQuestions(response.data);
    } catch (error: any) {
      console.log(error.response.data.error_code);
      handleErrorCode(error.response.data.error_code);
    }
  };

  const setDigitalAssessment = async () => {
    try {
      const surveyId = window.sessionStorage.getItem('surveyId');
      if (surveyId) {
        const array = {
          answers: [...answers],
        };
        await answerDigitalAssessmentSurvey(surveyId, array);
        saveAssessmentAnswers([]);
        saveAssessmentDetails(null);
      }
    } catch (error: any) {
      console.log(error.response.data.error_code);
      handleErrorCode(error.response.data.error_code);
    }
  };

  const addAnswer = async () => {
    const selectedAnswer = answer;
    setAnswer(null);

    if (!selectedAnswer) {
      return;
    }

    const updatedAnswers: AnswerType[] = answers.map((item) =>
      item?.question_id === selectedAnswer?.question_id ? selectedAnswer : item
    );

    let newAnswers: AnswerType[] = [];

    if (updatedAnswers.some((item) => item?.question_id === selectedAnswer?.question_id)) {
      newAnswers = updatedAnswers;
    } else {
      newAnswers = [...updatedAnswers, selectedAnswer];
    }

    setAnswers(newAnswers);

    saveAssessmentAnswers(newAnswers);

    setCurrentQuestionIndex(currentQuestionIndex + 1);

    if (currentQuestionIndex + 1 === digitalMaturityData?.questions?.length) {
      setIsMaturitySurveyOpen(false);
      setIsDigitalMaturity(false);
      setIsDigitalReadiness(true);
    }

    if (
      digitalMaturityData?.questions?.length &&
      digitalReadinessData?.questions?.length &&
      currentQuestionIndex + 1 ===
        digitalMaturityData?.questions?.length + digitalReadinessData?.questions?.length
    ) {
      await setDigitalAssessment();
      history.push('/score');
    }
  };

  const handleDigitalMaturityProceed = () => {
    console.log('handle digital maturity proceed');
    setIsDigitalMaturity(false);
    setIsMaturitySurveyOpen(true);
  };

  const handleDigitalReadinessProceed = () => {
    setIsDigitalReadiness(false);
    setIsReadinessSurveyOpen(true);
  };

  const onCloseMaturity = () => {
    setIsMaturitySurveyOpen(false);
    setIsDigitalMaturity(true);
  };

  const onCloseReadiness = () => {
    setIsReadinessSurveyOpen(false);
    setIsDigitalReadiness(true);
  };

  useEffect(() => {
    getDigitalAssessmentLanguages();
    getSurvey();
  }, []);

  useEffect(() => {
    if (surveyLoaded) {
      getQuestionTranslations();

      const savedAnswers = getAssessmentAnswers();
      const savedAssessmentDetails = getAssessmentDetails();
      const currentIndex = savedAnswers.length;

      console.log('survey loaded', savedAssessmentDetails, savedAnswers);

      if (currentIndex > 0) {
        setAnswers(savedAnswers);
        setCurrentQuestionIndex(savedAnswers.length);

        if (currentIndex >= (savedAssessmentDetails.maturityQuestionsLength ?? 0)) {
          setIsDigitalMaturity(false);
          handleDigitalReadinessProceed();
        } else {
          handleDigitalMaturityProceed();
        }
      } else {
        setIsDigitalMaturity(true);
      }
    }
  }, [surveyLoaded]);

  const multiLanguageToggle = (languageList: Language[]) => {
    return (
      <MultiToggleLanguageButton
        languages={languageList}
        selectedValue={selectedValue}
        onSelect={setSelectedValue}
      />
    );
  };

  const filteredLanguages = (languageList: string[]) => {
    const availableLanguageList: Language[] = [];

    if (languages.length > 0) {
      for (let index = 0; index < languages.length; index++) {
        const language: Language = languages[index];

        languageList.forEach((lan: string) => {
          if (lan == language.code) {
            availableLanguageList.push(language);
          }
        });
      }
    }

    return availableLanguageList;
  };

  return (
    <div className="min-h-screen h-100 bg-digitayoWhite flex flex-col">
      <div className={`${isMaturitySurveyOpen ? 'hidden' : ''}`}>
        {isDigitalReadiness && (
          <div className="flex flex-col justify-between min-h-screen h-full">
            <div>
              <img src={DigitalReadinessBG} alt="digital-maturity-bg" />
            </div>
            <div className="px-8 bg-gradient-to-t from-white to-gray-50">
              {multiLanguageToggle(languages)}
              <div className="mb-6 mt-10 bg-gradient-to-r from-digitayoOrange1 to-digitayoPurple1 bg-clip-text text-transparent font-bold text-[28px]">
                Digital Readiness
              </div>
              <div className="text-digitayoBlack4 text-lg">
                Digital readiness refers to how well a business can adapt and effectively utilize
                digital technologies and tools. Masasabing ready ang iyong negosyo kung ito ay may
                resources at iyong staff ay may angkop na skills para mapalago ang negosyo
                digitally.
              </div>
            </div>
            <div className="flex flex-col w-full pt-6 pb-8 gap-2 px-8 mt-auto">
              <PrimaryButton isInvalid={false} onClick={handleDigitalReadinessProceed}>
                Proceed
              </PrimaryButton>
            </div>
          </div>
        )}

        {isDigitalMaturity && (
          <>
            <div>
              <img src={DigitalMaturityBG} alt="digital-maturity-bg" />
            </div>
            <div className="px-8 bg-gradient-to-t from-white to-gray-50">
              {multiLanguageToggle(languages)}
              <div className="mb-6 mt-10 bg-gradient-to-r from-digitayoOrange1 to-digitayoPurple1 bg-clip-text text-transparent font-bold text-[28px]">
                Digital Maturity
              </div>
              <div className="text-digitayoBlack4 text-lg">
                Digital maturity refers to how well a business is using technology to improve its
                business. Masasabing digitally mature ang iyong negosyo kung ito ay kayang
                mag-compete online, sa anumang channel na gamit.
                <br />
                <br />
                Sinusukat ng aming assessment tool ang experience at proficiency ng iyong negosyo sa
                iba't ibang technologies at digital tools. Para matukoy ang iyong digital maturity,
                ito ang mga kailangan tignan:
              </div>
              <div className="mt-8 mb-6">
                {maturitySteps.map((item, i) => {
                  return <ListItem key={i} step={item.step} description={item.description} />;
                })}
              </div>
              <div className="flex flex-col w-full pt-6 pb-8 gap-3">
                <PrimaryButton isInvalid={false} onClick={handleDigitalMaturityProceed}>
                  Proceed
                </PrimaryButton>
                <SecondaryButton
                  isValid={false}
                  isOrange={true}
                  onClick={() => history.push('/dashboard')}
                >
                  <div></div>
                  Go Back to Dashboard
                  <div></div>
                </SecondaryButton>
              </div>
            </div>
          </>
        )}
      </div>

      {isMaturitySurveyOpen && (
        <>
          {digitalMaturityData?.questions
            .sort((a, b) => a.order - b.order)
            .map((item, i: number) => {
              const updatedQuestion = getTranslatedQuestions(item);
              const availableLanguages = Object.keys(updatedQuestion.language);
              const filteredLanguage = filteredLanguages(availableLanguages);
              const order = i + 1;

              let iconPath;

              try {
                iconPath = require(`../../assets/das/${digitalMaturityData.code}/${order}.svg`);
              } catch (error) {
                iconPath = digitalReadinessData?.icon;
              }

              return (
                <div key={i} className={`${currentQuestionIndex === i ? '' : 'hidden'}`}>
                  <SurveyModal
                    name={`Digital Maturity ${order} of ${digitalMaturityData?.questions.length}`}
                    // icon={digitalMaturityData?.icon}
                    icon={iconPath}
                    heading={updatedQuestion?.language[selectedValue]?.question}
                    isComplete={answer !== null}
                    onClick={addAnswer}
                    onClose={onCloseMaturity}
                    onBack={goToPreviousQuestion}
                    nProgress={((i + 1) / digitalMaturityData?.questions.length) * 100}
                    showBackButton={i > 0}
                    multiToggle={multiLanguageToggle(filteredLanguage)}
                  >
                    <>
                      {!!errorMessage && (
                        <ToastPopup
                          error={true}
                          message={errorMessage}
                          onClose={() => setErrorMessage('')}
                        />
                      )}
                      <DasAnswerButton
                        option="1"
                        description={updatedQuestion?.language[selectedValue]?.l1_option}
                        selected={
                          answer?.answer === 1 ||
                          (answers[currentQuestionIndex]?.answer === 1 && !answer)
                        }
                        onSelect={() => setAnswer({ question_id: item.id, answer: 1, skip: false })}
                      />
                      <DasAnswerButton
                        option="2"
                        description={updatedQuestion?.language[selectedValue]?.l2_option}
                        selected={
                          answer?.answer === 2 ||
                          (answers[currentQuestionIndex]?.answer === 2 && !answer)
                        }
                        onSelect={() => setAnswer({ question_id: item.id, answer: 2, skip: false })}
                      />
                      <DasAnswerButton
                        option="3"
                        description={updatedQuestion?.language[selectedValue]?.l3_option}
                        selected={
                          answer?.answer === 3 ||
                          (answers[currentQuestionIndex]?.answer === 3 && !answer)
                        }
                        onSelect={() => setAnswer({ question_id: item.id, answer: 3, skip: false })}
                      />
                      <DasAnswerButton
                        option="4"
                        description={updatedQuestion?.language[selectedValue]?.l4_option}
                        selected={
                          answer?.answer === 4 ||
                          (answers[currentQuestionIndex]?.answer === 4 && !answer)
                        }
                        onSelect={() => setAnswer({ question_id: item.id, answer: 4, skip: false })}
                      />
                      <DasAnswerButton
                        option="5"
                        description={updatedQuestion?.language[selectedValue]?.l5_option}
                        selected={
                          answer?.answer === 5 ||
                          (answers[currentQuestionIndex]?.answer === 5 && !answer)
                        }
                        onSelect={() => setAnswer({ question_id: item.id, answer: 5, skip: false })}
                      />
                      <DasAnswerButton
                        icon={NotApplicableIcon}
                        description="Not applicable"
                        selected={
                          answer?.answer === null ||
                          (answers[currentQuestionIndex]?.answer === null && !answer)
                        }
                        onSelect={() =>
                          setAnswer({ question_id: item.id, answer: null, skip: true })
                        }
                      />
                    </>
                  </SurveyModal>
                </div>
              );
            })}
        </>
      )}

      {isReadinessSurveyOpen && digitalMaturityData?.questions?.length && (
        <>
          {digitalReadinessData?.questions
            .sort((a, b) => a.order - b.order)
            .map((item, i) => {
              const updatedQuestion = getTranslatedQuestions(item);
              const availableLanguages = Object.keys(updatedQuestion.language);
              const filteredLanguage = filteredLanguages(availableLanguages);
              const order = i + 1;

              let iconPath;

              try {
                iconPath = require(`../../assets/das/${digitalReadinessData.code}/${order}.svg`);
              } catch (error) {
                iconPath = digitalReadinessData?.icon;
              }

              return (
                <div
                  key={i}
                  className={`${
                    currentQuestionIndex === i + digitalMaturityData?.questions?.length
                      ? ''
                      : 'hidden'
                  }`}
                >
                  <SurveyModal
                    name={`Digital Readiness ${order} of ${digitalReadinessData?.questions.length}`}
                    // icon={digitalReadinessData?.icon}
                    icon={iconPath}
                    heading={updatedQuestion?.language[selectedValue]?.question}
                    isComplete={answer !== null}
                    onClick={addAnswer}
                    onClose={onCloseReadiness}
                    onBack={goToPreviousQuestion}
                    nProgress={((i + 1) / digitalReadinessData?.questions.length) * 100}
                    showBackButton={i > 0}
                    multiToggle={multiLanguageToggle(filteredLanguage)}
                  >
                    <>
                      {!!errorMessage && (
                        <ToastPopup
                          error={true}
                          message={errorMessage}
                          onClose={() => setErrorMessage('')}
                        />
                      )}
                      <DasAnswerButton
                        option="1"
                        description={updatedQuestion?.language[selectedValue]?.l1_option}
                        selected={
                          answer?.answer === 1 ||
                          (answers[currentQuestionIndex]?.answer === 1 && !answer)
                        }
                        onSelect={() => setAnswer({ question_id: item.id, answer: 1, skip: false })}
                      />
                      <DasAnswerButton
                        option="2"
                        description={updatedQuestion?.language[selectedValue]?.l2_option}
                        selected={
                          answer?.answer === 2 ||
                          (answers[currentQuestionIndex]?.answer === 2 && !answer)
                        }
                        onSelect={() => setAnswer({ question_id: item.id, answer: 2, skip: false })}
                      />
                      <DasAnswerButton
                        option="3"
                        description={updatedQuestion?.language[selectedValue]?.l3_option}
                        selected={
                          answer?.answer === 3 ||
                          (answers[currentQuestionIndex]?.answer === 3 && !answer)
                        }
                        onSelect={() => setAnswer({ question_id: item.id, answer: 3, skip: false })}
                      />
                      <DasAnswerButton
                        option="4"
                        description={updatedQuestion?.language[selectedValue]?.l4_option}
                        selected={
                          answer?.answer === 4 ||
                          (answers[currentQuestionIndex]?.answer === 4 && !answer)
                        }
                        onSelect={() => setAnswer({ question_id: item.id, answer: 4, skip: false })}
                      />
                      <DasAnswerButton
                        option="5"
                        description={updatedQuestion?.language[selectedValue]?.l5_option}
                        selected={
                          answer?.answer === 5 ||
                          (answers[currentQuestionIndex]?.answer === 5 && !answer)
                        }
                        onSelect={() => setAnswer({ question_id: item.id, answer: 5, skip: false })}
                      />
                      <DasAnswerButton
                        icon={NotApplicableIcon}
                        description="Not applicable"
                        selected={
                          answer?.answer === null ||
                          (answers[currentQuestionIndex]?.answer === null && !answer)
                        }
                        onSelect={() =>
                          setAnswer({ question_id: item.id, answer: null, skip: true })
                        }
                      />
                    </>
                  </SurveyModal>
                </div>
              );
            })}
        </>
      )}
    </div>
  );
};

export default DigitalAssessment;
