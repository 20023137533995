export type CourseTag = {
  title: string;
  color: string;
};

type CapsulesProps = {
  capsules: CourseTag[];
};

const Capsules = ({ capsules = [] }: CapsulesProps) => {
  if (!capsules?.length) return <div className="flex flex-row flex-wrap" />;

  return (
    <div className="flex flex-row flex-wrap">
      {capsules.map((item, index) => {
        if (index > 2) {
          return (
            <div
              className="text-xs mr-2 py-1 px-3 my-1 text-digitayoBlack2 whitespace-nowrap"
              key={item.title}
            >
              and more
            </div>
          );
        }
        return (
          <div
            className="text-xs mr-2 py-1 px-3 my-1 text-digitayoBlack2 rounded-2xl whitespace-nowrap"
            style={{
              backgroundColor: `${item.color}`,
            }}
            key={item.title}
          >
            {item.title}
          </div>
          // <div className={`${item?.bgColor} text-xs mr-2 py-1 px-2 bg-gray-200 rounded-lg`} key={i}>
          //   {item?.label}
          // </div>
        );
      })}
    </div>
  );
};

export default Capsules;
