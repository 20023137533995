import axios from 'axios';
import {
  AnswerDigitalAssessmentSurvey,
  AnswerQuestionnaire,
  BookmarkCourse,
  ChangePassword,
  GetAccounts,
  GetBookmarkedCourses,
  GetCourse,
  GetCourses,
  GetDigitalAssessmentSurvey,
  GetDigitalAssessmentSurveyAllQuestionTranslate,
  GetDigitalAssessmentSurveyQuestionTranslate,
  GetDrcScore,
  GetGoogleAuthUrl,
  GetLanguages,
  GetProfile,
  GetQuestionnaires,
  GetResourcesParams,
  GetScoreHistory,
  GetSmeDashboard,
  InitiateResetPassword,
  LatestAnswers,
  Login,
  LoginWithGoogle,
  ResendOtp,
  ResetPassword,
  SendInquiry,
  SignupWithEmail,
  UpdateDigitalPlatform,
  UpdateProfile,
  VerifyAccount,
} from '../../types/http';

const BASE_URL =
  process.env.REACT_APP_API_SERVICE_URL ?? window?._env_?.REACT_APP_API_SERVICE_URL ?? '/v1';
// const BASE_URL = process.env.REACT_APP_API_SERVICE_URL ?? 'v1';

export const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(async (config) => {
  if (!config.headers) return config;

  const accessToken = window.sessionStorage.getItem('access_token');

  if (accessToken) {
    config.headers.Authorization = 'Bearer ' + accessToken;
  }

  return config;
});

export const getAccounts: GetAccounts = () => {
  return api.get('/v1/smes');
};

export const getProfile: GetProfile = () => {
  return api.get('/v1/smes/profile');
};

export const signupWithEmail: SignupWithEmail = (params) => {
  return api.post('/v1/smes', params);
};

export const verifyEmailAccount: VerifyAccount = ({ id, otp_token, otp_code }) => {
  return api.post(`/v1/auth/${id}/verify-account`, {
    otp_token,
    otp_code,
  });
};

export const requestOtp: ResendOtp = (params: { id?: string }) => {
  return api.post(`/v1/auth/${params.id}/request-otp`, params);
};

export const login: Login = (params) => {
  return api.post(`/v1/auth/login`, params);
};

export const getGoogleAuthUrl: GetGoogleAuthUrl = () => {
  return api.get('/v1/auth/google/url');
};

export const loginWithGoogle: LoginWithGoogle = (code: string) => {
  return api.post('/v1/auth/google/login', { code });
};

export const updateProfile: UpdateProfile = async (params) => {
  const updateResponse = await api.post(`/v1/smes/update-profile`, params);

  await refreshToken();

  return updateResponse;
};

export const updateDigitalPlatform: UpdateDigitalPlatform = (params) => {
  return api.post(`/v1/smes/update-digital-platform`, params);
};

export const initiateResetPassword: InitiateResetPassword = (params) => {
  return api.post(`/v1/auth/initiate-reset-password`, params);
};

export const resetPassword: ResetPassword = (params) => {
  return api.post(`/v1/auth/reset-password`, params);
};

export const changePassword: ChangePassword = (params) => {
  return api.post(`/v1/auth/update-password`, params);
};

export const getCourses: GetCourses = (params) => {
  return api.get('/v1/courses?', { params });
};

export const getRecommendedCourses: GetCourses = () => {
  return api.get('/v1/recommended-courses');
};

export const getBookmarkedCourses: GetBookmarkedCourses = () => {
  return api.get('/v1/bookmarked-courses');
};

export const getCourse: GetCourse = (params) => {
  return api.get(`/v1/course/${params.id}`);
};

export const setBookmarkCourse: BookmarkCourse = (params) => {
  return api.post(`/v1/course/${params.id}/bookmark`);
};

export const getQuestionnaires: GetQuestionnaires = () => {
  return api.get(`/v1/digital-profiles/questionnaires`);
};

export const answerQuestionnaire: AnswerQuestionnaire = (questionnaireId, answers) => {
  return api.post(`/v1/digital-profiles/questionnaires/${questionnaireId}/answer`, {
    answers,
  });
};

export const getLatestAnswers: LatestAnswers = (questionnaireId) => {
  return api.get(`/v1/digital-profiles/questionnaires/${questionnaireId}/latest-answers`);
};

export const getDigitalAssessmentSurvey: GetDigitalAssessmentSurvey = () => {
  return api.get(`/v1/digital-assessments/survey`);
};

export const answerDigitalAssessmentSurvey: AnswerDigitalAssessmentSurvey = (surveyId, answers) => {
  return api.post(`/v1/digital-assessments/survey/${surveyId}/answer`, answers);
};

export const getDigitalAssessmentSurveyQuestionTranslate: GetDigitalAssessmentSurveyQuestionTranslate =
  (surveyId, questionId) => {
    return api.get(`/v1/digital-assessments/survey/${surveyId}/question/${questionId}/language`);
  };

export const getDigitalAssessmentSurveyAllQuestionTranslate: GetDigitalAssessmentSurveyAllQuestionTranslate =
  (surveyId) => {
    return api.get(`/v1/digital-assessments/survey/${surveyId}/questions/language`);
  };

export const getLanguages: GetLanguages = () => {
  return api.get(`/v1/digital-assessments/languages`);
};

export const getSmeDashboard: GetSmeDashboard = () => {
  return api.get(`/v1/smes/dashboard`);
};

export const getSmeScores: GetScoreHistory = () => {
  return api.get(`/v1/smes/scores`);
};

export const getDrcScore: GetDrcScore = () => {
  return api.get(`/v1/courses/scores`);
};

export const sendInquiry: SendInquiry = (inquiryType: string, message: string) => {
  return api.post(`/v1/smes/send-inquiry`, {
    inquiryType,
    message,
  });
};

export const refreshToken = async () => {
  try {
    const { data } = await api.post(`/v1/auth/refresh-token`, {
      token: window.sessionStorage.getItem('refresh_token'),
    });
    window.sessionStorage.setItem('access_token', data.access_token);
    window.sessionStorage.setItem('refresh_token', data.refresh_token);
  } catch (error) {
    console.log(error);
  }
};

export const getAllResources = async (filters: GetResourcesParams) => {
  return api.get(`/v1/resources`, { params: filters });
};

export const getBlogTags = async () => {
  return api.get(`/v1/resources/tags`);
};
