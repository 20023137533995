import CompletedLayout from '../layouts/CompletedLayout';
import ErrorIcon from '../assets/error-icon.svg';
import { useHistory } from 'react-router';

const ErrorPage = () => {
  const history = useHistory();

  return (
    <CompletedLayout
      icon={ErrorIcon}
      title="Something went wrong"
      alignment="center"
      primaryBtnLabel="Go to Dashboard"
      onPrimaryClick={() => history.push('/dashboard')}
    >
      <div className="text-xl py-4 text-digitayoBlack4 text-center mx-auto">
        Oops. Something went wrong.
        <br /> Please try again later.
      </div>
    </CompletedLayout>
  );
};

export default ErrorPage;
