import React from 'react';

type LinkType = {
  children: React.ReactNode;
  fontSize?: string;
  fontWeight?: string;
  display?: string;
  onClick: () => void;
};

const UnderlineLink = ({ children, fontSize, fontWeight, display, onClick }: LinkType) => {
  return (
    <span
      className={`${fontSize} ${fontWeight} ${display} underline underline-offset-1 text-digitayoOrange1 cursor-pointer ml-1`}
      onClick={onClick}
    >
      {children}
    </span>
  );
};

export default UnderlineLink;
