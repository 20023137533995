import { createContext, ReactNode, useState } from 'react';
import { getLatestAnswers, getQuestionnaires } from '../services/https/api';
import { getDigitalProfileAnswers, saveDigitalProfileAnswers } from '../utils/StorageHelper';
import { answerQuestionnaire } from '../services/https/api';

type AnswerType = string | number | boolean;

export interface QuestionOption {
  key: string;
  icon: string;
  label: string;
}

export interface Question {
  id: string;
  order: number;
  options?: QuestionOption[];
  question: string;
  required: boolean;
  code: string;
  question_hint: string;
  question_type: string;
  validation_type: string;
}

export interface QuestionnaireCategory {
  id: string;
  icon: string;
  label: string;
  order: number;
  questions: Question[];
}

export interface Questionnaire {
  id: string;
  categories: QuestionnaireCategory[];
}

export interface Answer {
  question_id: string;
  answer: AnswerType;
}

interface IDigitalProfileContext {
  questionnaire?: Questionnaire;
  answers?: Answer[];
  loadQuestions: () => Promise<void>;
  totalQuestions: number;
  setAnswers: (answers: Answer[]) => void;
  setAnswer: (questionId: string, answer: AnswerType) => void;
  editMode: boolean;
  submitQuestionnaireAnswer: () => Promise<void>;
}

export const DigitalProfileContext = createContext<IDigitalProfileContext>({
  questionnaire: undefined,
  answers: [],
  loadQuestions: async () => {
    // empty
  },
  totalQuestions: 0,
  setAnswers: () => {
    // empty
  },
  setAnswer: () => {
    // empty
  },
  editMode: false,
  submitQuestionnaireAnswer: async () => {
    // empty
  },
});

export default function DigitalProfileContextProvider({ children }: { children: ReactNode }) {
  const [questionnaire, setQuestionnaire] = useState<Questionnaire>();
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [totalQuestions, setTotalQuestions] = useState<number>(0);
  const [editMode, setEditMode] = useState(false);
  const loadQuestions = async () => {
    console.log('load questions');
    const response = await getQuestionnaires();
    const rQuestionnaire: Questionnaire = response.data?.questionnaire ?? [];

    const latestAnswers = await getLatestAnswers(rQuestionnaire.id);
    if (latestAnswers?.data.length) {
      setAnswers(latestAnswers.data);
      setEditMode(true);
    } else {
      const answersFromCache = getDigitalProfileAnswers();
      // load from cache
      console.log('loaded from cache', answersFromCache.length);
      setAnswers(answersFromCache);
    }

    let questionCount = 0;
    rQuestionnaire.categories.map((c) => {
      questionCount += c.questions.length;
    });
    setTotalQuestions(questionCount);
    setQuestionnaire(rQuestionnaire);
  };

  const setAnswer = (question_id: string, answer: AnswerType) => {
    console.log('set', answer);
    const newAnswer = { question_id, answer };
    const filtered = answers.filter((o) => o.question_id !== question_id);
    setAnswers([...filtered, newAnswer]);

    if (!editMode && questionnaire?.id) {
      // save to cache
      saveDigitalProfileAnswers([...filtered, newAnswer]);
      console.log('new answers', answers);
    }
  };

  const submitQuestionnaireAnswer = async () => {
    console.log('submit answer');
    if (!questionnaire || !answers.length) return;
    saveDigitalProfileAnswers([]);
    await answerQuestionnaire(questionnaire.id, answers);
  };

  const values = {
    questionnaire,
    answers,
    loadQuestions,
    totalQuestions,
    setAnswers,
    setAnswer,
    editMode,
    submitQuestionnaireAnswer,
  };

  return <DigitalProfileContext.Provider value={values}>{children}</DigitalProfileContext.Provider>;
}
