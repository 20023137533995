import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import EmainIcon from '../../assets/signup/email.svg';
import InputField from '../../components/form/InputField';
import SelectField from '../../components/form/SelectField';
import Modal from '../../components/modal/Modal';
import BusinessInfoFormSection from '../../components/section/BusinessInfoFormSection';
import ToastPopup from '../../components/Toastr';
import UnderlineLink from '../../components/UnderlineLink';
import { BusinessInfoContext } from '../../context/BusinessInfoContext';
import { updateProfile } from '../../services/https/api';

type Values = {
  first_name: string;
  last_name: string;
  designation: string;
  work_email: string;
};

type ModalData = {
  title?: string;
  icon?: string;
};

const BusinessInfo3 = () => {
  const { register, getValues, watch, setValue } = useForm<Values>();
  const [popup, setPopup] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();
  const businessInfo = useContext(BusinessInfoContext);
  const data = {
    title: 'Authorized Representative',
    heading: `${
      'Who is the authorized representative of ' + businessInfo.value?.business_name + ' ?'
    }`,
    progress: 100,
  };

  const designationOptions = ['Owner', 'Store Manager', 'Contact Person', 'Other'];

  const VALID_EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const modalData: ModalData = {
    title: `I have multiple email addresses. Which one do I choose?`,
    icon: EmainIcon,
  };

  const onClose = () => {
    setPopup(false);
  };

  useEffect(() => {
    const sme = JSON.parse(window.sessionStorage.getItem('profile') as string);
    const accessToken = window.sessionStorage.getItem('access_token');

    if (sme && accessToken) {
      setValue('first_name', sme.first_name);
      setValue('last_name', sme.last_name);
      setValue('designation', sme.designation);
      setValue('work_email', sme.work_email);
      if ((sme.first_name && sme.last_name && sme.designation && sme.work_email) === undefined) {
        setCompleted(false);
      } else {
        setCompleted(true);
      }
    }
  }, []);

  useEffect(() => {
    const subscription = watch((value) => {
      if (
        !!(
          value.first_name &&
          value.last_name &&
          value.designation &&
          value.work_email &&
          value.work_email.match(VALID_EMAIL_REGEX)
        )
      ) {
        businessInfo.setValue({
          ...Object(businessInfo.value),
          first_name: value.first_name,
          last_name: value.last_name,
          designation: value.designation,
          work_email: value.work_email,
        });
        setCompleted(true);
      } else {
        setCompleted(false);
      }
    });
    return () => subscription.unsubscribe();
  }, [businessInfo, watch]);

  const handleErrorCode = (errorCode: string) => {
    if (errorCode === 'UNAUTHORIZED') {
      setErrorMessage('Access is denied due to invalid credentials.');
      window.sessionStorage.clear();
      history.push('/login');
    } else if (errorCode) {
      setErrorMessage('There was an error saving, Please try again later.');
      console.log(errorCode);
    }

    if (window.sessionStorage.getItem('profile')) {
      history.push('/dashboard');
    } else {
      history.push('/login');
    }
  };

  const saveBusinessInfo = async () => {
    const accessToken = window.sessionStorage.getItem('access_token');

    if (!accessToken) {
      history.push('/signup');
      return;
    }

    try {
      await updateProfile({
        ...Object(businessInfo.value),
      });

      history.push('/account-created');
    } catch (error: any) {
      handleErrorCode(error.response.data.error_code);
    }
  };

  return (
    <BusinessInfoFormSection
      name={data.title}
      nProgress={data.progress}
      heading={data.heading}
      isComplete={completed}
      onClick={saveBusinessInfo}
      isHeadingSmaller={true}
    >
      <div className="flex flex-col gap-3">
        {!!errorMessage && (
          <ToastPopup error={true} message={errorMessage} onClose={() => setErrorMessage('')} />
        )}
        <InputField
          label="First Name"
          placeholder="Juan"
          isValid={watch('first_name')}
          {...register('first_name', {
            required: 'First Name is required.',
          })}
        />
        <InputField
          label="Last Name"
          placeholder="dela Cruz"
          isValid={watch('last_name')}
          {...register('last_name', {
            required: 'Last Name is required.',
          })}
        />

        <SelectField
          value={getValues('designation')}
          label="Designation"
          {...register('designation', {
            required: 'Designation is required.',
          })}
          placeholder="e.g. Owner"
          options={designationOptions.map((str) => ({ code: str, name: str }))}
          hasOptionsWithId={false}
        />

        <InputField
          isEmail={true}
          label="Work Email"
          placeholder="Work Email"
          isValid={watch('work_email')}
          {...register('work_email', {
            required: 'Work email is required.',
          })}
        />
        <div className="text-md mx-3 mt-4 mb-8 text-above-btn cursor-pointer flex flex-col">
          I have multiple email addresses.
          <UnderlineLink onClick={() => setPopup(true)}>Which one do I choose?</UnderlineLink>
        </div>
        {popup && <Modal modalData={modalData} hasMultipleEmails={true} onClose={onClose} />}
      </div>
    </BusinessInfoFormSection>
  );
};

export default BusinessInfo3;
