import { RouteComponentProps, useHistory } from 'react-router';
import { useContext, useEffect, useState } from 'react';
import qs from 'qs';
import { loginWithGoogle } from '../services/https/api';
import PrimaryButton from '../components/button/PrimaryButton';
import { AuthUserContext } from '../context/AuthUserContextProvider';

const GoogleCallback = (props: RouteComponentProps) => {
  const history = useHistory();
  const [errorCode, setErrorCode] = useState('');
  const { profile, refreshProfile } = useContext(AuthUserContext);

  const errorMessage = (errorCode: string) => {
    if (errorCode === 'DUPLICATE') {
      return 'User already exists, please login with your email and password.';
    } else {
      return 'Google authentication expired or invalid. If the problem persist please contact administrator.';
    }
  };

  const redirectToAuthPage = () => {
    if (profile!.status === 'BUSINESS_INFO_PROVIDED') {
      history.push('/dashboard');
    } else {
      history.push('/requirements');
    }
  };

  useEffect(() => {
    if (profile) {
      redirectToAuthPage();
    }
  }, [profile]);

  useEffect(() => {
    let search = props.location.search;
    if (search.startsWith('?')) {
      search = search.substring(1);
    }

    const query = qs.parse(search) as { code?: string };

    if (query.code) {
      loginWithGoogle(query.code)
        .then(({ data }: { data: { access_token: string } }) => {
          window.sessionStorage.setItem('access_token', data.access_token);
          refreshProfile();
        })
        .catch((error: any) => {
          if (error.response.data.error_code) {
            setErrorCode(error.response.data.error_code);
          } else {
            setErrorCode('INTERNAL_ERROR');
          }

          console.log('error', error.response.data);
        });
    } else {
      history.push('/login');
    }
  }, []);

  return (
    <div className="flex flex-col justify-evenly items-center min-h-screen text-gray-500 px-12 bg-gradient-to-r from-digitayoCreated1 to-digitayoCreated2">
      <div className="font-inter">
        <div className="text-sm py-4 text-digitayoBlack4">
          <div className="py-4 text-lg text-center">
            {!errorCode ? (
              'Loggin in with google...'
            ) : (
              <p className="text-red-500"> {errorMessage(errorCode)}</p>
            )}
          </div>
          {errorCode && (
            <div className="my-3">
              <PrimaryButton
                isInvalid={false}
                onClick={() => {
                  history.push('/login');
                }}
              >
                Back to Login Page
              </PrimaryButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GoogleCallback;
