import Capsules, { CourseTag } from './Capsules';

type CardTypes = {
  imageSrc: string;
  bookmarkSrc: string;
  label: string;
  description: string;
  tags?: CourseTag[];
  onClick?: () => void;
};

const CardCourses = ({
  imageSrc,
  bookmarkSrc,
  label,
  description,
  tags = [],
  onClick,
}: CardTypes) => {
  return (
    <div
      className="w-100 flex flex-row border rounded-lg my-6 shadow-xl shadow-[#9C27B01A] cursor-pointer bg-digitayoWhite"
      onClick={onClick}
    >
      <div className="w-1/3 d-flex justify-center items-center">
        <img src={imageSrc} className="h-full w-full object-fit rounded-l-xl" alt="card-image" />
      </div>
      <div className="w-2/3 px-4 my-6 flex flex-col justify-between align-center">
        <div className="ml-auto">
          <img src={bookmarkSrc} alt="bookmark-icon" width="20" />
        </div>
        <div>
          <div className="text-digitayoBlack4 text-sm font-bold mr-4">{label}</div>
          <div className="text-digitayoBlack2 text-xs my-2 truncate-3-lines">{description}</div>
        </div>
        <Capsules capsules={tags} />
      </div>
    </div>
  );
};

export default CardCourses;
