import React from 'react';

type ButtonProps = {
  children: React.ReactNode;
  onClick?: () => void;
  isValid: boolean;
  isOrange: boolean;
  type?: 'button' | 'submit';
};

const SecondaryButton = ({
  children,
  onClick,
  isValid,
  isOrange,
  type = 'button',
}: ButtonProps) => {
  return (
    <button
      className={`bg-transparent text-lg text-center border-2 w-full h-[50px] leading-[50px] rounded-[10px] cursor-not-allowed flex flex-row justify-between align-middle items-center font-bold ${
        !isValid ? 'cursor-pointer bg-digitayoOrange1' : 'cursor-not-allowed bg-digitayoOrange4'
      }
      ${isOrange ? 'text-digitayoOrange1 border-digitayoOrange1' : 'text-white border-white'}`}
      onClick={onClick}
      type={type}
      disabled={isValid}
    >
      {children}
    </button>
  );
};

export default SecondaryButton;
