import { FC, useEffect, useState } from 'react';
import PrimaryButton from './button/PrimaryButton';

interface SMEHubButtonProps {
  label?: string;
  courseLink?: string;
}

const SMEHUB_URL =
  process.env.REACT_APP_SMEHUB_URL ?? window?._env_?.REACT_APP_SMEHUB_URL ?? '/sme-hub';

const SMEHubButton: FC<SMEHubButtonProps> = ({ label = 'Open Course', courseLink = null }) => {
  const [token, setToken] = useState<string>('');

  useEffect(() => {
    const accessToken = window.sessionStorage.getItem('access_token');
    setToken(accessToken!);
  }, []);

  const getSmeHubUrl = () => {
    if (courseLink?.indexOf(SMEHUB_URL) === -1) {
      return courseLink;
    }

    if (courseLink) {
      return `${SMEHUB_URL}/login/index.php?redirect=1&redirect_url=${courseLink.replace(
        SMEHUB_URL,
        ''
      )}`;
    }

    return `${SMEHUB_URL}/login/index.php`;
  };

  return (
    <form method="POST" action={getSmeHubUrl()}>
      <input type="hidden" name="Authorization" value={token} />
      <PrimaryButton
        onClick={() => {
          // console.log('asd');
        }}
        isInvalid={!token}
        type="submit"
      >
        {label}
      </PrimaryButton>
    </form>
  );
};

export default SMEHubButton;
