import { ReactNode, createContext, useEffect, useState } from 'react';
import { Sme } from '../types/http';
import { getProfile } from '../services/https/api';

type Profile = {
  hasDigitalProfile: boolean;
  isLoginWithGoogle: boolean;
} & Sme;

interface IAuthUserContext {
  profile?: Profile;
  authenticated: boolean;
  refreshProfile: () => Promise<void>;
}

export const AuthUserContext = createContext<IAuthUserContext>({
  profile: undefined,
  refreshProfile: async () => {
    // empty
    console.log('refresh');
  },
  authenticated: false,
});

export default function AuthUserContextProvider({ children }: { children: ReactNode }) {
  const [profile, setProfile] = useState<Profile>();
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    if (!profile) {
      refreshProfile();
    }
  }, []);

  const refreshProfile = async () => {
    console.log('refresh profile');
    try {
      const result = await getProfile();
      console.log(result);
      setProfile(result.data.sme);
      setAuthenticated(true);
    } catch (error) {
      setAuthenticated(false);
    }
  };

  return (
    <AuthUserContext.Provider
      value={{
        profile,
        refreshProfile,
        authenticated,
      }}
    >
      {children}
    </AuthUserContext.Provider>
  );
}
