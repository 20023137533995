import { useState } from 'react';
import Slider from 'react-slick';
import { Resource } from '../pages/Dashboard/Resources/DashboardResources';
import Card2 from './Card2';
import Modal from './modal/Modal';

const sliderSettings = {
  dots: true,
  infinite: false,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  swipeToSlide: true,
  dotsClass: 'slick-dots custom-dots',
};

type data = {
  id: string;
  thumbnail: string;
  title: string;
  description: string;
  highlight?: string;
  url?: string;
  email?: string;
  contacts?: string[];
};

type CardCarouselType = {
  data: Resource[];
  blogs?: boolean;
  payments?: boolean;
  logistics?: boolean;
  partners?: boolean;
};

const CardCarousel = ({ data, blogs, payments, logistics }: CardCarouselType) => {
  const [popup, setPopup] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string>('');

  return (
    <div className="w-full">
      {data.length > 1 ? (
        <Slider {...sliderSettings}>
          {data.map((item) => (
            <Card2
              key={`card-${item.id}`}
              id={item.id}
              imageSrc={item.image}
              title={item.title}
              description={item.description}
              blogs={blogs}
              tags={item.tags}
              // highlight={item.highlight}
              url={item.url}
              popup={popup}
              setPopup={() => setPopup(!popup)}
              setSelectedItem={(selectedItem) => setSelectedItem(selectedItem)}
              // onClick={() => toCourseDetails(blog.id)}
            />
          ))}
        </Slider>
      ) : (
        <div className="mx-6 mb-9">
          {data.map((item) => (
            <Card2
              key={`card-${item.id}`}
              id={item.id}
              imageSrc={item.image}
              title={item.title}
              description={item.description}
              blogs={blogs}
              payments={payments}
              logistics={logistics}
              tags={item.tags}
              // highlight={item.highlight}
              url={item.url}
              popup={popup}
              setPopup={() => setPopup(!popup)}
              setSelectedItem={(selectedItem) => setSelectedItem(selectedItem)}
              // onClick={() => toCourseDetails(blog.id)}
            />
          ))}
        </div>
      )}
      {popup &&
        data.map((item: Resource) => {
          if (selectedItem === item.id) {
            return (
              <Modal
                modalData={{
                  title: item.title,
                  icon: item.image,
                  description: item.description,
                  // highlight: item.highlight,
                  url: item.url,
                  email: item.email_address,
                  contacts: item.contact_numbers,
                  promo: item.promo,
                }}
                isResources
                onClose={() => setPopup(false)}
              />
            );
          }
        })}
    </div>
  );
};

export default CardCarousel;
