import { useHistory } from 'react-router';
import SetPasswordIcon from '../../assets/password/password.png';
import PrimaryButton from '../../components/button/PrimaryButton';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import UnderlineLink from '../../components/UnderlineLink';
import { signupWithEmail } from '../../services/https/api';
import { BusinessInfoContext } from '../../context/BusinessInfoContext';
import PasswordField from '../../components/form/PasswordField';
import ToastPopup from '../../components/Toastr';
import Modal from '../../components/modal/Modal';
import LockIcon from '../../assets/password/password.png';

const SetPassword = () => {
  // const [continueBtn, setContinueBtn] = useState<boolean>(true);
  const history = useHistory();
  const [isNewPasswordValid, setNewPasswordValid] = useState<string | boolean>();
  const [isRetypePasswordValid, setRetypePasswordValid] = useState<string | boolean>();
  const [passwordsMatch, setPasswordsMatch] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [popup, setPopup] = useState<boolean>(false);
  const { setValue, getValues, watch } = useForm();
  const info = useContext(BusinessInfoContext);

  const strongPasswordData = {
    title: 'How do I make a strong password?',
    icon: LockIcon,
  };

  const handleErrorCode = (errorCode: string) => {
    if (errorCode === 'DUPLICATE') {
      setErrorMessage('User already exist. Please login or try another email.');
    } else if (errorCode === 'WRONG_CREDENTIALS') {
      setErrorMessage('Invalid email or password.');
      console.log(errorCode);
    } else if (errorCode === 'BAD_REQUEST') {
      setErrorMessage('Validation error. Please check the required fields');
      console.log(errorCode);
    } else if (errorCode) {
      setErrorMessage('There was an error signing in. Please try again later.');
      console.log(errorCode);
    }
  };

  const onContinue = async () => {
    const password = watch('newPassword');

    try {
      // TODO: email should come from SignupPage.tsx
      const { data } = await signupWithEmail({
        email: info?.value?.email as string,
        password: password,
      });

      // TODO: this should save access_token and refresh_token
      window.sessionStorage.setItem('access_token', data.access_token);
      window.sessionStorage.setItem('refresh_token', data.refresh_token);
      window.sessionStorage.setItem('auth_id', data.auth_id);
      history.push('/confirm-email');
    } catch (error: any) {
      // Error here
      handleErrorCode(error.response.data.error_code);
    }
  };

  const validatePassword = (password: string) => {
    if (
      password.match(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[-#!$@%^&*()_+|~=`{}\[\]:";'<>?,.\/ ]).{8,}$/g
      )
    ) {
      return true;
    } else {
      return 'Password must be at least 8 characters long.';
    }
  };

  const handleNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    const passwordError = validatePassword(password);
    setValue('newPassword', password);
    setNewPasswordValid(passwordError);
    setPasswordsMatch(password === getValues('retypePassword'));
  };

  const handleRetypePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    const passwordError = validatePassword(password);
    setValue('retypePassword', password);
    setRetypePasswordValid(passwordError);
    setPasswordsMatch(password === getValues('newPassword'));
  };

  useEffect(() => {
    const email = info.value?.email;
    if (!email) {
      history.push('/signup');
      return;
    }
  }, []);

  return (
    <div className="bg-digitayoWhite min-h-screen h-100 p-7 font-archivo">
      <div className="my-6 bg-gradient-to-r from-digitayoOrange1 to-digitayoPurple1 bg-clip-text text-transparent font-bold text-[34px]">
        Set Password
      </div>

      <img src={SetPasswordIcon} alt="Otp Image" className="w-1/2 mx-auto py-2" />

      <div className="text-xl my-8">
        <div className="text-digitayoBlack4">
          Please create a <strong>strong</strong> password for your account
        </div>
      </div>
      {!!errorMessage && (
        <ToastPopup error={true} message={errorMessage} onClose={() => setErrorMessage('')} />
      )}

      <form className="flex flex-col gap-4">
        <PasswordField
          id="password"
          label="New Password"
          placeholder="Enter password"
          isValid={isNewPasswordValid !== true}
          onChange={handleNewPassword}
        />

        <PasswordField
          id="retypePassword"
          label="Re-type Password"
          placeholder="Type password again"
          isValid={isRetypePasswordValid !== true}
          onChange={handleRetypePassword}
        />
      </form>

      <div className="text-digitayoBlack2 text-md mt-10">
        How do I make a{' '}
        <UnderlineLink onClick={() => setPopup(true)}>strong password</UnderlineLink>?
      </div>
      {popup && (
        <Modal
          modalData={strongPasswordData}
          hasPasswordDescription={true}
          onClose={() => setPopup(false)}
        />
      )}

      <div className="flex flex-col my-6">
        <PrimaryButton
          isInvalid={
            !(isNewPasswordValid === true && isRetypePasswordValid === true && passwordsMatch)
          }
          onClick={onContinue}
        >
          Continue
        </PrimaryButton>
      </div>
    </div>
  );
};

export default SetPassword;
