/* Theme variables */
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { Route, Switch } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import AddToHomescreen from './components/AddToHomescreen';
import AuthUserContextProvider from './context/AuthUserContextProvider';
import { BusinessInfoContext } from './context/BusinessInfoContext';
import DigitalProfileContextProvider from './context/DigitalProfileContext';
import AccountCreated from './pages/AccountCreated';
import {
  ChangePassword,
  ConfirmEmail,
  ForgotPassword,
  Login,
  Requirements,
  SetPassword,
  SignupPage,
  TermsAndConditions,
} from './pages/Auth/index';
import ResetPassword from './pages/Auth/ResetPassword';
import SmeHub from './pages/Auth/SmeHub';
import SmeHubAdmin from './pages/Auth/SmeHubAdmin';
import { BusinessInfo1, BusinessInfo2, BusinessInfo3 } from './pages/BusinessInfo';
import CourseDetails from './pages/Dashboard/Courses/CourseDetails';
import DashboardCourses from './pages/Dashboard/Courses/DashboardCourses';
import DashboardAssessment from './pages/Dashboard/DashboardAssessment';
import DashboardHelpdesk from './pages/Dashboard/DashboardHelpdesk';
import DashboardHome from './pages/Dashboard/DashboardHome';
import InquirySubmitted from './pages/Dashboard/InquirySubmitted';
import DashboardResources from './pages/Dashboard/Resources/DashboardResources';
import DigitalProfile from './pages/DigitalProfile/DigitalProfile';
import DigitalProfileCreated from './pages/DigitalProfile/DigitalProfileCreated';
import ErrorPage from './pages/ErrorPage';
import GoogleCallback from './pages/GoogleCallback';
import Onboarding from './pages/Onboarding';
import DigitalAssessment from './pages/SDMEST/DigitalAssessment';
import Score from './pages/SDMEST/Score';
import ScoreHistory from './pages/SDMEST/ScoreHistory';
import Splash from './pages/Splash';
import UpdatedPassword from './pages/UpdatedPassword';
import './styles/layout.css';
import { infoFormType } from './types/infoForm';
import ProtectedRoute from './utils/ProtectedRoute';

const queryClient = new QueryClient();

const App: React.FC = () => {
  const [value, setValue] = useState<infoFormType>({
    id: '',
    email: '',
    business_name: '',
    region: '',
    city: '',
    barangay: '',
    first_name: '',
    last_name: '',
    designation: '',
    work_email: '',
    payment_methods: {
      cash: false,
      maya: false,
      gcash: false,
      others: '',
    },
    logistic_platforms: {
      grab: false,
      lalamove: false,
      gogo: false,
      others: '',
    },
    ecommerce_platforms: {
      shopify: false,
      facebook: false,
      lazada: false,
      others: '',
    },
    security_platforms: {
      one_password: false,
      bitwarden: false,
      norton: false,
      others: '',
    },
    trainings_and_certifications: '',
    facebook_url: '',
    instagram_url: '',
  });

  const initialPathRef = useRef(window.location.pathname);

  useEffect(() => {
    const lastPage = window.localStorage.getItem('lastPage');
    const currentPath = window.location.pathname;

    if (lastPage && currentPath !== lastPage && currentPath !== initialPathRef.current) {
      window.location.href = lastPage;
    }

    const handleBeforeUnload = () => {
      const accessToken = window.sessionStorage.getItem('access_token');
      if (accessToken) {
        console.log('Access token found:', accessToken);
      }
    };

    const handleRouteChange = () => {
      window.localStorage.setItem('lastPage', window.location.pathname);
    };

    window.addEventListener('popstate', handleRouteChange);
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, []);

  return (
    <div className="relative overflow-hidden">
      <div
        className="absolute inset-0 bg-fixed bg-cover bg-center"
        style={{ backgroundImage: "url('/assets/background-full.jpg')" }}
      ></div>
      <div className="relative mx-auto min-h-screen h-100 bg-appBackground max-w-md font-archivo shadow-2xl">
        <QueryClientProvider client={queryClient}>
          <Router>
            <Switch>
              <AuthUserContextProvider>
                <BusinessInfoContext.Provider value={{ value, setValue }}>
                  <AddToHomescreen />

                  <DigitalProfileContextProvider>
                    <Route exact path="/" component={Splash} />
                    <Route exact path="/onboarding" component={Onboarding} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/smehub" component={SmeHub} />
                    <Route exact path="/smehub/admin" component={SmeHubAdmin} />
                    <Route exact path="/signup" component={SignupPage} />
                    <Route exact path="/forgot-password" component={ForgotPassword} />
                    <Route exact path="/reset-password/:id/code/:code" component={ResetPassword} />
                    <Route exact path="/updated-password" component={UpdatedPassword} />
                    <Route exact path="/set-password" component={SetPassword} />
                    <Route exact path="/confirm-email" component={ConfirmEmail} />
                    <Route exact path="/requirements" component={Requirements} />
                    <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
                    <ProtectedRoute path="/dashboard" component={DashboardHome} />
                    <ProtectedRoute path="/sdmest" component={DashboardAssessment} />
                    <ProtectedRoute exact path="/courses" component={DashboardCourses} />
                    <ProtectedRoute exact path="/resources" component={DashboardResources} />
                    <ProtectedRoute
                      exact
                      path="/courses/:id/:tab/details"
                      component={CourseDetails}
                    />
                    <ProtectedRoute path="/helpdesk" component={DashboardHelpdesk} />
                    <ProtectedRoute path="/inquiry-submitted" component={InquirySubmitted} />
                    <ProtectedRoute exact path="/change-password" component={ChangePassword} />
                    <ProtectedRoute exact path="/business-info/1" component={BusinessInfo1} />
                    <ProtectedRoute exact path="/business-info/2" component={BusinessInfo2} />
                    <ProtectedRoute exact path="/business-info/3" component={BusinessInfo3} />
                    <ProtectedRoute exact path="/account-created" component={AccountCreated} />
                    <ProtectedRoute
                      exact
                      path="/digital-profile-created"
                      component={DigitalProfileCreated}
                    />
                    <ProtectedRoute
                      exact
                      path="/digital-profile/:page"
                      component={DigitalProfile}
                    />
                    <ProtectedRoute
                      exact
                      path="/digital-assessment"
                      component={DigitalAssessment}
                    />
                    <ProtectedRoute exact path="/score" component={Score} />
                    <ProtectedRoute exact path="/score-history" component={ScoreHistory} />
                    <Route exact path="/google/callback" component={GoogleCallback} />
                    <Route exact path="/error" component={ErrorPage} />
                  </DigitalProfileContextProvider>
                </BusinessInfoContext.Provider>
              </AuthUserContextProvider>
            </Switch>
          </Router>
        </QueryClientProvider>
      </div>
    </div>
  );
};

export default App;
