import { ChangeEvent, forwardRef, useEffect, useState } from 'react';
import InputField from './InputField';
import { QuestionOption } from '../../context/DigitalProfileContext';

type SelectOneOrTextFieldProps = {
  label?: string;
  value?: string;
  name?: string;
  options: QuestionOption[];
  onChange?: (val: string) => void;
  placeholder?: string;
  hasOptionsWithId?: boolean;
  isValid?: string;
};

const SelectOneOrTextField = forwardRef<HTMLSelectElement, SelectOneOrTextFieldProps>(
  (props, _) => {
    const { label, value, options, onChange } = props;
    const [customText, setCustomText] = useState('');

    useEffect(() => {
      if (value?.startsWith('custom:')) {
        setCustomText(value?.replace('custom:', ''));
      }
    }, [value]);

    const onSelectChange = (val: string) => {
      setCustomText('');
      onChange?.(val);
    };

    const onCustomTextChange = (event: ChangeEvent<HTMLInputElement>) => {
      setCustomText(event.target.value);
      onChange?.('custom:' + event.target.value);
    };

    return (
      <div className="mt-2">
        <label className="text-xl">{label}</label>
        <div className="grid grid-cols-2 gap-3 my-2">
          {options.map((item) => {
            const isSelected = value === item.key;

            if (item.key.startsWith('custom:')) {
              // custom input
              return;
            }

            return (
              <div
                className={` ${
                  isSelected ? 'bg-digitayoPurple1 text-white' : 'border-digitayoGray3'
                } cursor-pointer p-3 border items-center justify-end rounded-lg text-center relative`}
                key={item.key}
                onClick={() => onSelectChange(item.key)}
              >
                <div className="absolute left-[10px]">
                  <img src={item.icon} alt="icon" width="27" height="27" />
                </div>
                {item.label}
              </div>
            );
          })}
        </div>
        {options
          .filter((o) => o.key.startsWith('custom:'))
          .map((item) => {
            return (
              <InputField
                className={customText && 'bg-digitayoPurple1 text-white'}
                inputClassName={customText && '!bg-digitayoPurple1 text-white'}
                key={item.key}
                label={item.key.replace('custom:', '')}
                value={customText}
                placeholder={item.label}
                onChange={onCustomTextChange}
              />
            );
          })}
      </div>
    );
  }
);

export default SelectOneOrTextField;
