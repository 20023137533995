import { TextareaHTMLAttributes, forwardRef } from 'react';

type TextareaProps = {
  label?: string;
  className?: string;
  placeholder?: string;
  isValid?: string;
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

const TextareaField = forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => {
  const { label, isValid, ...otherProps } = props;

  return (
    <div className="border rounded-lg border-digitayoGray3 p-2 mt-2">
      <div
        className={`text-sm text-digitayoBlack1 p-1 pb-0 mb-0 ${isValid ? 'text-green-600' : ''}`}
      >
        {label}
      </div>
      <div className="flex justify-between text-xl">
        <textarea
          ref={ref}
          rows={4}
          className="flex flex-grow rounded py-2 text-digitayoBlack4 px-1 outline-0 bg-digitayoWhite resize-none"
          {...otherProps}
        ></textarea>
      </div>
    </div>
  );
});

export default TextareaField;
