import { InputHTMLAttributes, forwardRef } from 'react';

type InputFieldProps = {
  label?: string;
  className?: string;
  placeholder?: string;
  inputClassName?: string;
  isValid?: string | number | boolean;
  isEmail?: boolean;
  errorMessage?: string;
} & InputHTMLAttributes<HTMLInputElement>;

const InputField = forwardRef<HTMLInputElement, InputFieldProps>((props, ref) => {
  const {
    label,
    isValid,
    isEmail,
    className,
    inputClassName = '',
    errorMessage,
    value,
    ...otherProps
  } = props;

  let additionalInputClassName = '';

  additionalInputClassName += inputClassName;

  return (
    <>
      <div
        className={`border rounded-lg text-digitayoBlack1 border-digitayoGray3 p-2 mt-2 ${className}`}
      >
        <div
          className={`text-sm p-1 pb-0 mb-0 ${isValid && !errorMessage ? 'text-green-600' : ''}`}
        >
          {label}
        </div>
        <div className="flex justify-between text-xl">
          <input
            ref={ref}
            type={`${isEmail ? 'email' : 'text'}`}
            value={value}
            className={`flex flex-grow rounded text-digitayoBlack4 ${
              !additionalInputClassName.includes('bg-') && 'bg-digitayoWhite'
            } py-2 px-1 outline-0 ${additionalInputClassName}`}
            {...otherProps}
          />
        </div>
      </div>
      {!!errorMessage && <p className="text-red-500">{errorMessage}</p>}
    </>
  );
});

export default InputField;
