type FilterType = {
  label: string;
  onClick?: () => void;
  // filters: string[];
  selected: boolean;
};

const FilterButton = ({ label, onClick, selected }: FilterType) => {
  // const isActive = filters.includes(label);

  return (
    <div
      className={`text-[16px] border rounded-lg py-3 px-4 cursor-pointer ${
        selected ? 'bg-digitayoPurple1 text-white' : 'text-digitayoBlack2 bg-digitayoWhite'
      }`}
      onClick={onClick}
    >
      {label}
    </div>
  );
};

export default FilterButton;
