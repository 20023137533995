export async function getRegions() {
  const response = await fetch(`https://psgc.gitlab.io/api/regions`);
  const data = await response.json();
  return data;
}

export async function getCities(regionCode: string) {
  const response = await fetch(`https://psgc.gitlab.io/api/regions/${regionCode}/cities`);
  const data = await response.json();
  return data;
}

export async function getBarangays(provinceCode: string) {
  const response = await fetch(`https://psgc.gitlab.io/api/provinces/${provinceCode}/barangays`);
  const data = await response.json();
  return data;
}

export async function getBarangaysFromDistrict(districtCode: string) {
  const response = await fetch(`https://psgc.gitlab.io/api/districts/${districtCode}/barangays`);
  const data = await response.json();
  return data;
}
