import CompletedLayout from '../../layouts/CompletedLayout';
import CheckConfetti from '../../assets/password/check-confetti.svg';
import { useHistory } from 'react-router';

const InquirySubmitted = () => {
  const history = useHistory();

  return (
    <CompletedLayout
      icon={CheckConfetti}
      title="Your request has been submitted"
      alignment="center"
      primaryBtnLabel="Go to Dashboard"
      onPrimaryClick={() => history.push('/dashboard')}
      iconWidth="400"
    >
      <div className="text-md py-4 text-digitayoBlack4 text-center mx-auto">
        Your request has been submitted. Our team will review your concern and respond as soon as we
        can.
      </div>
    </CompletedLayout>
  );
};

export default InquirySubmitted;
