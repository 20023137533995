import { IoArrowForwardOutline } from 'react-icons/io5';
import { Tag } from '../pages/Dashboard/Resources/DashboardResources';
import Capsules from './Capsules';

type CardType = {
  id: string;
  imageSrc: string;
  title: string;
  description: string;
  highlight?: string;
  url?: string;
  blogs?: boolean;
  payments?: boolean;
  logistics?: boolean;
  tags?: Tag[];
  onClick?: () => void;
  popup: boolean;
  setPopup: () => void;
  setSelectedItem?: (val: string) => void;
};

const Card2 = ({
  id,
  imageSrc,
  title,
  description,
  highlight,
  url,
  blogs,
  tags = [
    { tagName: 'Success', tagColor: '#FFDCB2' },
    { tagName: 'Digital', tagColor: '#FFDCB2' },
  ],
  onClick,
  setPopup,
  setSelectedItem,
}: CardType) => {
  const handleClick = (id: string) => {
    setSelectedItem?.(id);
    setPopup();
  };

  const handleRedirect = () => {
    window.open(url, '_blank');
  };

  return (
    <div
      className="w-full max-w-[290px] h-full shadow-lg shadow-[#9C27B01A] rounded-xl overflow-hidden flex-1"
      onClick={blogs ? handleRedirect : onClick}
    >
      {blogs ? (
        <div className="w-full h-[150px]">
          <img
            className="w-full h-auto max-w-[290px] max-h-[150px] object-cover object-top"
            src={imageSrc}
            alt="card-image"
          />
        </div>
      ) : (
        <div className="w-full h-[150px] flex justify-center items-center border-b-[1px] border-digitayoGray2 bg-digitayoWhite">
          <img
            className="w-auto h-auto max-w-[290px] max-h-[150px] object-contain object-center"
            src={imageSrc}
            alt="card-image"
          />
        </div>
      )}
      <div className="h-full p-[18px] pb-5 bg-neutral-50 flex-col justify-start items-start flex">
        <div className="text-digitayoBlack4 text-sm font-bold line-clamp-2">{title}</div>
        <div
          className={`text-digitayoBlack2 text-xs my-2 truncate-4-lines mt-2 ${
            highlight ? 'mb-4' : 'mb-5'
          }`}
        >
          {description}
        </div>
        {!blogs && (
          <>
            {highlight && (
              <div className="text-digitayoGreen text-[10px] italic mb-3">{highlight}</div>
            )}
            {url && (
              <div className="w-full flex justify-between items-end">
                <a
                  className="text-digitayoGray5 text-[10px]"
                  href={url.indexOf('http') > -1 ? url : `https://${url}`}
                  target="_blank"
                >
                  {url}
                </a>
                <span
                  className="flex items-center text-[10px] font-bold text-digitayoOrange1 cursor-pointer"
                  onClick={() => handleClick(id)}
                >
                  View More
                  <IoArrowForwardOutline className="ml-1" fontSize="13px" />
                </span>
              </div>
            )}
          </>
        )}
        {blogs && tags && (
          <Capsules capsules={tags.map((t) => ({ title: t.tagName, color: t.tagColor }))} />
        )}
      </div>
    </div>
  );
};

export default Card2;
